import React, { useState } from "react";
import DeliveryDate from "../Custom/DeliveryDate";
import { useNavigate } from "react-router-dom";
import { addtoCartGreetingCard } from "../../redux/action";
import { connect } from "react-redux";
import Toastify from "../SnackBar/Toastify";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import { CART } from "../routes/ConstURL";
const EgiftcardDeliveryDate = ({ addtoCartGreetingCard }) => {
  const [loading, setLoading] = useState(false);
  const cartDetails = storage.fetch.recipientDetails();
  let cart_id = storage.fetch.carts_id();

  let id = {};
  if (cart_id !== undefined) {
    id = { carts_id: cart_id };
  } else {
    id = { carts_id: "" };
  }
  const navigate = useNavigate();
  const onSubmit = async (model) => {
    setLoading(true);
    let item_type = { item_qty: "1" };
    let item_id = { item_id: storage.fetch.amountID() };
    let media_id = { media_file_id: storage.fetch.item_id() };

    let item = model;
    Object.assign(item, id, cartDetails, item_id, item_type, media_id);
    addtoCartGreetingCard(item).then((res) => {
      if (res?.data?.status) {
        setTimeout(() => {
          toast.success(res?.data?.message);
          setLoading(false);
          storage.destroy.item_id();
          storage.destroy.recipientDetails();
          storage.destroy.message();
          storage.destroy.amountID();

          navigate(CART);
        }, 2000);
      } else {
        res?.data?.errors?.map((item) => {
          toast.error(res);
        });
        setTimeout(() => {
          setLoading(false);
          // navigate('/firstcheckout')
        }, 1000);
      }
    });
  };
  return (
    <>
      <DeliveryDate
        loading={loading}
        headerText={"Greeting cards/ select delivery date"}
        setLoading={setLoading}
        onSubmit={onSubmit}
        egift={true}
        recipient={true}
      />
      <Toastify />
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    addtoCartGreetingCard: (item) => dispatch(addtoCartGreetingCard(item)),
  };
}
export default connect(null, mapDispatchToProps)(EgiftcardDeliveryDate);
