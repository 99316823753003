/* eslint-disable eqeqeq */
import { Box, Button, IconButton, TextField, Typography, Skeleton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { connect } from "react-redux";
import Toastify from "../SnackBar/Toastify";
import LoaderComponent from "../Loader/LoaderComponent";
import { showCardinCarts, removeItem, updateMsg, handleQty } from "../../redux/action";
import { storage } from "../../config/storage";
import { KeyboardArrowDown } from "@mui/icons-material";
import React, { useState } from "react";
import { FILE_TYPE } from "../../config/mockData";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const ViewCartDetailsAccordian = ({ item, removeItem, showCardinCarts, updateMsg, handleQty, loading }) => {
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState(parseInt(item?.item_qty));
  const [cart, setCart] = useState(false);
  const [popup, setPopup] = useState(false);
  const [gift_id, setGift_id] = useState();
  const [msg, setMsg] = useState(item.greet_msg);
  let carts_id = { carts_id: storage.fetch.carts_id() };
  let id = { cart_items_id: item.cart_items_id };
  const viewSubItem = (item, index) => {
    setCart(!cart);
  };
  const handleInc = () => {
    setOpen(true);
    setQty(qty + 1);
    const quantity = { quantity: qty + 1 };
    Object.assign(quantity, id, carts_id);
    handleQty(quantity).then((res) => {
      setOpen(false);
      if (res) {
        toast.success("Quantity Updated");
        showCardinCarts(carts_id).then((res) => {
          if (res) {
          }
        });
      }
    });
  };
  const handleDec = () => {
    if (qty === 1) {
      setQty(qty);
    } else {
      setOpen(true);
      setQty(qty - 1);
      const quantity = { quantity: qty - 1 };
      Object.assign(quantity, id);
      handleQty(quantity).then((res) => {
        if (res) {
          setOpen(false);
          toast.success("Quantity Updated");
          showCardinCarts(carts_id).then((res) => {
            if (res) {
            }
          });
        }
      });
    }
  };

  const handleClickOpen = () => {
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
    if (gift_id) {
      id = { cart_items_id: gift_id };
    } else {
      id = { cart_items_id: item.cart_items_id };
    }
    removeItem(id).then((res) => {
      if (res) {
        setGift_id();
        setOpen(true);
        toast.success("Item Removed");
        if (res?.data?.data?.is_empty) {
          storage.destroy.carts_id();
          setOpen(false);
        } else {
          setTimeout(() => {
            console.log("ram");
            showCardinCarts(carts_id).then((res) => {
              if (res) {
                setOpen(false);
              } else {
                setOpen(false);
              }
            });
          }, 2000);
        }
      }
    });
  };
  const handleRemove = (param) => {
    handleClickOpen();
    typeof param === "string" ? setGift_id(param) : setGift_id();
  };
  const onSubmit = () => {
    setOpen(true);
    const update = { message: msg };
    Object.assign(update, id, carts_id);
    updateMsg(update).then((res) => {
      setOpen(false);
      if (res.data.status) {
        setOpen(false);
        toast.success("Updated Sucessfully!!");
      } else {
        setOpen(false);
      }
    });
  };
  const handleMsg = (e) => {
    setMsg(e.target.value);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          pt: 2,
          pb: 2,
          height: { xs: 100, md: 140 },
          width: "100%",
          alignItems: "center",
        }}>
        <LoaderComponent open={open} />
        <Toastify />
        {loading && (
          <Box sx={{ width: "100px", height: "100px", ml: 2 }}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: 50, sm: 70, md: 90, lg: 120, xl: 150 },
                height: { xs: 80, md: 100 },
              }}
            />
          </Box>
        )}
        {!loading && (
          <Box
            sx={{
              height: { xs: 110, md: 155 },
              width: { xs: "100px", md: "125px" },
              pl: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}>
            {item.item_type == FILE_TYPE.IMAGE && (
              <img alt="itemimage" style={{ height: "50%", width: "100%" }} src={item?.item_img_formatted} />
            )}
            {item.item_type == FILE_TYPE.GIF && (
              <img alt="itemimage" style={{ height: "50%", width: "100%" }} src={item?.e_gift_cards_img} />
            )}
            {item.item_type == 3 && <img alt="itemimage" style={{ height: "50%", width: "100%" }} src={item?.gift_cards_img} />}

            <Link
              style={{
                color: "#3D2E57",
                fontSize: "18px",
                fontWeight: 400,
                textTransform: "none",
                textDecoration: "none",
              }}
              onClick={() => viewSubItem(item)}>
              View
            </Link>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: 180,
            height: { lg: 125, md: 117, sm: 92, xs: 95 },
            maxHeight: 500,
            pl: { xs: 2, md: 3, lg: 5, xl: 8 },
            fontSize: { xs: "13px", md: "18px" },
            alignItems: "flex-start",
          }}>
          {loading && (
            <Box sx={{ width: "50px", height: "100px" }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: 60, sm: 130, md: 150, lg: 170, xl: 250 },
                  height: 40,
                }}
              />
            </Box>
          )}

          {!loading && (
            <Typography
              sx={{
                textOverflow: "ellipsis",
                fontSize: { xs: "16px", md: "18px" },
                width: { xs: 160, md: 200 },
                overflow: "hidden",
                whiteSpace: "wrap",
              }}>
              {item.item_type == FILE_TYPE.VIDEO ? "Gift Card" : item.item_type == FILE_TYPE.GIF ? "E-Gift Card" : item?.item_title}
            </Typography>
          )}
          {loading && (
            <Box sx={{ height: "100px" }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: 30, sm: 60, md: 70, lg: 80, xl: 90 },
                  height: 30,
                }}
              />
            </Box>
          )}
          {!loading && (
            <Link
              onClick={handleRemove}
              style={{
                color: "red",
                textTransform: "none",
                fontSize: "18px",
                fontWeight: 400,
                textDecoration: "none",
              }}>
              Remove
            </Link>
          )}
        </Box>
        {loading && (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              ml: { xs: 1, sm: 6, md: 7, lg: 15, xl: 20 },
              mt: 3,
            }}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: 50, sm: 60, md: 80, lg: 130, xl: 150 },
                height: 70,
              }}
            />
          </Box>
        )}
        {item.item_type == FILE_TYPE.IMAGE && (
          <>
            {!loading && (
              <Typography
                sx={{
                  color: "#A8A8A8",
                  pl: { xs: 0, sm: 1, md: 14, lg: 27, xl: 27 },
                  fontSize: { xs: "16px", md: "18px" },
                  mb: { xs: 5, md: 0 },
                }}>
                Qty
              </Typography>
            )}
            {loading && (
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  ml: { xs: 1, sm: 2, md: 3, lg: 5, xl: 7 },
                  mt: 3,
                }}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: { xs: 50, sm: 60, md: 80, lg: 130, xl: 150 },
                    height: 70,
                  }}
                />
              </Box>
            )}
            {!loading && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: { md: 60, xs: 40 },
                  height: { md: 38, xs: 20 },
                  ml: { xs: 1, sm: 1, md: 3, lg: 5, xl: 8 },
                  justifyContent: "space-around",
                  border: "1px solid #3D2E57",
                  borderRadius: 1,
                  mb: { xs: 5, md: 0 },
                }}>
                <Typography
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    ml: { xs: 2, lg: 2 },
                    fontWeight: "600",
                    display: "flex",
                    fontSize: { xs: "16px", md: "18px" },
                  }}>
                  {item.item_qty}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: 40,
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}>
                  <IconButton onClick={handleInc} sx={{ height: "3%" }}>
                    <KeyboardArrowUpIcon
                      sx={{
                        height: { xs: 15, md: 25 },
                        width: { xs: 20, md: 30 },
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={handleDec} sx={{ mt: { xs: -1, md: -0.3 }, height: "3%" }}>
                    <KeyboardArrowDown
                      sx={{
                        height: { xs: 15, md: 25 },
                        width: { xs: 20, md: 30 },
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            )}
          </>
        )}
        {item.item_type == 2 && (
          <Box
            sx={{
              width: "35%",
            }}
          />
        )}
        {loading && (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              ml: { xs: 2, sm: 5, md: 7, lg: 10, xl: 15 },
              mt: 3,
            }}>
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: 60, sm: 70, md: 80, lg: 130, xl: 150 },
                height: 70,
              }}
            />
          </Box>
        )}
        {!loading && (
          <Box
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              display: "flex",
              pr: { xs: "10px", md: "60px" },
              fontSize: { xs: "16px", md: "20px" },
              fontWeight: 400,
              mb: { xs: 5, md: 0 },
            }}>
            ${item.item_total_price}
          </Box>
        )}
      </Box>
      {cart && (
        <Box>
          <Box
            sx={{
              width: "100%",
              height: 41,
              bgcolor: "#F6F6F6",
              display: "flex",
              alignItems: "center",
            }}>
            <Typography
              sx={{
                ml: 2,
                fontWeight: "400",
                fontSize: "20px",
                color: "#3D2E57",
              }}>
              Recipent Details
            </Typography>
          </Box>
          <Box sx={{ mt: 2, mb: 2, ml: 2 }}>
            <Typography sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: {
                    md: 300,
                  },
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}>
                <span style={{ marginRight: "60px" }}> Name:</span>
                {item.recipient_name}
              </Typography>
              <Typography sx={{ width: { md: 550 } }}>
                <span style={{ marginRight: "60px" }}>Email: </span> {item.recipient_email}
              </Typography>
              <Typography sx={{ width: { md: 550 } }}>
                <span style={{ marginRight: "40px" }}> Contact: </span> {item.recipient_contact}
              </Typography>
              {item.item_type == FILE_TYPE.IMAGE && (
                <Typography sx={{ width: { md: 550 } }}>
                  <span style={{ marginRight: "40px" }}>Address:</span>
                  {item.recipient_address1}
                </Typography>
              )}
            </Typography>
          </Box>
          {item.item_file_type == FILE_TYPE.IMAGE && (
            <>
              {item.child.length > 0 && (
                <Box
                  sx={{
                    width: { xs: "90%", md: "100%" },
                    ml: { xs: 2, md: 0 },
                    height: 41,
                    bgcolor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                    border: { xs: 0, md: 1 },
                    borderRadius: "5px",
                  }}>
                  <Typography sx={{ ml: 2, fontWeight: "600" }}>Gift Card</Typography>
                </Box>
              )}

              {item.child &&
                item.child.map((gift, ind) => (
                  <Box
                    sx={{
                      display: "flex",
                      height: 140,
                      width: "100%",
                      alignItems: "center",
                    }}>
                    <Box
                      sx={{
                        height: 120,
                        width: 60,
                        ml: { xs: 0, md: 2 },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}>
                      <img alt="logo" style={{ height: 75, width: 52 }} src={require("../../assets/Group 592.png")} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 120,
                        ml: { xs: 2, md: 5 },
                        width: { xs: "90%", md: "100%" },
                        alignItems: "center",
                      }}>
                      <Typography
                        sx={{
                          fontSize: { xs: 10, md: 15 },
                        }}>
                        Gift Card Voucher
                      </Typography>
                      <Box
                        sx={{
                          width: 100,
                          justifyContent: "center",
                          fontSize: { xs: 10, md: 15 },
                        }}>
                        ${gift.item_total_price}
                      </Box>
                      <Button onClick={(e) => handleRemove(gift.cart_items_id)} sx={{ color: "red", fontSize: { xs: 10, md: 15 } }}>
                        Remove
                      </Button>
                    </Box>
                  </Box>
                ))}
              {item.item_type == 1 && (
                <>
                  <Typography sx={{ fontWeight: "600", ml: 2 }}>Message</Typography>
                  <TextField
                    fullWidth={true}
                    multiline={true}
                    variant="filled"
                    defaultValue="Enter your message here"
                    type="text"
                    aria-label="Enter Your Message here"
                    rows={4}
                    value={msg}
                    onChange={handleMsg}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      bgcolor: "#F6F6F6",
                      width: { xs: "90%", md: "100%" },
                      ml: 2,
                      borderRadius: 2,
                      border: "0px",
                      borderColor: "red",
                      borderBottom: 0,
                    }}
                  />
                  <Button
                    onClick={onSubmit}
                    variant="outlined"
                    sx={{
                      mt: 2,
                      mb: 2,
                      ml: 2,
                      width: { md: "20%", xs: "40%" },
                      border: "1px solid #3D2E57",
                      color: "#3D2E57",
                      fontWeight: "700",
                      fontSize: "14px",
                      borderRadius: "15px",
                      textTransform: "none",
                    }}>
                    Update Message{" "}
                  </Button>
                </>
              )}
            </>
          )}
          {item.item_file_type == FILE_TYPE.GIF && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: 41,
                  bgcolor: "#F6F6F6",
                  mb: 2,
                }}>
                <Typography
                  sx={{
                    ml: 2,
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#3D2E57",
                    pt: 1,
                  }}>
                  Gif
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}>
                <img alt="logo" style={{ height: 66, width: 66 }} src={item.item_img_formatted} />
              </Box>
            </>
          )}
          {item.item_file_type == FILE_TYPE.VIDEO && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: 41,
                  bgcolor: "#F6F6F6",
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}>
                <Typography sx={{ ml: 2, fontWeight: "600" }}>Video</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                }}>
                <video width="15%" controls>
                  <source src={item.item_img_formatted} />
                </video>
              </Box>
            </>
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", ml: 10 }}>
        <Dialog open={popup} sx={{ ml: 8, mt: 5 }}>
          <DialogContent
            sx={{
              width: 500,
            }}>
            <DialogContentText
              sx={{
                fontSize: "24px",
              }}>
              Are you sure ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPopup(false);
              }}>
              No
            </Button>
            <Button onClick={handleClose} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    removeItem: (id) => dispatch(removeItem(id)),
    showCardinCarts: (carts_id) => dispatch(showCardinCarts(carts_id)),
    updateMsg: (model) => dispatch(updateMsg(model)),
    handleQty: (quantity) => dispatch(handleQty(quantity)),
  };
}
export default connect(null, mapDispatchToProps)(ViewCartDetailsAccordian);
