/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { storage } from "../../config/storage";
import { getCardListing, getCategoryName } from "../../redux/action";
import { connect, useSelector } from "react-redux";
import { CARDLISTING, LOGIN, MYPROFILE, REGISTER } from "../routes/ConstURL";

const Drawer = ({ open, onClick, getCategoryName, getCardListing }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerArray, setdrawerArray] = useState([]);
  useEffect(() => {
    let data = {
      start: 0,
      length: 16,
    };
    if (isMobile) {
      getCategoryName(data).then((res) => {
        if (res.data.data) {
          setdrawerArray(res?.data?.data1?.categories);
          // if (drawerArray.length) {
          //   drawerArray.unshift({ category_name: "All greeting cards" });
          //   setdrawerArray([...drawerArray]);
          // }
        } else {
        }
      });
    }
  }, []);

  const navigate = useNavigate();
  const DrawerItem = [
    {
      category: "Greeting Cards",
      drawerArray,
      // listing: [
      //  {name: "All Greeting Cards",link:"/greetingcategories"},
      //   {name:"Birthday Cards"},
      //   {name:"Thank you Cards"},
      //   {name:"Congratulations"},
      //   {name:"New Baby Cards"},
      //   {name:"Special Occasions"},
      //   {name:"With Sympathy"},
      // ],
    },
    { category: "Gift Cards" },
    { category: "E - Gift Card" },
  ];
  const [Show, setShow] = useState(0);
  const handleClick1 = () => {
    if (storage?.fetch?.authToken()?.length > 7) {
      navigate(MYPROFILE);
    } else {
      navigate(LOGIN);
    }
  };

  const handleClick2 = () => {
    if (storage?.fetch?.authToken()?.length > 7) {
      storage.destroy.authToken();
      navigate(LOGIN);
    } else {
      navigate(REGISTER);
    }
  };
  const showItem = (text, index) => {
    if (Show === index + 1) {
      setShow(0);
    } else if (index === 0) {
      setShow(1);
    } else {
      index === 1 ? navigate("giftcardamount") : navigate("egiftcardamount");
    }
  };
  const cardListing = (item, index) => {
    storage.set.category_id(item);
    // let id_storage=storage.fetch.category_id();
    let id = { category_id: item };
    getCardListing(id);
    navigate(CARDLISTING);
  };

  const list = (anchor) => (
    <Box
      sx={{
        maxWidth: 250,
        display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" },
        // overflowY:'hidden',
        // overflowX:'hidden'
      }}
      //   role="presentation"
      //   onClick={state}
      //   onKeyDown={state}
    >
      <List sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            pr: 3,
            justifyContent: "flex-end",
          }}>
          <IconButton onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </Box>

        {DrawerItem.map((text, index) => (
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            }}
            key={index}
            disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              onClick={() => {
                showItem(text, index);
                index === 0 ? "" : onClick();
              }}>
              <ListItemText sx={{ width: "100%" }} primary={text.category}></ListItemText>
              <ListItemIcon sx={{}}>{index === 0 ? <ArrowForwardIosIcon sx={{ ml: "30px" }} /> : ""}</ListItemIcon>
            </ListItemButton>
            {Show === 1 && text.drawerArray
              ? text.drawerArray.map((val, index) => {
                  return (
                    <ListItem key={index} disablePadding sx={{ width: "80%", ml: 2 }}>
                      <ListItemButton
                        onClick={() => {
                          cardListing(val.category_id, index);
                          onClick();
                        }}>
                        <ListItemText sx={{}} primary={val.category_name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })
              : ""}
          </ListItem>
        ))}
        <ListItemButton
          sx={{ pt: "15px", pb: "15px" }}
          onClick={() => {
            handleClick1();
            onClick();
          }}>
          <ListItem
            disablePadding
            sx={{
              // display: "flex",
              width: "100%",
            }}>
            {storage?.fetch?.authToken()?.length > 7 ? "My Account" : "Log in "}
          </ListItem>
        </ListItemButton>
        <ListItemButton
          sx={{ pt: "15px", pb: "15px" }}
          onClick={() => {
            handleClick2();
            onClick();
          }}>
          <ListItem
            disablePadding
            sx={{
              // display: "flex",
              // flexDirection: "column",
              width: "100%",
            }}>
            {storage?.fetch?.authToken()?.length > 7 ? "Log out" : "Sign up "}
          </ListItem>
        </ListItemButton>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      {["", "right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            ModalProps={{
              hideBackdrop: "false",
            }}
            anchor={"right"}
            open={open}
            onClose={true}
            onOpen={true}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getCategoryName: (data) => dispatch(getCategoryName(data)),
    getCardListing: (id) => dispatch(getCardListing(id)),
  };
}
export default connect(null, mapDispatchToProps)(Drawer);
// <ListItem
//   key={index}
//   disablePadding
//   sx={{ width: "80%", ml: 2 }}
// >
//   {/* <ListItemButton
//     onClick={() => val.onclick}
//   >
//     <ListItemText sx={{}} primary={val.name} />
//   </ListItemButton> */}
//   pppp
// </ListItem>
