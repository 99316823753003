/* eslint-disable no-duplicate-case */
import {
  CARD_LISTING,
  CATEGORY_LIST,
  PHONE_VERIFY,
  REGISTER_USER,
  OTP_VERIFY,
  EMAIL_OTP,
  CARD_DETAIL,
  ORDER_DETAIL,
  USER_DATA,
  CARD_IN_CARTS,
  ADD_MESSAGE,
  SHOW_COUNTRY_DATA,
  ADDRESS_LIST,
  E_GIFT_CARD_AMOUNT,
  E_GIFT_CARD_GIF
} from "../action/actionCreator";

const initialState = {
  registerData: [],
  categoryList: [],
  cardlisting: [],
  carddetail: [],
  orderdetail: [],
  cardpersonalise: [],
  cart_item:[],
  country_data:[],
  addressList:[],
  e_Gift_card_data:[],
  e_gift_gifs:[]
};
const User = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        registerData: action.payload,
      };
    case CATEGORY_LIST:
      return {
        ...state,
        categoryList: action?.payload?.data1,
      };
    case CARD_DETAIL:
      return {
        ...state,
        carddetail: action?.payload?.data,
      };
      case ORDER_DETAIL:
      return {
        ...state,
        orderdetail: action?.payload?.data,
      };
    case CARD_LISTING:
      return {
        ...state,
        cardlisting: action?.payload?.data1,
      };
      case ADDRESS_LIST:
      return {
        ...state,
        addressList: action?.payload?.data.user_addresses,
      };
    case PHONE_VERIFY:
      return {
        ...state,
        user: action?.payload?.status,
      };
    case OTP_VERIFY:
      return {
        ...state,
        user: action?.payload?.status,
      };
    case EMAIL_OTP:
      return {
        state: action?.payload?.data,
      };
    case USER_DATA:
      return {
        state: action?.payload?.data,
      };
      case CARD_IN_CARTS:
      return {
        ...state,
        cart_item: action?.payload?.data
      };
      case ADD_MESSAGE:
      return {
        state: action?.payload?.data
      };
      case SHOW_COUNTRY_DATA:
        return {
          country_data: action?.payload?.data
        };
        case E_GIFT_CARD_AMOUNT:
        return {
          e_Gift_card_data: action?.payload?.data
        };
        case E_GIFT_CARD_GIF:
        return {
          e_gift_gifs: action?.payload?.data1
        };

    default:
      return state;
  }
};
export default User;
