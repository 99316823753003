/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Typography, Backdrop, CircularProgress } from "@mui/material";
import "./loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import Toastify from "../SnackBar/Toastify";
import LoginwithEmail from "./loginwithEmail";
import LoginwithPhone from "./loginwithPhone";
import { LOGIN, REGISTER } from "../routes/ConstURL";
/**
 * Form Validation Schema
 */

const LoginWithOtp = () => {
  const navigate = useNavigate();
  const [sendedOTP, setSendedOTP] = React.useState(false);
  const [emailOTP, setEmailOTP] = React.useState(false);
  const [phoneOTP, setPhoneOTP] = React.useState(true);
  const [loader, setloader] = React.useState(false);
  const [otpverify, setOtpverify] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F3F0EE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt: { lg: 18, xs: 12, sm: 14, md: 16 },
        }}>
        <Box
          sx={{
            maxWidth: "900px",
            width: { xs: "92%", sm: "80%", md: "65%", lg: "50%", xl: "50%" },
            mt: { sm: "10px", md: "20px", lg: "30px", xl: "30px", xs: "0px" },
            borderRadius: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "20px",
              xl: "20px",
            },
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "35px" },
              lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
              fontWeight: "700",
              textAlign: "center",
              color: "#3D2E57",
              mt: 5,
              mb: 4,
            }}>
            Login
          </Typography>

          <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
            Send otp to
            <Button
              disableRipple
              sx={{
                ml: "10px",
                bgcolor: emailOTP ? "#FFFFFF" : "#FF8D2A",
                color: emailOTP ? "#3D2E57" : "",
                textTransform: "none",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: emailOTP ? "#FFFFFF" : "#FF8D2A",
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              variant="contained"
              onClick={() => {
                setEmailOTP(false);
                setPhoneOTP(true);
              }}>
              Email
            </Button>
            <Button
              onClick={() => {
                setEmailOTP(true);
                // setPhoneOTP(false)
                setSendedOTP(false);
                setOtpverify(false);
              }}
              variant="contained"
              disableRipple
              sx={{
                color: emailOTP ? "" : "#3D2E57",
                textTransform: "none",
                bgcolor: emailOTP ? "#FF8D2A" : "#FFFFFF",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: emailOTP ? "#FF8D2A" : "#FFFFFF",
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}>
              Phone
            </Button>
          </Typography>
          {emailOTP ? <LoginwithPhone /> : <LoginwithEmail />}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "center" },
            }}>
            <Link
              to={REGISTER}
              className="fontlink2"
              style={{
                fontWeight: 400,
                color: "#FF8D2A",
                textDecoration: "none",
                marginBottom: "10px",
              }}>
              Register your account
            </Link>

            <Button
              disableRipple
              onClick={() => navigate(LOGIN)}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                },
                textTransform: "none",
                fontWeight: 400,
                color: "#3D2E57",
                textDecoration: "none",
                maxHeight: "37px",
                mb: "10px",
                fontSize: { xs: "13px", md: "20px" },
                pt: 0,
                pb: 1,
              }}
              variant="text">
              Login With Password
            </Button>
          </Box>
          <Toastify />
        </Box>
      </Box>
    </>
  );
};

export default LoginWithOtp;
