export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const CARD_LISTING = "CARD_LISTING";
export const FORGOT_GEN_OTP = "FORGOT_GEN_OTP";
export const FORGOT_MATCH_OTP = "FORGOT_MATCH_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const EMAIL_RESEND_OTP = "EMAIL_RESEND_OTP";
export const PHONE_VERIFY = "PHONE_VERIFY";
export const OTP_VERIFY = "OTP_VERIFY";
export const EMAIL_OTP = "EMAIL_OTP";
export const CARD_DETAIL = "CARD_DETAIL";
export const CARD_IN_CARTS = "CARD_IN_CARTS";
export const USER_DATA = "USER_DATA"
export const ADD_GREETING_CARD_TO_CARD = "ADD_GREETING_CARD_TO_CARD"
export const UPDATE_USER = "UPDATE_USER"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const CHECK_MESSAGE = "CHECK_MESSAGE"
export const CHECK_RECIPENTS = "CHECK_RECIPENTS"
export const REMOVE_ITEM = "REMOVE_ITEM"
export const CHECKOUT = "CHECKOUT"
export const SHOW_COUNTRY_DATA = "SHOW_COUNTRY_DATA"
export const UPDATE_MSG = "UPDATE_MSG"
export const HANDLE_QTY = "HANDLE_QTY"
export const ADDRESS_LIST = "ADDRESS_LIST"
export const  E_GIFT_CARD_AMOUNT="E_GIFT_CARD_AMOUNT"
export const  E_GIFT_CARD_GIF="E_GIFT_CARD_GIF"
export const  ORDER_DETAIL="ORDER_DETAIL"


