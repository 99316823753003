import * as React from "react";

import Box from "@mui/material/Box";

import { Card, CardMedia, Typography } from "@mui/material";

const BoxContainer2 = () => {
  return (
    <Box sx={style.mainBox}>
      <Box sx={style.wrapBox}>
        <Box sx={style.card}>
          <Box sx={style.cardBox}>
            <Box sx={style.mediaBox}>
              <CardMedia
                component="img"
                width="100%"
                image={require("../../assets/delievryimg.png")}
                alt="green iguana"
                sx={{
                  borderRadius:"10px",
                  minHeight:'400px',

                }}
              />
            </Box>
            <Box sx={style.textBox}>
              <Box sx={style.TypographyBox}>
                <Typography sx={style.text1}>
                This Month's Chartable Partner
                </Typography>
                <Typography variant="h2" sx={style.text2}>
                Royal Flying Doctor Service
                </Typography>
                <Typography sx={style.text3}>
                  The Royal Flying Doctor Service has been saving lives in rural
                  and remote Australia for more than 90 years . Delivered by a
                  dedicated team of professionals and supported by a vast number
                  of volunteers and supporters, the RFDS provides a lifeline for
                  those that live , work and travel in rural and remote
                  Australia.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BoxContainer2;
const style = {
  mainBox: { display: "flex", width: "100%", justifyContent: "center" },
  wrapBox: {
    flexDirection: "row",
    display: "flex",
    maxWidth: "1360px",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    width: "100%",
    pt: 3,
    justifyContent: "space-between",
   
  },
  card: {
    width: "100%",
    bgcolor: "#F9603A",
    borderRadius: 3,
  },
  cardBox: {
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    height: "100%",
  },
  mediaBox: {
    flexDirection: "column",
    display: "flex",
    width: {
      xs: "100%",
      sm: "100%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
    minHeight: {
      xs: "auto",
      sm: "auto",
      md: "400px",
      lg: "400px",
      xl: "400px",
    },
    
  },
  textBox: {
    flexDirection: "column",
    justifyContent: {
      xs: "space-between",
      sm: "space-between",
      md: "space-between",
      lg: "space-evenly",
      xl: "space-evenly",
    },
    display: "flex",
    width: {
      xs: "100%",
      sm: "100%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
    
    pt: { xs: 5, sm: 12, lg: 3, md: 7, xl: 0 },
    pb: { xs: 5, sm: 12, lg: 3, md: 7, xl: 0 },
    alignItems: "center",
  },
  TypographyBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "90%",
    
  },
  text1: {
    color: "white",
    fontSize: { xs: 15, sm: 20, md: 20, lg: 18, xl: 18 },
    textAlign: "center",
    fontWeight: "500",
    lineHeight: '22px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    mb:1,
  },
  text2: {
    color: "white",
    width: "70%",
    fontSize: { xs: 25, sm: 35, md: 40, lg: 50, xl: 50 },
    lineHeight: {xs:'35px',sm:'50.4px'},
    letterSpacing: '-0.015em',
    textAlign: "center",
    fontWeight: "900",
    mb: {xs:2,sm:3},
  },
  text3: {
    color: "white",
    fontSize: 18,
    lineHeight: '21px',
    width:'82%',
    // mb: 3,
    fontWeight: "400",
    letterSpacing: '-0.015em',
    textAlign: "center",
  },
};
