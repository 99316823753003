/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
const Wrapper = ({ children }) => {
  const [color, setcolor] = useState("#F3F0EE");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setcolor("white");
    } else {
      setcolor("#F3F0EE");
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        bgcolor: color,
      }}
    >
      <Box
        sx={{
          maxWidth: {xl:"1360px",lg:"1200px"},
          mr: "auto",
          ml: "auto",
          bgcolor: "white",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Wrapper;
