import React, { useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Button, Typography, Backdrop, CircularProgress, TextField, FormLabel, Select, MenuItem, Skeleton } from "@mui/material";
import "./loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../Custom/Input/InputField";
import { storage } from "../../config/storage";
import Toastify from "../SnackBar/Toastify";
import { connect, useSelector } from "react-redux";
import { getLoginWithPhone, verifyPhoneOtp, country_dropdown_data } from "../../redux/action";
import { useFormik } from "formik";
import LoaderComponent from "../Loader/LoaderComponent";

const LoginwithPhone = ({ getLoginWithPhone, verifyPhoneOtp, country_dropdown_data }) => {
  const navigate = useNavigate();
  const [loader, setloader] = React.useState(false);
  const [sendedOTP, setSendedOTP] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [value, setValue] = React.useState("");
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("61");
  const [countryNumberRange, setCountryNumberRange] = React.useState({
    max: 9,
    min: 9,
    id: "",
  });
  const newState = useSelector((state) => state?.User?.country_data);
  const country_names = newState?.country_list;
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Please enter your phone number")
      .min(countryNumberRange.min, `Enter minimum ${countryNumberRange.min} numbers `)
      .max(countryNumberRange.max, `Enter maximum ${countryNumberRange.max} numbers`),
    login_phone_otp: yup.string().when("otpSent", {
      is: true,
      then: yup.string().required("Please enter OTP"),
    }),
  });

  let cart_id = storage.fetch.carts_id();
  let id = {};
  if (cart_id !== undefined) {
    id = { carts_id: cart_id, carts_id_check: true };
  } else {
    id = { carts_id_check: false };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    country_dropdown_data();
  }, []);
  let defaultValues = {
    phone: "",
    login_phone_otp: "",
    otpSent: sendedOTP,
  };
  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleOtp = (e) => {
    setOTP(e.target.value);
  };
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit: (value) => {
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onChangeSelect = (e) => {
    setCountryPhoneCode(e.target.value.phone_code);
    setCountryNumberRange({
      max: e.target.value.sizeof_nn.max,
      min: e.target.value.sizeof_nn.min,
      id: e.target.value.country_id,
    });
  };
  const onSubmit = (model) => {
    setloader(true);
    let item = model;
    item.phonecode = `+${countryPhoneCode}`;
    item.country_id = countryNumberRange.id;
    if (sendedOTP) {
      Object.assign(item, id);
      verifyPhoneOtp(item).then((res) => {
        setloader(false);
        if (res.data.status) {
          storage.set.authToken(res?.data?.token);
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
    } else {
      delete item.otpSent;
      delete item.login_phone_otp;
      getLoginWithPhone(item).then((res) => {
        setloader(false);
        if (res.data.status) {
          setSendedOTP(true);
        } else {
          res?.data?.errors?.map((item) => {
            return toast.error(item);
          });
        }
      });
    }
  };
  return (
    <form
      name="LoginForm"
      onSubmit={formik.handleSubmit}
      style={{
        width: "90%",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      <LoaderComponent open={loader} />
      <FormLabel
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          color: "#333333",
        }}>
        Phone
        <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <TextField
        name="phone"
        autoComplete="false"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type="number"
        variant="filled"
        InputProps={{
          disableUnderline: true,
          pt: "10px",
          style: { backgroundColor: "#F6F6F6" },
          startAdornment: (
            <Select
              disableUnderline
              SelectDisplayProps={{
                style: {
                  padding: 0,
                  backgroundColor: "#F6F6F6",
                  color: "#A8A8A8",
                },
              }}
              sx={{
                height: "30px",
                width: "90px",
                backgroundColor: "#F6F6F6",
                borderBottom: "none",
                "&.MuiInputBase-root": {
                  backgroundColor: "transparent",
                },
                "&.MuiFilledInput-root": {
                  backgroundColor: "transparent",
                },
              }}
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              // defaultValue="select"
              value={countryPhoneCode}
              onChange={onChangeSelect}
              label="Country"
              inputProps={{
                style: {
                  backgroundColor: "#F6F6F6",
                  border: "none",
                },
              }}>
              {/* {country_names &&editAddressId&&
                        country_names.map((item, index) => (
                          <MenuItem value={formik.values.recipient_country}>{formik.values.recipient_country}</MenuItem>
                        ))} */}
              <MenuItem value={countryPhoneCode}>+{countryPhoneCode}</MenuItem>
              {!country_names && [1, 2, 3, 4, 5].map((item, index) => <Skeleton height={71} />)}
              {country_names &&
                country_names.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    +{item.phone_code}
                  </MenuItem>
                ))}
            </Select>
          ),
        }}
        inputProps={{
          style: {
            paddingTop: "16px",
            paddingBottom: "15px",
          },
        }}
        color="primary"
        placeholder="Enter Phone Number"
        sx={{
          width: "100%",
          border: "none",
        }}
      />
      {value === "" ? <p style={{ color: "red" }}>{formik.errors.phone}</p> : null}
      {sendedOTP && (
        <>
          <FormLabel
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "24px",
              color: "#333333",
            }}>
            OTP
            <span>*</span>
          </FormLabel>
          <TextField
            name="login_phone_otp"
            autoComplete="false"
            onChange={handleOtp}
            onBlur={formik.handleBlur}
            type="text"
            variant="filled"
            InputProps={{ disableUnderline: true, pt: "10px" }}
            inputProps={{
              style: {
                paddingTop: "16px",
                paddingBottom: "15px",
              },
            }}
            color="primary"
            placeholder="Enter OTP here"
            sx={{
              width: "100%",
              border: "none",
            }}
          />
          {formik.errors.login_phone_otp && formik.touched.login_phone_otp ? (
            <p style={{ color: "red" }}>{formik.errors.login_phone_otp}</p>
          ) : null}
        </>
      )}
      {sendedOTP && (
        <Button
          disableRipple
          sx={{
            width: {
              xs: "100%",
              sm: "90%",
              md: "60%",
              lg: "40%",
              xl: "40%",
            },
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#FFFFFF",
              border: "1.5px solid #3D2E57",
            },
            bgcolor: "#FFFFFF",
            color: "#3D2E57",
            border: "1.5px solid #3D2E57",
            // p: 1,
            mb: 2,
            mt: 2,
            borderRadius: "15px",
            fontSize: { xs: "20px", md: "22px", lg: "24px" },
            fontWeight: 500,
            textTransform: "none",
          }}
          variant="outlined"
          type="submit">
          Submit OTP
        </Button>
      )}
      {!sendedOTP && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            disableRipple
            type="submit"
            variant="text"
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#FFFFFF",
                border: "1.5px solid #3D2E57",
              },
              border: "1.5px solid #3D2E57",

              color: "#3D2E57",
              fontWeight: "400",
              fontSize: "20px",
              textTransform: "none",
              width: {
                xs: "100%",
                sm: "90%",
                md: "60%",
                lg: "40%",
                xl: "40%",
              },
              borderRadius: "15px",
              mb: 2,
            }}>
            Send OTP
          </Button>
        </Box>
      )}
    </form>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getLoginWithPhone: (item) => dispatch(getLoginWithPhone(item)),
    verifyPhoneOtp: (item) => dispatch(verifyPhoneOtp(item)),
    country_dropdown_data: () => dispatch(country_dropdown_data()),
  };
}

export default connect(null, mapDispatchToProps)(LoginwithPhone);
