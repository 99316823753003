import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_gift_card_amount } from "../../redux/action";
import CardAmount from "../Custom/CardAmount";
import { GIFT_SELECT } from "../routes/ConstURL";

const amount = ["$50", "$100", "$150", "$160", "$200", "$250", "$300", "$350", "$450", "$500"];
const img = require("../../assets/Group 592.png");

const GiftCardAmount = ({ get_gift_card_amount }) => {
  const [selectedIndex, setSelectedIndex] = React.useState([]);

  const navigate = useNavigate();
  React.useEffect(() => {
    let arrayMap = [];
    get_gift_card_amount().then((res) => {
      res.data.data.gift_cards.map((val) => {
        val["selected"] = false;
        arrayMap.push(val);
      });
      setSelectedIndex(arrayMap);
    });
  }, []);
  return (
    <>
      <CardAmount
        amount={amount}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        width={"392px"}
        image={img}
        pt={"100px"}
        pb={"100px"}
        grid={true}
        heading={"Select Amount"}
        bgcolor={"#3DD5B7"}
        gift={true}
        onClick={GIFT_SELECT}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    get_gift_card_amount: () => dispatch(get_gift_card_amount()),
  };
}
export default connect(null, mapDispatchToProps)(GiftCardAmount);
