/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as yup from "yup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SaveAddressList, getUserAddress } from "../../redux/action";
import { storage } from "../../config/storage";
import { check_recipent, country_dropdown_data } from "../../redux/action";
import { useFormik } from "formik";
import { Box, Button, Typography, FormLabel, Skeleton, TextField, MenuItem, Select, InputLabel } from "@mui/material";
import Toastify from "../SnackBar/Toastify";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoaderComponent from "../Loader/LoaderComponent";
import { MYADDRESS } from "../routes/ConstURL";

const Recipients = ({
  check_recipent,
  country_dropdown_data,
  egift,
  heading,
  buttonText,
  bgcolor,
  inputBgcolor,
  validate,
  route,
  SaveAddressList,
  getUserAddress,
  editAddressId,
  recipient,
}) => {
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("61");
  const [countryNumberRange, setCountryNumberRange] = React.useState({
    max: 9,
    min: 9,
    id: "",
  });
  const schema = yup.object().shape({
    recipient_name: yup.string().required("Please enter name"),
    recipient_email: yup.string().required("Please enter your email").email("Please enter valid email"),
    recipient_contact: yup
      .string()
      .required("Please enter your phone number")
      .min(countryNumberRange.min, `Enter minimum ${countryNumberRange.min} numbers `)
      .max(countryNumberRange.max, `Enter maximum ${countryNumberRange.max} numbers`),

    recipient_dob: yup.string().required("Please enter your Date of birth"),
    validate: yup.boolean(),
    recipient_city: yup.string().when("validate", {
      is: true,
      then: yup.string().required("Please enter the city"),
    }),
    recipient_country: yup.string().required("Select Country"),
    recipient_address1: yup.string().when("validate", {
      is: true,
      then: yup.string().required("Delivery Address is required"),
    }),
    recipient_postcode: yup.string().when("validate", {
      is: true,
      then: yup.string().required("Postcode is required"),
    }),
  });
  const navigate = useNavigate();
  const newState = useSelector((state) => state?.User?.country_data);
  const country_names = newState?.country_list;

  const [open, setOpen] = useState(false);

  const msg = storage.fetch.message();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("Select Country");
  const location = useLocation();
  const trimmedURL = location.pathname.slice(0, 6);
  const recipient_data = storage.fetch.recipientDetails();
  if (editAddressId) {
    heading = "Edit address";
    buttonText = "Save address";
  }
  const [userData, setUserData] = useState({
    recipient_name: "",
    recipient_email: "",
    recipient_contact: "",
    recipient_dob: "",
    recipient_address1: "",
    recipient_address2: "",
    recipient_city: "",
    recipient_postcode: "",
    recipient_country: country,
    phonecode: "",
    validate: validate,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    country_dropdown_data();
    let api_data = {
      address_id: editAddressId,
    };
    if (editAddressId) {
      setLoading(true);
      getUserAddress(api_data).then((res) => {
        setLoading(false);
        if (res) {
          const result = res.data.data.user_address;
          setUserData({
            recipient_name: result.recipient_name,
            recipient_email: result.recipient_email,
            recipient_contact: result.recipient_contact,
            recipient_dob: result.recipient_dob,
            recipient_address1: result.recipient_address1,
            recipient_city: result.recipient_city,
            recipient_postcode: result.recipient_postcode,
            recipient_country: result.recipient_country,
            recipient_address2: result.recipient_address2,
          });
          setCountry(result.recipient_country);
          setCountryPhoneCode(result.phonecode);
          setCountryNumberRange({
            max: result.sizeof_nn.max,
            min: result.sizeof_nn.min,
            id: result.country_id,
          });
        } else {
        }
      });
    } else {
      if (recipient_data) {
        setUserData({
          recipient_name: recipient_data.recipient_name ? recipient_data?.recipient_name : "",
          recipient_email: recipient_data?.recipient_email ? recipient_data?.recipient_email : "",
          recipient_contact: recipient_data?.recipient_contact ? recipient_data?.recipient_contact : "",
          recipient_dob: recipient_data?.recipient_dob ? recipient_data?.recipient_dob : "",
          recipient_address1: recipient_data?.recipient_address1 ? recipient_data?.recipient_address1 : "",
          recipient_city: recipient_data?.recipient_city ? recipient_data?.recipient_city : "",
          recipient_postcode: recipient_data?.recipient_postcode ? recipient_data?.recipient_postcode : "",
          recipient_address2: recipient_data?.recipient_address2 ? recipient_data?.recipient_address2 : "",
        });
        setCountry(recipient_data.recipient_country ? recipient_data.recipient_country : country);
        setCountryPhoneCode(recipient_data.phonecode ? recipient_data.phonecode : countryPhoneCode);
        // setCountryNumberRange({
        //   max: recipient_data.sizeof_nn.max,
        //   min: result.sizeof_nn.min,
        //   id: result.country_id,
        // });
      } else {
      }
    }
  }, []);
  const formik = useFormik({
    initialValues: userData,
    validationSchema: schema,
    onSubmit: (value) => {
      setUserData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });

  const onSubmit = async (model) => {
    let api_data = { address_id: editAddressId };

    Object.assign(model, api_data);
    let item = model;
    Object.assign(item, { phonecode: countryPhoneCode }, { country_id: countryNumberRange.id });

    setLoading(true);

    if (validate) {
      item.recipient_country = country;
      delete model.validate;
    } else {
      delete model.recipient_city;
      delete model.recipient_address1;
      delete model.recipient_country;
      delete model.recipient_postcode;
      delete model.recipient_address2;
      Object.assign(item, { item_type: 2 });
    }
    if (recipient) {
      setLoading(true);
      setOpen(true);
      if (trimmedURL === "/giftc") {
        Object.assign(item, { item_type: 3 });
      } else if (trimmedURL === "/selec") {
        Object.assign(item, { item_type: 2 });
      } else {
        Object.assign(item, { item_type: 1 });
      }
      check_recipent(item).then((res) => {
        setLoading(false);
        setOpen(false);
        if (res.data.status) {
          item.greet_msg = msg?.greet_msg;
          storage.set.recipientDetails(item);
          navigate(route);
        }
      });
    } else {
      setLoading(true);
      SaveAddressList(model).then((res) => {
        setLoading(false);
        if (res.data.status) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigate(MYADDRESS);
          }, 1000);
        } else {
          res?.data?.errors?.map((item) => {
            return toast.error(item);
          });
        }
      });
    }
  };
  const handleChange = (event) => {
    setCountry(event.target.value);
  };
  const handleSelectChange = (e) => {
    setCountryPhoneCode(e.target.value.phone_code);
    setCountryNumberRange({
      max: e.target.value.sizeof_nn.max,
      min: e.target.value.sizeof_nn.min,
      id: e.target.value.country_id,
    });
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: bgcolor,
          pb: recipient ? 4 : 3,
          pt: recipient ? 4 : 3,
          pr: recipient ? { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 } : 3,
          pl: recipient ? { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 } : 3,
        }}>
        <LoaderComponent open={loading} />
        <LoaderComponent open={open} />

        <form
          name="RegisterForm"
          onSubmit={formik.handleSubmit}
          schema={schema}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}>
          <Typography display="block" sx={Style.typographyStyle}>
            {heading}
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "30%",
              borderRadius: 2,
            }}>
            <Box sx={Style.rowBoxStyle}>
              {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Name
                    <span style={Style.star}>*</span>
                  </FormLabel>

                  <TextField
                    name="recipient_name"
                    value={formik.values.recipient_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    color="primary"
                    placeholder="Enter recipients name here"
                    sx={{
                      width: "100%",
                      border: "none",
                      bgcolor: inputBgcolor,
                    }}
                    autoComplete="false"
                  />
                  {formik.errors.recipient_name && formik.touched.recipient_name ? (
                    <p style={{ color: "red" }}>{formik.errors.recipient_name}</p>
                  ) : null}
                </Box>
              )}

              {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Date of Birth
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_dob"
                    value={formik.values.recipient_dob}
                    id="recipient_dob"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="date"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    autoComplete="false"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                        bgcolor: inputBgcolor,
                        textTransform: "uppercase",
                      },
                    }}
                    color="primary"
                    placeholder="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                      border: "none",
                    }}
                  />

                  {formik.errors.recipient_dob && formik.touched.recipient_dob ? (
                    <p style={{ color: "red" }}>{formik.errors.recipient_dob}</p>
                  ) : null}
                </Box>
              )}
            </Box>

            <Box sx={Style.rowBoxStyle}>
              {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Email
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_email"
                    value={formik.values.recipient_email}
                    id="recipient_email"
                    autoComplete="false"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    color="primary"
                    placeholder="Enter recipients email address"
                    sx={{
                      width: "100%",
                      border: "none",
                      bgcolor: inputBgcolor,
                    }}
                  />
                  {formik.errors.recipient_email && formik.touched.recipient_email ? (
                    <p style={{ color: "red" }}>{formik.errors.recipient_email}</p>
                  ) : null}
                </Box>
              )}
              {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Mobile Number
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_contact"
                    value={formik.values.recipient_contact}
                    id="recipient_contact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="phone"
                    variant="filled"
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    autoComplete="false"
                    color="primary"
                    placeholder="Enter recipients Mobile no."
                    sx={{
                      width: "100%",
                      border: "none",
                      bgcolor: inputBgcolor,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      pt: "10px",
                      style: { backgroundColor: "#F6F6F6" },
                      startAdornment: (
                        <Select
                          disableUnderline
                          SelectDisplayProps={{
                            style: {
                              padding: 0,
                              backgroundColor: "#F6F6F6",
                              color: "#A8A8A8",
                            },
                          }}
                          sx={{
                            height: "30px",
                            width: "90px",
                            backgroundColor: "#F6F6F6",
                            borderBottom: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              bgcolor: "rgba(0, 0, 0, 0.08)",
                            },
                            "&.MuiInputBase-root": {
                              backgroundColor: "transparent",
                            },
                          }}
                          MenuProps={{ disableScrollLock: true }}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          // defaultValue="select"
                          value={countryPhoneCode}
                          onChange={handleSelectChange}
                          label="Country"
                          inputProps={{
                            style: {
                              backgroundColor: "#F6F6F6",
                              border: "none",
                            },
                          }}>
                          {/* {country_names &&editAddressId&&
                  country_names.map((item, index) => (
                    <MenuItem value={formik.values.recipient_country}>{formik.values.recipient_country}</MenuItem>
                  ))} */}
                          <MenuItem value={countryPhoneCode}>+{countryPhoneCode}</MenuItem>

                          {country_names &&
                            country_names.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                +{item.phone_code}
                              </MenuItem>
                            ))}
                        </Select>
                      ),
                    }}
                  />
                  {formik.errors.recipient_contact && formik.touched.recipient_contact ? (
                    <p style={{ color: "red" }}>{formik.errors.recipient_contact}</p>
                  ) : null}
                </Box>
              )}
            </Box>
            {!egift && (
              <>
                <Box sx={Style.rowBoxStyle}>
                  {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                  {!loading && (
                    <Box sx={Style.inputStyle}>
                      <FormLabel sx={Style.label}>
                        Delivery address line 1<span style={Style.star}>*</span>
                      </FormLabel>

                      <TextField
                        name="recipient_address1"
                        value={formik.values.recipient_address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        variant="filled"
                        InputProps={{ disableUnderline: true, pt: "10px" }}
                        inputProps={{
                          style: {
                            paddingTop: "16px",
                            paddingBottom: "15px",
                          },
                        }}
                        color="primary"
                        placeholder="Enter delivery address"
                        sx={{
                          width: "100%",
                          border: "none",
                          bgcolor: inputBgcolor,
                        }}
                        autoComplete="false"
                      />
                      {formik.errors.recipient_address1 && formik.touched.recipient_address1 ? (
                        <p style={{ color: "red" }}>{formik.errors.recipient_address1}</p>
                      ) : null}
                    </Box>
                  )}

                  {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                  {!loading && (
                    <Box sx={Style.inputStyle}>
                      <FormLabel sx={Style.label}>Delivery address line 2</FormLabel>

                      <TextField
                        name="recipient_address2"
                        value={formik.values.recipient_address2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        variant="filled"
                        InputProps={{ disableUnderline: true, pt: "10px" }}
                        inputProps={{
                          style: {
                            paddingTop: "16px",
                            paddingBottom: "15px",
                          },
                        }}
                        color="primary"
                        placeholder="Enter delivery address"
                        sx={{
                          width: "100%",
                          border: "none",
                          bgcolor: inputBgcolor,
                        }}
                        autoComplete="false"
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={Style.rowBoxStyle}>
                  {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                  {!loading && (
                    <Box sx={Style.inputStyle}>
                      <FormLabel sx={Style.label}>
                        City
                        <span style={Style.star}>*</span>
                      </FormLabel>

                      <TextField
                        name="recipient_city"
                        value={formik.values.recipient_city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        variant="filled"
                        InputProps={{ disableUnderline: true, pt: "10px" }}
                        inputProps={{
                          style: {
                            paddingTop: "16px",
                            paddingBottom: "15px",
                          },
                        }}
                        color="primary"
                        placeholder="Enter city"
                        sx={{
                          width: "100%",
                          border: "none",
                          bgcolor: inputBgcolor,
                        }}
                        autoComplete="false"
                      />
                      {formik.errors.recipient_city && formik.touched.recipient_city ? (
                        <p style={{ color: "red" }}>{formik.errors.recipient_city}</p>
                      ) : null}
                    </Box>
                  )}

                  {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                  {!loading && (
                    <Box sx={Style.inputStyle}>
                      <InputLabel
                        // helperText={errors?.recipient_country?.message}
                        // errors={!!errors.recipient_country}
                        id="demo-simple-select-standard-label"
                        placeholder="Select country"
                        sx={{
                          fontWeight: 400,
                          fontSize: "24px",
                          // lineHeight: "24px",
                          color: "#333333",
                        }}>
                        Country
                        <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        sx={{
                          width: "100%",
                          height: "53px",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                            bgcolor: "rgba(0, 0, 0, 0.06)",
                          },
                        }}
                        MenuProps={{ disableScrollLock: true }}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        // defaultValue="select"
                        value={country}
                        onChange={handleChange}
                        label="Country">
                        {/* {country_names &&editAddressId&&
                        country_names.map((item, index) => (
                          <MenuItem value={formik.values.recipient_country}>{formik.values.recipient_country}</MenuItem>
                        ))} */}
                        <MenuItem value={country}>{country}</MenuItem>

                        {country_names &&
                          country_names.map((item, index) => (
                            <MenuItem key={index} value={item.nicename}>
                              {item.nicename}
                            </MenuItem>
                          ))}
                      </Select>
                      {formik.errors.recipient_country && formik.touched.recipient_country ? (
                        <p style={{ color: "red" }}>{formik.errors.recipient_country}</p>
                      ) : null}
                      {/* <FormHelperText> {tst} </FormHelperText> */}
                    </Box>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                  {!loading && (
                    <Box sx={Style.inputStyle}>
                      <FormLabel sx={Style.label}>
                        Postcode
                        <span style={Style.star}>*</span>
                      </FormLabel>

                      <TextField
                        name="recipient_postcode"
                        value={formik.values.recipient_postcode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        variant="filled"
                        InputProps={{ disableUnderline: true, pt: "10px" }}
                        inputProps={{
                          style: {
                            paddingTop: "16px",
                            paddingBottom: "15px",
                          },
                        }}
                        color="primary"
                        placeholder="Enter postcode"
                        sx={{
                          width: "100%",
                          border: "none",
                          bgcolor: inputBgcolor,
                        }}
                        autoComplete="false"
                      />
                      {formik.errors.recipient_postcode && formik.touched.recipient_postcode ? (
                        <p style={{ color: "red" }}>{formik.errors.recipient_postcode}</p>
                      ) : null}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box>
            {egift && (
              <Button
                variant="outlined"
                onClick={() => onSubmit(formik.values)}
                sx={{
                  mt: 2,
                  mb: 2,
                  pb: "8px",
                  pr: "25px",
                  pl: "25px",
                  "&.MuiButtonBase-root:hover": {
                    border: "1px solid #3D2E57",
                  },
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "19px",
                  borderRadius: "15px",
                  color: "#3D2E57",
                  border: "1px solid #3D2E57",
                  textTransform: "none",
                }}>
                {buttonText}
                <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
              </Button>
            )}
            {!egift && (
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  mt: 1,
                  mb: 2,
                  pb: "8px",
                  pr: "25px",
                  pl: "25px",
                  "&.MuiButtonBase-root:hover": {
                    border: "1px solid #3D2E57",
                  },
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "19px",
                  borderRadius: "15px",
                  color: "#3D2E57",
                  border: "1px solid #3D2E57",
                  textTransform: "none",
                }}>
                {buttonText}
                <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
              </Button>
            )}
          </Box>
        </form>
        <Toastify />
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    check_recipent: (item) => dispatch(check_recipent(item)),
    country_dropdown_data: () => dispatch(country_dropdown_data()),
    SaveAddressList: (data) => dispatch(SaveAddressList(data)),
    getUserAddress: (api_data) => dispatch(getUserAddress(api_data)),
  };
}
export default connect(null, mapDispatchToProps)(Recipients);
const Style = {
  typographyStyle: {
    fontSize: { xs: "24px", md: "35px" },
    fontWeight: { xs: "400", md: "700" },
    lineHeight: { xs: "29px", md: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
    pb: 3,
  },
  rowBoxStyle: {
    width: { xl: "100%", lg: "100%", sm: "100%", xs: "100%" },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  inputStyle: {
    width: {
      xs: "100%",
      sm: "100%",
      md: "45%",
      lg: "45%",
      xl: "45%",
    },
    mb: 2,
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    // lineHeight: "24px",
    color: "#333333",
  },
  star: {
    color: "red",
  },
};
