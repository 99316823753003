import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import Toastify from "../SnackBar/Toastify";
const EmailVerify = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "#F4F3EF",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        pt:{xl:18,md:18,xs:12}
      }}
    >
      <Box
        sx={{
          maxWidth: "1360px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#3DD5B7",
              marginTop: 5,
              height: {
                xl: "165px",
                lg: "145px",
                md: "125px",
                sm: "105px",
                xs: "85px",
              },
              width: {
                xl: "165px",
                lg: "145px",
                md: "125px",
                sm: "105px",
                xs: "85px",
              },
            }}
          >
            <DraftsOutlinedIcon
              sx={{
                width: {
                  xl: "80px",
                  lg: "70px",
                  md: "60px",
                  sm: "50px",
                  xs: "40px",
                },
                height: "80px",
              }}
            />
          </Avatar>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Toastify/>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: {
                xl: "30px",
                lg: "26px",
                md: "22px",
                sm: "18px",
                xs: "14px",
              },
              lineHeight: "31px",
              textAlign: "center",
              marginTop: 3,
            }}
          >
            Check your mail
          </Typography>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: {
                xl: "20px",
                lg: "19px",
                md: "18px",
                sm: "17px",
                xs: "16px",
              },
              lineHeight: "18px",
              textAlign: "center",
              marginTop: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            We have sent an email to your registered email.Please check and
            verify
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
           <Button
                  sx={{
                    width: {
                      xs: "90%",
                      sm: "90%",
                      md: "90%",
                      lg: "30%",
                      xl: "30%",
                    },
                    color: "#3D2E57",
                    border:"2px solid #3D2E57",
                    p: 1,
                    borderRadius:'15px',
                    fontSize:'24px',
                    textTransform:'none'
                  }}
                  variant="outlined"
                  type="submit"
                >
                  Register
                </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button
            className="btn"
            sx={{
              width: "100%",
              color: "#4F4F4F",
              fontSize: {
                xl: "20px",
                lg: "18px",
                md: "16px",
                sm: "14px",
                xs: "12px",
              },
            }}
            onClick={() => navigate("/phoneverify")}
          >
            Skip, I will confirm later
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerify;
