

// Staging Url
export const API_URL = "https://gopher.orientaloutsourcing.in/public/api";
// export const API_URL = "https://orientaloutsourcing.in/gopher-dev/public/api";


// Live URL
// export const API_URL = "http://18.142.23.88:8050"

