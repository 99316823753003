/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { FooterPages, FooterLastPages } from "../../config/mockData";
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookF } from '@fortawesome/fontawesome-free-solid'
import { Link, Navigate, useNavigate } from "react-router-dom";
// import { useTheme } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";
export default function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ width: "100%", bgcolor: "#F4F3EF", mt: "auto" }}>
      <AppBar
        component={"footer"}
        sx={{
          bgcolor: "#F4F3EF",
          maxWidth: {
            xs: "600px",
            md: "1100px",
            xl: "1450px",
            lg: "1430px",
            sm: "850px",
          },

          // width: { xs: "95%", sm: "95%", md: "90%", lg: "90%", xl: "90%" },
          mr: "auto",
          ml: "auto",
          mt: "auto",
          boxShadow: "none",
          pt: 1,
          pb: 2,
          // height: { xs: 465, sm: 465, md: 400, lg: 340, xl: 340 },
        }}
        position="static"
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            pt: 1,
            pb: 1,
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Box sx={style.logoImage}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
            >
              <Box sx={style.logoImg}>
                <img
                  alt="logo"
                  className="logoSize"
                  style={style.image}
                  src={require("../../assets/Gopher-Logo-Retina.png")}
                />
                <img
                  alt="logo"
                  className="logoSize2"
                  style={style.image}
                  src={require("../../assets/Gopher-Logo-Logo.png")}
                />
              </Box>
            </Link>
          </Box>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            pt: 2,
            pb: 3,
            justifyContent: "space-around",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              // pr: "100px",
              // pl: "100px",
              maxWidth: "75%",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            {FooterPages.map((item, index) => (
              <Link
                key={index}
                style={{
                  fontFamily: "Effra",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "40px",

                  textTransform: "none",
                  textAlign: "center",
                  color: "#3D2E57",
                  fontSize: "20px",
                  textDecoration: "none",
                }}
                to={item.route}
              >
                {item.val}
              </Link>
            ))}

            {isMobile && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("google.com/abc")}
                >
                  <InstagramIcon
                    sx={{
                      fontSize: 35,
                      color: "#3D2E57",
                      backgroundColor: "#F4F3EF",
                    }}
                  />
                  <Typography style={style.typographyStyle}>
                    Instagram
                  </Typography>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick="facebook.com/amitabhbachchan/"
                >
                  <FacebookOutlinedIcon
                    sx={{ fontSize: 35, color: "#3D2E57" }}
                  />
                  <Typography style={style.typographyStyle}>
                    facebook
                  </Typography>
                </Link>
              </Box>
            )}
            {!isMobile && (
              <>
                <a
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  target="_blank"
                  href="https://www.instagram.com/accounts/login/?next=%2Famitabhbachchan%2F&source=omni_redirect"
                >
                  <InstagramIcon
                    sx={{
                      fontSize: 35,
                      color: "#3D2E57",
                      backgroundColor: "#F4F3EF",
                    }}
                  />
                  <Typography style={style.typographyStyle}>
                    Instagram
                  </Typography>
                </a>
                {/* </Link> */}
                <a
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  target="_blank"
                  href="https://www.facebook.com/amitabhbachchan/"
                >
                  <FacebookOutlinedIcon
                    sx={{ fontSize: 35, color: "#3D2E57" }}
                  />
                  <Typography style={style.typographyStyle}>
                    facebook
                  </Typography>
                </a>
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            flex: 0.5,
            justifyContent: {
              xs: "space-evenly",
              sm: " space-around",
              md: "center",
              lg: "center",
              xl: "center",
            },
          }}
        >
          {!isMobile &&
            FooterLastPages.map((item, index) => (
              <Link style={style.textStyle1} key={index} to={item.route}>
                {item.val}
              </Link>
            ))}
          {isMobile &&
            FooterLastPages.map((item, index) => (
              <Link style={style.textStyle} key={index} to={item.route}>
                {item.val}
              </Link>
            ))}
        </Box>
      </AppBar>
    </Box>
  );
}

const style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#3D2E57",
    marginLeft: 10,
  },
  textStyle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    textDecoration: "none",
    color: "#AAAAAA",
    textTransform: "none",
  },
  textStyle1: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    marginRight: "17px",
    marginLeft: "17px",
    textDecoration: "none",
    color: "#AAAAAA",
    textTransform: "none",
  },
  logoImage: {
    width: { xs: "55%", sm: "37%", md: "37%", lg: "27%", xl: "27%" },
    pt: "70px",
    pb: "70px",
  },
  logoImg: {
    // maxHeight: "100px",
    maxWidth: "383px",
  },
  image: {
    height: "100%",
    width: "100%",
  },
};
