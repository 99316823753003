import {Api} from '../../config/request'


export const requestCategoryList = async (data) => {
  return Api.getListRequest('/products/categories/categoryList', data);
};
export const requestGiftCardAmount = async (data) => {
  return Api.getListRequest('/gift/cards/list', data);
};
export const requestCarddetail = async (data) => {
  return Api.getListRequest('/products/greetingCards/cardDetails', data);
};
export const requestCardperList = async (data) => {
  return Api.getListRequest('/products/greetingCards/greetingCardsList', data);
};


const Gift_CardAPi = {
    requestCategoryList,
    requestGiftCardAmount,
    requestCarddetail,
    requestCardperList,
};
export default Gift_CardAPi;
