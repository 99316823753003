import {Api} from '../../config/request'

export const requestuserdata = async (data) => {
  return Api.getListRequest('/user/profile', data);
};
export const requestOrderdetail = async (data) => {
  return Api.getListRequest("/user/order/orderDetails", data);
};
export const requestItemdetail = async (data) => {
  return Api.getListRequest("/user/order/orderItemDetails", data);
};
export const requestupdateuser = async (data) => {
  return Api.getListRequest('/user/updateProfile', data);
};
export const requestReminders = async (data) => {
  return Api.getListRequest('/user/reminders', data);
};
export const requestupdateReminders = async (data) => {
  return Api.getListRequest('/save/user/reminders', data);
};
export const requestremoveReminder = async (data) => {
  return Api.getListRequest('/remove/user/reminders', data);
};
export const requestgetOrders = async (data) => {
  return Api.getListRequest('/user/order/myorders', data);
};
const ProfileApi = {
  requestuserdata,
  requestupdateuser,
  requestReminders,
  requestupdateReminders,
  requestremoveReminder,
  requestOrderdetail,
  requestItemdetail,
  requestgetOrders,
};
export default ProfileApi;
