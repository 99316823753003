import * as React from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputField from "../Input/InputField";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { storage } from "../../../config/storage";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { country_dropdown_data } from "../../../redux/action";

const GuestUserModal = ({
  onCloseModal,
  openModal,
  onClickModal2,
  onClickModal1,
  width,
  height,
  net_amount,
  getGuestRegister,
  country_dropdown_data,
}) => {
  const [loader, setloader] = React.useState(false);
  const navigate = useNavigate();
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("61");
  const [countryNumberRange, setCountryNumberRange] = React.useState({
    max: 9,
    min: 9,
    id: "",
  });

  const newState = useSelector((state) => state?.User?.country_data);
  const country_names = newState?.country_list;

  const schema = yup.object().shape({
    fullname: yup.string().required("Please enter your fullname"),
    email: yup.string().required("Please enter your email").email("Please enter valid email"),
    phone: yup
      .string()
      .required("Please enter your phone number")
      .min(countryNumberRange.min, `Enter minimum ${countryNumberRange.min} numbers `)
      .max(countryNumberRange.max, `Enter maximum ${countryNumberRange.max} numbers`),
  });
  const defaultValues = {
    fullname: "",
    email: "",
    phone: "",
  };
  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    height: height,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  const onSubmit = async (model) => {
    setloader(true);
    let item = model;
    // Object.assign(item, id);
    getGuestRegister(item).then((res) => {
      setloader(false);
      if (res.data.status) {
        toast.success(res?.data?.message);
        // storage.set.authToken(res?.data?.token);
        // storage.set.carts_id(res?.data?.carts_id);

        setTimeout(() => {
          navigate("/mycart/payment", { state: net_amount });
        }, 1000);
      } else {
        res?.data?.errors?.map((item) => {
          return toast.error(item);
        });
      }
    });
  };
  React.useEffect(() => {
    country_dropdown_data();
  }, []);

  const onChangeSelect = (e) => {
    setCountryPhoneCode(e.target.value.phone_code);
    setCountryNumberRange({
      max: e.target.value.sizeof_nn.max,
      min: e.target.value.sizeof_nn.min,
      id: e.target.value.country_id,
    });
  };
  return (
    <div>
      {/* <Button onClick={onClickModal}>Open modal</Button> */}
      <Modal open={openModal} onClose={onCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            name="LoginForm"
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                fontSize: { xs: "14px", sm: "14px", md: "20px", lg: "24px" },
                lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
                fontWeight: "600",
                textAlign: "center",
                color: "#3D2E57",
                mt: 5,
                mb: 2,
              }}>
              Please register as guest user if you want to place an order without login.
            </Typography>
            <InputField
              control={control}
              helperText={errors?.fullname?.message}
              errors={!!errors.fullname}
              type={"text"}
              placeholder={"Enter full name  here"}
              formlabel="Full name"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="fullname"
              required={"*"}
            />
            <Box sx={{ height: { xs: "25px", md: "20px" } }} />

            <InputField
              control={control}
              helperText={errors?.email?.message}
              errors={!!errors.email}
              type={"text"}
              placeholder={"Enter email here"}
              formlabel="Email"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="email"
              required={"*"}
            />

            <Box sx={{ height: { xs: "25px", md: "20px" } }} />

            <InputField
              control={control}
              helperText={errors?.phone?.message}
              errors={!!errors.phone}
              variant="filled"
              placeholder={"Enter phone number"}
              formlabel="Phone"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="phone"
              type={"number"}
              required={"*"}
              phoneCode={true}
              countryPhoneCode={countryPhoneCode}
              onChangeSelect={onChangeSelect}
              country_names={country_names}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}></Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Button
                disableRipple
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "60%",
                    lg: "40%",
                    xl: "40%",
                  },
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                    border: "1.5px solid #3D2E57",
                  },
                  bgcolor: "#FFFFFF",
                  color: "#3D2E57",
                  border: "1.5px solid #3D2E57",
                  // p: 1,
                  mb: 2,
                  mt: 2,
                  borderRadius: "15px",
                  fontSize: { xs: "20px", md: "22px", lg: "24px" },
                  fontWeight: 500,
                  textTransform: "none",
                }}
                variant="outlined"
                type="submit">
                Proceed
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "center" },
              }}>
              <Link
                // to="/register"
                onClick={onClickModal1}
                className="fontlink2"
                style={{
                  fontWeight: 400,
                  color: "#FF8D2A",
                  textDecoration: "none",
                  marginBottom: "10px",
                }}>
                Login with password
              </Link>
              <Button
                disableRipple
                onClick={onClickModal2}
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                  },
                  textTransform: "none",
                  fontWeight: 400,
                  color: "#3D2E57",
                  textDecoration: "none",
                  maxHeight: "37px",
                  mb: "10px",
                  fontSize: "20px",
                  pt: 0,
                  pb: 1,
                }}
                variant="text">
                Login with OTP
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    country_dropdown_data: () => dispatch(country_dropdown_data()),
  };
}

export default connect(null, mapDispatchToProps)(GuestUserModal);
