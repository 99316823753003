import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { storage } from "../../config/storage";
import Image1 from "../../assets/Group 586.png";
import Image2 from "../../assets/Group 737.png";
import Box from "@mui/material/Box";
import { AccountData } from "../../config/mockData";

import { CardMedia, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MYACCOUNT, MYPROFILE, REGISTER } from "../routes/ConstURL";

const BoxContainer3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const Active = (item) => {
    AccountData.map((element) => {
      if (element.val === item) {
        element.active = true;
        element.color = "#FF8D2A";
        element.bgcolor = "#3D3264";
      } else {
        element.active = false;
        element.color = "#3D2E57";
        element.bgcolor = "#3D2E57";
      }
    });
    navigate(MYACCOUNT);
  };

  return (
    <Box sx={style.mainBox}>
      <Box sx={style.wrapBox}>
        <Box sx={style.cardBox}>
          {!isMobile && (
            <CardMedia sx={style.mediaBox} component="div" height="100%" width="100%" image={Image1} alt="green iguana">
              <Box sx={style.dataBox}>
                <Box>
                  <Typography sx={style.text}>
                    Register those important dates so that you never forget that birthday or anniversary again.
                  </Typography>
                </Box>

                <Link
                  to={storage?.fetch?.authToken()?.length == null ? REGISTER : MYPROFILE}
                  style={{
                    color: "#553B81",
                    fontWeight: 800,
                    backgroundColor: "#FFFFFF",
                    fontSize: "16px",
                    // fontSize:{xs:'10px',sm:'16px'},
                    textDecoration: "none",
                    padding: "14px 37px 14px 37px",
                    borderRadius: "10px",
                    marginTop: "50px",
                    width: { xs: "100%", sm: "70%" },
                  }}>
                  REGISTER NOW
                </Link>
              </Box>
            </CardMedia>
          )}
          {isMobile && (
            <CardMedia
              sx={style.mediaBox}
              component="div"
              // height="100%"
              width="100%"
              image={Image2}
              alt="green iguana">
              <Box sx={style.dataBox}>
                <Box>
                  <Typography sx={style.text}>
                    Register those important dates so that you never forget that birthday or anniversary again.
                  </Typography>
                </Box>

                <Link
                  variant="contained"
                  to={storage?.fetch?.authToken()?.length == null ? REGISTER : MYPROFILE}
                  // default_bgcolor={"#FFFFFF"}
                  // onclick_bgcolor={"#FFFFFF"}
                  // item={"Register Now"}
                  style={{
                    color: "#553B81",
                    fontWeight: 800,
                    backgroundColor: "#FFFFFF",
                    fontSize: "16px",
                    // fontSize:{xs:'10px',sm:'16px'},
                    textDecoration: "none",
                    padding: "14px 37px 14px 37px",
                    borderRadius: "10px",
                    marginTop: "30px",
                    width: { xs: "100%", sm: "70%" },
                  }}>
                  REGISTER NOW
                </Link>
              </Box>
            </CardMedia>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default BoxContainer3;
const style = {
  mainBox: { display: "flex", width: "100%", justifyContent: "center" },
  wrapBox: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1360px",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    width: "100%",
    overflowX: "hidden",
    pt: 3,
    pb: 3,
  },
  cardBox: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    borderRadius: 3,
  },
  mediaBox: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundPosition: "unset",
  },
  dataBox: {
    maxWidth: { xs: "95%", sm: "65%" },
    minHeight: "auto",
    pt: { xs: "80px", sm: "60px", md: "40px", xl: "100px", lg: "100px" },
    pb: { xs: "80px", sm: "60px", md: "40px", xl: "100px", lg: "100px" },
    alignItems: "center",
    display: "flex",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    color: "white",
    fontSize: { xs: 30, sm: 30, md: 30, xl: 42, lg: 42 },
    fontWeight: "700",
    letterSpacing: { xs: 0, sm: "-0.015em" },
    lineHeight: "50px",
    textAlign: "center",
  },
};
