import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Typography, Table, TableRow, TableCell, TableHead, TableBody, Skeleton } from "@mui/material";
import { getItemDetail } from "../../redux/action";
import LoaderComponent from "../Loader/LoaderComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import { UserContext } from "../home/main";
import { storage } from "../../config/storage";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import { LOGIN, MYORDER } from "../routes/ConstURL";

const ItemDetail = ({ getItemDetail }) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [newState, setnewState] = useState({
    item_total_price: "",
    item_qty: "",
    item_title: "",
    img: "",
    item_type_formatted: "",
    file_type: 1,
    status: "",
    greet_msg: "",
    recipient_name: "",
    recipient_dob: "",
    recipient_email: "",
    recipient_contact: "",
    recipient_address1: "",
    recipient_address2: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  let id = location?.state?.id;
  if (id) {
    storage.set.order_item_id(id);
  }
  let id_storage = storage.fetch.order_item_id();
  if (id_storage) {
    id = id_storage;
  } else {
    setTimeout(() => {
      if (storage.fetch.authToken()) {
        navigate(MYORDER);
      } else {
        navigate(LOGIN);
      }
    }, 3000);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = { order_item_id: id };
    if (!id_storage) {
      toast.error("please select a order first");
    }
    getItemDetail(data).then((res) => {
      setLoading(false);
      if (res) {
        setnewState(res.data.data.order_item_details);
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        pb: 5,
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
      }}>
      <LoaderComponent open={loading} />
      <Toastify />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          borderRadius: 4,
          justifyContent: "center",
        }}>
        <Typography sx={Style.typographyStyle}>Order item detail</Typography>

        <Box sx={{ overflow: "auto", ml: 3, mr: 3, mt: -3 }}>
          <Table sx={{ minWidth: { xs: "775px", md: "100%" } }}>
            <TableHead>
              <TableRow>
                <TableCell style={Style.tableCell}>Item</TableCell>
                <TableCell style={Style.tableCell}> </TableCell>
                <TableCell style={Style.tableCell}>Price</TableCell>
                <TableCell style={Style.tableCell}>QTY</TableCell>
                <TableCell style={Style.tableCell}>Type</TableCell>
                <TableCell style={Style.tableCell}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {loading && <Skeleton variant="rectangular" sx={{ height: { xs: 100, md: 65 } }} width={60} />}
                  {!loading && (
                    <Box
                      sx={{
                        height: { xs: 100, md: 65 },
                        width: 60,
                        border: "1px solid rgba(61, 46, 87, 0.4)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                      }}>
                      {(newState?.file_type === FILE_TYPE.IMAGE || newState?.file_type === FILE_TYPE.GIF) && (
                        <img alt="logo1" style={{ height: 50, width: 40 }} src={newState?.img} />
                      )}
                      {newState?.file_type === FILE_TYPE.VIDEO && (
                        <video width="60" height="65" controls>
                          <source src={newState?.img} />
                        </video>
                      )}
                    </Box>
                  )}
                </TableCell>
                <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading &&""}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && newState?.item_total_price}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && (newState?.item_qty === "" ? "__" : newState?.item_qty)}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && newState?.item_type_formatted}
                </TableCell>
                <TableCell
                  style={Style.tableCell1}
                  sx={{
                    color: newState?.status.toLowerCase() === "completed" ? "#219653" : "#FF8D2A",
                  }}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && newState?.status}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        {loading && <Skeleton style={Style.typographyStyle2} variant="rectangular" animation="wave" />}
        {!loading && newState?.greet_msg && (
          <>
            <Typography sx={Style.typographyStyle}>Message</Typography>
            <Typography style={Style.typographyStyle2}>{newState?.greet_msg}</Typography>
          </>
        )}

        <Typography sx={Style.typographyStyle}>Recipent Details</Typography>

        {loading && (
          <>
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} animation="wave" />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} animation="wave" />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3, mb: 3 }} animation={false} />
          </>
        )}
        {!loading && (
          <Box
            sx={{
              mb: 5,
              ml: 5,
              mr: 5,
              display: "flex",
            }}>
            <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
              {newState?.recipient_name && <Typography style={Style.typographyStyle1}> Name: </Typography>}
              {newState?.recipient_dob && <Typography style={Style.typographyStyle1}>Date of birth:</Typography>}
              {newState?.recipient_email && <Typography style={Style.typographyStyle1}> Email: </Typography>}
              {newState?.recipient_contact && <Typography style={Style.typographyStyle1}>Contact:</Typography>}
              {newState?.recipient_address1 && <Typography style={Style.typographyStyle1}>Address:</Typography>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mr: { sm: 3, md: 5 } }}>
              {newState?.recipient_name && <Typography style={Style.typographyStyle1}>{newState?.recipient_name}</Typography>}
              {newState?.recipient_dob && <Typography style={Style.typographyStyle1}>{newState?.recipient_dob}</Typography>}
              {newState?.recipient_email && <Typography style={Style.typographyStyle1}>{newState?.recipient_email}</Typography>}
              {newState?.recipient_contact && <Typography style={Style.typographyStyle1}>{newState?.recipient_contact}</Typography>}
              {newState?.recipient_address1 && <Typography style={Style.typographyStyle1}>{newState?.recipient_address1}</Typography>}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getItemDetail: (data) => dispatch(getItemDetail(data)),
  };
}
export default connect(null, mapDispatchToProps)(ItemDetail);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#FFE7CA",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    m: 3,
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    // color: "#000000"
  },
  typographyStyle2: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginLeft: "24px",
    marginRight: "24px",
    padding: "13px",
    minHeight: "70px",
    backgroundColor: "#F6F6F6",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
  },
  linkButtonBox: {
    pt: "5px",
    pb: "5px",
    border: "1px solid #3D2E57",
    borderRadius: "15px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    textAlign: "center",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    paddingLeft: "15px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    width: "100%",
    height: "100%",
  },
};
