/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "./apiUrl";
import { storage } from "./storage";
export const getListRequest = async (url, data) => {
  let guest_api = { api_key: storage.fetch.guest_user_api_key() };
  if (storage.fetch.guest_user_api_key()) {
    let data1 = {};
    if (!data) {
      data = data1;
      Object.assign(data1, guest_api);
    } else {
      Object.assign(data, guest_api);
    }
    try {
      const res = await axios({
        url: API_URL + url,
        method: "POST",
        data,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: storage.fetch.authToken(),
        },
      });

      if (res.data.code == 401) {
        toast.error("Token is expired. Please login again");
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/login";
        }, 2000);
      } else {
        return res ? res : res.data;
      }
    } catch (err) {
      // toast.error("some error occured");
    }
  } else {
    try {
      const res = await axios({
        url: API_URL + "/get/key",
        method: "POST",
        data,
      });
      if (res.data.status) {
        storage.set.guest_user_api_key(res.data.data.api_key);
        let data1 = {};
        if (!data) {
          data = data1;
          Object.assign(data1, guest_api);
        } else {
          Object.assign(data, guest_api);
        }

        Object.assign(data, guest_api);
        try {
          const res = await axios({
            url: API_URL + url,
            method: "POST",
            data,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: storage.fetch.authToken(),
            },
          });

          if (res.data.code == 401) {
            toast.error("Token is expired. Please login again");
            setTimeout(() => {
              localStorage.clear();

              window.location.href = "/login";
            }, 2000);
          } else {
            return res ? res : res.data;
          }
        } catch (err) {
          // toast.error("some error occured");
        }
      }
    } catch (err) {}
  }
};
export const PostRequest = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      data,
      // body:JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return res ? res : res.data;
  } catch (err) {}
};

export const getPhoneNumber = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: storage.fetch.authToken(),
        Accept: "application/json",
      },
    });
    return res ? res : res.data;
  } catch (err) {}
};
export const getOtpVerified = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      data,
      // body:JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: storage.fetch.authToken(),
        Accept: "application/json",
      },
    });
    return res ? res : res.data;
  } catch (err) {}
};
export const AuthPostrequest = async (url, data) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      data,
      // body:JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: storage.fetch.authToken(),
        Accept: "application/json",
      },
    });
    return res ? res : res.data;
  } catch (err) {}
};
export const videoPostrequest = async (url, data) => {
  let guest_api = { api_key: storage.fetch.guest_user_api_key() };
  if (storage.fetch.guest_user_api_key()) {
    let data1 = {};
    if (!data) {
      data = data1;
      Object.assign(data1, guest_api);
    } else {
      Object.assign(data, guest_api);
    }
    try {
      const res = await axios({
        url: API_URL + url,
        method: "POST",
        data,
        headers: {
          "Content-type": "multipart/form-data",
          Accept: "application/json",
          // responseType: "blob",
          Authorization: storage.fetch.authToken(),
        },
      });

      if (res.data.code == 401) {
        toast.error("Token is expired. Please login again");
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/login";
        }, 2000);
      } else {
        return res ? res : res.data;
      }
    } catch (err) {
      // toast.error("some error occured");
    }
  } else {
    try {
      const res = await axios({
        url: API_URL + "/get/key",
        method: "POST",
        data,
      });
      if (res.data.status) {
        storage.set.guest_user_api_key(res.data.data.api_key);
        let data1 = {};
        if (!data) {
          data = data1;
          Object.assign(data1, guest_api);
        } else {
          // Object.assign(data, guest_api);
        }

        // Object.assign(data, guest_api);
        try {
          const res = await axios({
            url: API_URL + url,
            method: "POST",
            data,
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: storage.fetch.authToken(),
            },
          });

          if (res.data.code == 401) {
            toast.error("Token is expired. Please login again");
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/login";
            }, 2000);
          } else {
            return res ? res : res.data;
          }
        } catch (err) {
          // toast.error("some error occured");
        }
      }
    } catch (err) {}
  }
};
export const Api = {
  getListRequest,
  PostRequest,
  getPhoneNumber,
  getOtpVerified,
  AuthPostrequest,
  videoPostrequest,
};
