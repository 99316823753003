import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { placeholder } from "glamor";
import React from "react";
import { Link,useNavigate } from "react-router-dom";

const LinkButton = ({ ROUTE_URL, button_link_name,pr,pl,padding, bgcolor, textColor }) => {
  const navigate = useNavigate()
  return (
    <>
    <Link
      style={{
        textDecoration: "none",
        backgroundColor: bgcolor,
        color: textColor,
        padding:padding,
        paddingLeft:pl,
        paddingRight:pr,
        borderRadius:6,
        marginRight:'2px'
      }}
      to={ROUTE_URL}
      onClick={ROUTE_URL===""?()=>{
        <Dialog
          open={true}
          sx={{ ml: 8, mt: 5 }}
        >
          <DialogContent
            sx={{
              width: 500,
            }}
          >
            <DialogContentText
              sx={{
                fontSize: "24px",
              }}
            >
             This gift card functionality will be available soon 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{navigate(-1)}} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      }:""}
    >
      {button_link_name}
    </Link>
    </>
  );
};

export default LinkButton;
