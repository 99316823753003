import * as React from "react";

import Box from "@mui/material/Box";

import { CardContent, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const BoxContainer = () => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Box sx={style.mainBox}>
        <Box sx={style.card}>
          <Box sx={style.textBox}>
            <Typography variant="h2" gutterBottom sx={style.text1} component="div">
              EASY AS 1-2-3-4!
            </Typography>
          </Box>

          <Box sx={style.itemBox}>
            <Box sx={style.dividerBox}>
              {cardData.map((item, index) => (
                <Box sx={style.mapBox} key={index}>
                  <Box sx={style.image}>
                    <CardMedia component="img" height="100%" width="100%" image={item.img} alt="green iguana" />
                  </Box>
                  <CardContent
                    sx={{
                      height: 60,
                      padding: "0px",
                      maxWidth: "200px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}>
                    <Link className="EasyASlinks" style={style.button}>
                      {item.text}
                    </Link>
                  </CardContent>
                </Box>
              ))}
            </Box>
            <Box sx={style.dividerBox}>
              {cardData1.map((item, index) => (
                <Box sx={style.mapBox} key={index}>
                  <Box sx={style.image}>
                    <CardMedia component="img" height="100%" width="100%" image={item.img} alt="green iguana" />
                  </Box>
                  <CardContent
                    sx={{
                      height: 60,
                      padding: "0px",
                      maxWidth: "200px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}>
                    <Link className="EasyASlinks" style={style.button}>
                      {item.text}
                    </Link>
                  </CardContent>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BoxContainer;

const style = {
  mainBox: {
    flexDirection: "row",
    display: "flex",
    // width:   { xs: "95%", sm: "95%", md: "90%", lg: "90%", xl: "90%" },
    // pt: '20px',
    justifyContent: "space-between",
    maxWidth: "1360px",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    width: "100%",
  },
  card: {
    width: "100%",
    height: { xs: 600, sm: 650, md: 450, lg: 491, xl: 491 },
    justifyContent: "center",
    bgcolor: "#FF8D2A",
    borderRadius: 3,
  },
  textBox: {
    height: { xs: "15%", sm: "15%", md: "20%", lg: "20%", xl: "20%" },
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // bgcolor:"red"
    // mt:'70px',
  },
  text: {
    // bgcolor:'red',
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "500",
    color: "white",
  },
  text1: {
    // bgcolor:'red',
    textAlign: "center",
    fontSize: { xs: "32px", sm: "35px", md: "40px", lg: "50px", xl: "50px" },
    lineHeight: "38px",
    letterSpacing: "-0.015em",
    textTransform: "uppercase",
    mt: "70px",
    fontWeight: "900",
    color: "white",
  },
  itemBox: {
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
    display: "flex",
    alignItems: "center",
    mt: "20px",
  },
  dividerBox: {
    display: "flex",
    flexDirection: "row",
    width: {
      xs: "100%",
      sm: "100%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
    justifyContent: "space-around",
    // alignItems:'center',
    mt: "20px",
    pr: 1,
  },
  image: {
    pt: 2,
    height: { xs: 100, sm: 140, md: 180, lg: 180, xl: 180 },
    // bgcolor: "skyblue",
    width: { xs: 100, sm: 140, md: 180, lg: 180, xl: 180 },
  },
  mapBox: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    textAlign: "center",
    textDecoration: "none",
    color: "white",
    width: "100%",
    marginTop: "30px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.08em",
    textTransform: "none",
    // bgcolor:'green'
  },
};

const cardData = [
  {
    img: require("../../assets/easy1.png"),
    text: "SELECT THE PERFECT CARD",
  },
  {
    img: require("../../assets/easy2.png"),
    text: "PERSONALISE IT WITH A MESSAGE",
  },
];

const cardData1 = [
  {
    img: require("../../assets/easy3.png"),
    text: "ADD A GIFT OR EGIFT CARD",
  },
  {
    img: require("../../assets/Frame.png"),
    text: "WE'LL POST OR SEND IT FOR YOU",
  },
];
