import HomePage from "../home/homePage";
import Error from "../greetingCards/Error404";
import Register from "../register/Register";
import Loginpage from "../Login/loginpage";
import EmailVerify from "../register/EmailVerify";
import PhoneVerify from "../register/PhoneVerify";
import OtpVerify from "../register/OtpVerify";
import Payment from "../Cart/Payment";
import ForgetPassword from "../Login/ForgetPassword";
import ResetPassword from "../Login/ResetPassword";
import EmailOtp from "../register/EmailOtp";
import FirstCheckout from "../Cart/FirstCheckout";
import VerifyEmailForPass from "../Login/VerifyEmailForPass";
import OpenRoutes from "./OpenRoutes";
import Recordvideo from "../e-giftcard/recordvideo";
import SelectAmount from "../e-giftcard/SelectAmount";
import SelectType from "../e-giftcard/SelectType";
import SelectGif from "../e-giftcard/SelectGif";
import GreetingCategories from "../greetingCards/GreetingCategories";
import GreetingCardAmount from "../greetingCards/GreetingCardAmount";
import GreetingCardListing from "../greetingCards/GreetingCardListing";
import GreetingCardDetail from "../greetingCards/GreetingCardDetail";
import GreetingCardPersonalise from "../greetingCards/GreetingCardPersonalise";
import GreetingCardAddresses from "../greetingCards/GreetingCardAddresses";
import GiftCardAmount from "../giftCards/GiftCardAmount";
import GiftAddCard from "../giftCards/GiftAddCard";
import GiftCategories from "../giftCards/GiftCategories";
import GiftCardListing from "../giftCards/GiftCardListing";
import GiftCardDetail from "../giftCards/GiftCardDetail";
import GiftCardPersonalise from "../giftCards/GiftCardPersonalise";
import EgiftRecipients from "../e-giftcard/EgiftRecipients";
import GreetingCardDelivery from "../greetingCards/GreetingCardDelivery";
import Confirmation from "../Cart/Confirmation";
import ProtectedRouts from "./ProtectedRoutes";
import MyAccount from "../Custom/CustomAccount/MainAccount";
import MyOrderDetail from "../MyAccount/MyOrderDetail";
import GreetingCardRecipients from "../greetingCards/GreetingCardRecipients";
import AddNewAddress from "../MyAccount/AddNewAddress";
import EgiftcardDeliveryDate from "../e-giftcard/EgiftcardDeliveryDate";
import About from "../footer/otherPage/About";
import Contact from "../footer/otherPage/Contact";
import Shipping from "../footer/otherPage/Shipping";
import Return from "../footer/otherPage/Return";
import FAQS from "../footer/otherPage/FAQS";
import Policy from "../footer/otherPage/Policy";
import TermsUse from "../footer/otherPage/TermsUse";
import PreviewVideo from "../Cart/previewVideo";
import MyProfile from "../MyAccount/MyProfile";
import MyOrder from "../MyAccount/MyOrder";
import MyReminders from "../MyAccount/MyReminders";
import MyAddresses from "../MyAccount/MyAddresses";
import ItemDetail from "../MyAccount/ItemDetail";
import LoginWithOtp from "../Login/loginWithOtp";
import AddGiftCard from "../greetingCards/AddGiftCard";
import GiftCardRecipients from "../giftCards/GiftCardRecipients";
import GiftCardAddresses from "../giftCards/GiftCardAddresses";
import {
  LOGIN,
  REGISTER,
  HOMEPAGE,
  MYACCOUNT,
  MYPROFILE,
  MYORDER,
  MYREMINDERS,
  MYADDRESS,
  MYORDER_DETAIL,
  MYORDER_ITEMDETAIL,
  ADDNEW_ADDRESS,
  EDIT_ADDRESS,
  LOGIN_OTP,
  CATEGORIES,
  CARDLISTING,
  CARDDETAIL,
  CARDMESSAGE,
  GREETING_ADDRESS,
  GREET_RECIPIENT_AFTER_GIFT_LOGOUT,
  GREET_RECIPIENT_AFTER_GIFT_LOGIN,
  GREET_DELIVERY_AFTER_GIFT_LOGIN,
  GREET_ADDRESS_AFTER_GIFT,
  GREET_DELIVERY_AFTER_GIFT_LOGOUT,
  GREET_RECIPIENT_LOGIN,
  GREET_RECIPIENT_LOGOUT,
  GREET_DELIVERY_LOGOUT,
  GREET_DELIVERY_LOGIN,
  GREET_ADD_AMOUNT,
  GREET_ADD_CARD,
  GIFT_AMOUNT,
  GIFT_SELECT,
  GIFT_ONLY_RECIPIENT_LOGIN,
  GIFT_ONLY_ADDRESS_LOGIN,
  GIFT_ONLY_DELIVERY_LOGIN,
  GIFT_ONLY_DELIVERY_LOGOUT,
  GIFT_ONLY_RECIPIENT_LOGOUT,
  GIFT_CATEGORIES,
  GIFT_CARDLISTING,
  GIFT_CARDMESSAGE,
  GIFT_CARDDETAIL,
  GIFT_ADDRESS,
  GIFT_RECIPIENTS_LOGIN,
  GIFT_RECIPIENTS_LOGOUT,
  GIFT_DELIVERY_LOGOUT,
  GIFT_DELIVERY_LOGIN,
  EGIFT_VIDEO_DELIVERY,
  EGIFT_GIF_DELIVERY,
  EGIFT_GIF_RECIPIENT,
  EGIFT_VIDEO_RECIPIENT,
  EGIFT_GIF,
  EGIFT_SELECT,
  EGIFT_AMOUNT,
  EGIFT_VIDEOLINK,
  EGIFT_VIDEO,
  CART,
} from "./ConstURL";

export const globalRoutes = [
  {
    path: HOMEPAGE,
    element: HomePage,
  },
  {
    path: REGISTER,
    element: Register,
    routeType: OpenRoutes,
  },
  {
    path: LOGIN,
    element: Loginpage,
    routeType: OpenRoutes,
  },
  {
    path: MYACCOUNT,
    element: MyAccount,
    routeType: ProtectedRouts,
    child: [
      {
        path: MYPROFILE,
        element: MyProfile,
      },
      {
        path: MYORDER,
        element: MyOrder,
      },
      {
        path: MYREMINDERS,
        element: MyReminders,
      },
      {
        path: MYADDRESS,
        element: MyAddresses,
      },
      {
        path: MYORDER_DETAIL,
        element: MyOrderDetail,
      },
      {
        path: MYORDER_ITEMDETAIL,
        element: ItemDetail,
      },
      {
        path: ADDNEW_ADDRESS,
        element: AddNewAddress,
      },
      {
        path: EDIT_ADDRESS,
        element: AddNewAddress,
      },
    ],
  },
  {
    path: LOGIN_OTP,
    element: LoginWithOtp,
    routeType: OpenRoutes,
  },
  {
    path: CATEGORIES,
    element: GreetingCategories,
  },
  {
    path: CARDLISTING,
    element: GreetingCardListing,
  },
  {
    path: CARDDETAIL,
    element: GreetingCardDetail,
  },
  {
    path: CARDMESSAGE,
    element: GreetingCardPersonalise,
  },
  {
    path: GREETING_ADDRESS,
    element: GreetingCardAddresses,
  },
  {
    path: GREET_RECIPIENT_AFTER_GIFT_LOGOUT,
    element: GreetingCardRecipients,
  },
  {
    path: GREET_RECIPIENT_AFTER_GIFT_LOGIN,
    element: GreetingCardRecipients,
  },
  {
    path: GREET_DELIVERY_AFTER_GIFT_LOGIN,
    element: GreetingCardDelivery,
  },
  {
    path: GREET_ADDRESS_AFTER_GIFT,
    element: GreetingCardAddresses,
  },
  {
    path: GREET_DELIVERY_AFTER_GIFT_LOGOUT,
    element: GreetingCardDelivery,
  },
  {
    path: CART,
    element: FirstCheckout,
  },
  {
    path: "/ForgetPassword",
    element: ForgetPassword,
    routeType: OpenRoutes,
  },
  {
    path: "/resetpassword",
    routeType: OpenRoutes,
    element: ResetPassword,
  },
  {
    path: "/otpverify",
    routeType: OpenRoutes,
    element: OtpVerify,
  },
  {
    path: "/mycart/payment",
    element: Payment,
  },
  {
    path: "/confirmation",
    element: Confirmation,
  },
  {
    path: "*",
    element: Error,
  },
  {
    path: "/emailverify",
    element: EmailVerify,
    routeType: OpenRoutes,
  },
  {
    path: "/phoneverify",
    element: PhoneVerify,
    routeType: OpenRoutes,
  },
  {
    path: "/emailotp",
    element: EmailOtp,
    routeType: OpenRoutes,
  },
  {
    path: "/verifyemailforpassword",
    element: VerifyEmailForPass,
    routeType: OpenRoutes,
  },
  {
    path: EGIFT_VIDEO,
    element: Recordvideo,
  },
  {
    path: EGIFT_VIDEOLINK,
    element: PreviewVideo,
  },
  {
    path: EGIFT_AMOUNT,
    element: SelectAmount,
  },
  {
    path: EGIFT_SELECT,
    element: SelectType,
  },
  {
    path: EGIFT_GIF,
    element: SelectGif,
  },
  {
    path: EGIFT_VIDEO_RECIPIENT,
    element: EgiftRecipients,
  },
  {
    path: EGIFT_GIF_RECIPIENT,
    element: EgiftRecipients,
  },
  {
    path: EGIFT_GIF_DELIVERY,
    element: EgiftcardDeliveryDate,
  },
  {
    path: EGIFT_VIDEO_DELIVERY,
    element: EgiftcardDeliveryDate,
  },
  {
    path: GREET_RECIPIENT_LOGIN,
    element: GreetingCardRecipients,
  },
  {
    path: GREET_RECIPIENT_LOGOUT,
    element: GreetingCardRecipients,
  },
  {
    path: GIFT_AMOUNT,
    element: GiftCardAmount,
  },
  {
    path: GIFT_SELECT,
    element: GiftAddCard,
  },
  {
    path: GIFT_ONLY_RECIPIENT_LOGIN,
    element: GiftCardRecipients,
  },
  {
    path: GIFT_ONLY_ADDRESS_LOGIN,
    element: GiftCardAddresses,
  },
  {
    path: GIFT_ONLY_DELIVERY_LOGIN,
    element: GreetingCardDelivery,
  },
  {
    path: GIFT_ONLY_DELIVERY_LOGOUT,
    element: GreetingCardDelivery,
  },
  {
    path: GREET_DELIVERY_LOGOUT,
    element: GreetingCardDelivery,
  },
  {
    path: GREET_DELIVERY_LOGIN,
    element: GreetingCardDelivery,
  },
  {
    path: GIFT_ONLY_RECIPIENT_LOGOUT,
    element: GiftCardRecipients,
  },
  {
    path: GREET_ADD_AMOUNT,
    element: GreetingCardAmount,
  },
  {
    path: GREET_ADD_CARD,
    element: AddGiftCard,
  },
  {
    path: GIFT_CATEGORIES,
    element: GiftCategories,
  },
  {
    path: GIFT_CARDLISTING,
    element: GiftCardListing,
  },
  {
    path: GIFT_CARDDETAIL,
    element: GiftCardDetail,
  },
  {
    path: GIFT_CARDMESSAGE,
    element: GiftCardPersonalise,
  },
  {
    path: GIFT_ADDRESS,
    element: GiftCardAddresses,
  },
  {
    path: GIFT_RECIPIENTS_LOGIN,
    element: GiftCardRecipients,
  },
  {
    path: GIFT_RECIPIENTS_LOGOUT,
    element: GiftCardRecipients,
  },
  {
    path: GIFT_DELIVERY_LOGIN,
    element: GreetingCardDelivery,
  },
  {
    path: GIFT_DELIVERY_LOGOUT,
    element: GreetingCardDelivery,
  },
  {
    path: "/about",
    element: About,
  },
  {
    path: "/contact",
    element: Contact,
  },
  {
    path: "/shipping&delivery",
    element: Shipping,
  },
  {
    path: "/return",
    element: Return,
  },
  {
    path: "/faqs",
    element: FAQS,
  },
  {
    path: "/privacypolicy",
    element: Policy,
  },
  {
    path: "/termsofuse",
    element: TermsUse,
  },
];
