/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Box, Button, Typography, Grid, Skeleton, Checkbox } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import VideoRecorder from "react-video-recorder";
import { storage } from "../../config/storage";
import { connect } from "react-redux";
import { saveVideo } from "../../redux/action";
import LoaderComponent from "../Loader/LoaderComponent";
import { UserContext } from "../home/main";
import { useContext } from "react";
import { EGIFT_VIDEO_RECIPIENT } from "../routes/ConstURL";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const CardAmount = ({
  headerText,
  amount,
  image,
  onClick,
  bgcolor,
  width,
  pt,
  pb,
  heading,
  grid,
  record,
  selectedIndex,
  setSelectedIndex,
  saveVideo,
  gift,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [policyAccepted, setPolicyAccepted] = React.useState(false);
  // const [preview, setPreview] = React.useState("");
  const onSubmit = () => {
    if (value === true) {
      navigate(onClick);
    } else {
      toast.error("Select Amount");
    }
  };
  let videoValue;
  let objectPass = {};
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selected = (val, index) => {
    const newItem = selectedIndex.map((newVal, ind) => {
      if (ind === index) {
        if (newVal.selected == true) {
          newVal.selected = false;
          return newVal;
        } else {
          newVal.selected = true;
          return newVal;
        }
      } else {
        newVal.selected = false;
        return newVal;
      }
      // comparing category for displaying data
    });
    setSelectedIndex(newItem);
    if (gift) {
      const previousId = storage.fetch.giftamountID();
      if (previousId === val.gift_card_id) {
        setValue(false);
        storage.destroy.giftamountID(val.gift_card_id);
      } else {
        storage.set.giftamountID(val.gift_card_id);
        setValue(true);
      }
    } else {
      const previousId = storage.fetch.amountID();
      if (previousId === val.ecard_id) {
        setValue(false);
        storage.destroy.amountID(val.ecard_id);
      } else {
        storage.set.amountID(val.ecard_id);
        setValue(true);
      }
    }
  };
  const saveVideoApi = async (item) => {
    videoValue = item;
    const myFile = new File([item], "a.webm", {
      type: "video/webm;codecs=vp8,opus",
    });
    objectPass = {
      file: myFile,
    };
  };
  const onClicks = async () => {
    // console.log(location.state);
    if (videoValue) {
      setOpen(true);
      saveVideo(objectPass).then((res) => {
        setOpen(false);
        if (res.data.status) {
          toast.error(res.data.message);
          // storage.set.item_id(res.data.data.media_file_id);
          navigate(EGIFT_VIDEO_RECIPIENT, {
            state: location.state == null ? "E_gift_cards" : location.state,
          });
          setOpen(false);
        } else {
          setOpen(false);
        }
      });
    } else {
      toast.error("Please record your video first");
    }
  };
  const handleChecked = (e) => {
    setPolicyAccepted(e.target.checked);
  };
  return (
    <>
      <Toastify />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#F3F0EE",

          alignItems: "center",
          pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
          pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        }}>
        <LoaderComponent open={open} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: "center",
            pt: { xs: 2, sm: 2, md: 3, lg: 5, xl: 5 },
            pb: 5,
            bgcolor: "#F3F0EE",
          }}>
          <Stack
            direction={{ xs: "column", sm: "column" }}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "50%",
                xl: "40%",
              },
            }}>
            {record && (
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "95%",
                    lg: "95%",
                    xl: "95%",
                  },
                  bgcolor: "black",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!policyAccepted && (
                  <Typography
                    sx={{
                      color: "white",
                      textAlign: "center",
                    }}>
                    Please accept term and condition first.
                  </Typography>
                )}
                {policyAccepted && (
                  <VideoRecorder
                    timeLimit={60000}
                    audio={true}
                    isFlipped={true}
                    onRecordingComplete={(videoBlob) => {
                      saveVideoApi(videoBlob);
                      // setPreview(videoBlob);
                    }}
                    onStopReplaying={() => {
                      // setPreview("");
                    }}
                  />
                )}
              </Box>
            )}

            {grid && selectedIndex?.length > 0 && (
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "85%",
                    lg: "85%",
                    xl: "85%",
                  },
                  pt: pt,
                  pb: pb,
                  borderRadius: "10px",
                  bgcolor: bgcolor,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  boxShadow: "5px solid gray",
                }}>
                <img alt="logo" style={{ maxWidth: width }} src={image} />
              </Box>
            )}
            {selectedIndex?.length === 0 && <Skeleton variant="rectangular" style={{ borderRadius: "20px" }} width={392} height={422} />}
          </Stack>

          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
                lg: "65%",
                xl: "65%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
            <Typography
              sx={{
                mt: 3,
                mb: selectedIndex ? 3 : "",
                color: "#3D2E57",

                fontWeight: 700,
                fontSize: { xs: 22, sm: 22, md: 26, lg: 35, xl: 35 },
              }}>
              {heading}
            </Typography>

            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "70%",
                  xl: "60%",
                },
              }}>
              {grid && (
                <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} columns={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
                  {selectedIndex?.length === 0 &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                      <Grid item xs={3} sm={3} md={2} lg={2} xl={2} key={index} sx={{}}>
                        <Skeleton variant="rectangular" style={{ borderRadius: "5px" }} width={"80px"} height={"40px"} />
                      </Grid>
                    ))}
                  {selectedIndex &&
                    selectedIndex?.map((val, index) => (
                      <Grid item xs={3} sm={3} md={2} lg={2} xl={2} key={index} sx={{}}>
                        <Button
                          sx={{
                            border: "1px solid #BDBDBD",
                            color: "black",
                            borderColor: "black",
                            fontWeight: "400",
                            "&.MuiButtonBase-root:hover": {
                              border: "1px solid #BDBDBD",
                            },
                            width: {
                              xs: "70px",
                              sm: "60px",
                              md: "80px",
                              lg: "80px",
                            },
                            fontSize: 18,
                            mr: 1,
                          }}
                          style={{
                            border: val.selected ? "1px solid rgba(255, 141, 42, 1)" : "",
                          }}
                          variant="outlined"
                          onClick={() => selected(val, index)}>
                          ${val.price}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
            {grid && (
              <Box>
                <Button
                  variant="outlined"
                  onClick={onSubmit}
                  sx={{
                    fontSize: "16px",
                    borderRadius: "15px",
                    mt: 4,
                    mb: 2,
                    color: "#3D2E57",
                    borderColor: "black",
                    textTransform: "none",
                  }}>
                  Continue
                  <ArrowForwardIosIcon fontSize="16" />
                </Button>
              </Box>
            )}
            {record && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex" }}>
                  <Checkbox checked={policyAccepted} onChange={(e) => handleChecked(e)} sx={{ width: "25px" }} {...label} />
                  <Typography
                    sx={{
                      color: "#3D2E57",
                      ml: "10px",
                      alignItems: "center",
                      display: "flex",
                    }}>
                    Terms & conditions applied for recording a video !
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  onClick={onClicks}
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    borderRadius: "15px",
                    mt: 2,
                    mb: 2,
                    color: "#3D2E57",
                    borderColor: "black",
                    textTransform: "none",
                    width: "36%",
                  }}>
                  Continue
                  <ArrowForwardIosIcon fontSize="20" />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {/* {preview && (
          <video
            src={window.URL.createObjectURL(preview)}
            width="30%"
            style={{ marginTop: "60px" }}
            autoPlaym
            loop
            controls
          />
        )} */}
      </Box>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveVideo: (item) => dispatch(saveVideo(item)),
  };
};

export default connect(null, mapDispatchToProps)(CardAmount);
