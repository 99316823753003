import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { storage as LocalStorage } from "../../config/storage";
import { toast} from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import 'react-toastify/dist/ReactToastify.css';

const OpenRoutes = ({children}) => {
  // const [state, setstate] = useState(false);
 const acc_Token = LocalStorage.fetch.authToken()
  if (acc_Token) {
    //   toast.error('Page Not Found')
    //   setTimeout(() => {
    //     toast.error('Page Not Found')
    // }, 500);
          return <Navigate to="/" replace />
  } else {
    
}
return children

};

export default OpenRoutes;
