/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import { getCardListing } from "../../redux/action";
import CardListing from "../Custom/CardListing";
import { useLocation } from "react-router-dom";
import LoaderComponent from "../Loader/LoaderComponent";
import Toastify from "../SnackBar/Toastify";
import { CARDDETAIL } from "../routes/ConstURL";

const GreetingCardListing = ({ getCardListing }) => {
  const aboveBreadcrumb = useLocation();
  const [dataEmpty, setDataEmpty] = React.useState(false);
  // const { id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [heading, setheading] = useState();
  const newState = useSelector((state) => state?.User?.cardlisting);
  const location = useLocation();
  const [start, setStart] = useState(1);
  let id = location?.state?.id;
  if (id) {
    storage.set.category_id(id);
  }
  let id_storage = storage.fetch.category_id();
  if (id_storage) {
    id = id_storage;
  } else {
  }
  let length = 8 * start;

  const handleScrolling = () => {
    setStart(start + 1);
  };
  useEffect(() => {
    handleDataFetch();
  }, [start]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleDataFetch = () => {
    setheading(aboveBreadcrumb?.state?.crumbValue);
    storage.set.prev_id(id);
    let category_id = {
      category_id: id,
      start: 0,
      length: length,
    };
    // Object.assign({ category_id, data });
    getCardListing(category_id).then((res) => {
      if (res?.data?.data.length === 0) {
        setDataEmpty(true);
        setLoad(false);
        toast.warn(res?.data?.message);
      } else {
        setLoad(false);
      }
    });
  };
  return (
    <>
      <LoaderComponent open={load} />
      <Toastify />
      <CardListing heading={heading} newState={newState} dataEmpty={dataEmpty} link={CARDDETAIL} next={handleScrolling} />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getCardListing: (category_id) => dispatch(getCardListing(category_id)),
  };
}
export default connect(null, mapDispatchToProps)(GreetingCardListing);
