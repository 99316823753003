import { Api } from "../../config/request";

export const request_e_gift_card_amount = async (data) => {
  return Api.getListRequest("/products/eCards/eCardsList", data);
};
export const request_e_gift_card_gif = async (data) => {
  return Api.getListRequest("/products/ecards/list_animated_gif", data);
};
export const saveVideoApi = async (data) => {
  return Api.videoPostrequest("/ecard/record/video/message", data);
};
export const requestVideoPreview = async (data) => {
  return Api.videoPostrequest("/get/video/message", data);
};
const GiftCardApi = {
  request_e_gift_card_amount,
  request_e_gift_card_gif,
  saveVideoApi,
  requestVideoPreview
};
export default GiftCardApi;
