import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { withStyles } from "@mui/styles";
import "./Header.css";
// import Toolbar from '@mui/material/Toolbar';
import { Box, IconButton, Menu, MenuItem, ListItemIcon, Dialog, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "./Drawer";
import LogoutIcon from "@mui/icons-material/Logout";
import { storage } from "../../config/storage";
import { AccountData } from "../../config/mockData";
import { Navigate } from "react-router-dom";
import { Button, DialogActions, DialogContentText } from "@mui/material";
import { CART, CATEGORIES, EGIFT_AMOUNT, GIFT_AMOUNT, LOGIN, MYPROFILE, REGISTER } from "../routes/ConstURL";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = React.useState(false);
  const [Before_login, setBefore_login] = React.useState();
  const [After_login, setAfter_login] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popup, setPopup] = React.useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [After_login_width, setAfter_login_width] = React.useState();
  const handleDialog = () => {
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setPopup(true);
  };
  const MyMenuItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "white !important",
        color: "#FF8D2A",
      },
      fontSize: "20px",
      display: "flex",
      alignItems: "center",
      color: "#3D2E57",
    },
  })(MenuItem);

  const Active = (item) => {
    AccountData.map((element) => {
      if (element.val === item) {
        element.active = true;
        element.color = "#FF8D2A";
        element.bgcolor = "#3D3264";
      } else {
        element.active = false;
        element.color = "#3D2E57";
        element.bgcolor = "#3D2E57";
      }
    });
  };

  const handleClick = (event) => {
    if (storage?.fetch?.authToken()?.length > 7) {
      setBefore_login(false);
      setAfter_login(true);
      setAfter_login_width({
        xs: "40%",
        sm: "20%",
        md: "22%",
        lg: "20%",
        xl: "18%",
      });
    } else {
      setBefore_login(true);
      setAfter_login(false);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        zIndex: 999,
        bgcolor: "white",
      }}>
      <AppBar sx={style.appBar} className="main">
        <Box sx={style.logoBox}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
            }}>
            <Box sx={style.logoImage}>
              <img alt="logo" className="logoSize" style={style.image} src={require("../../assets/Gopher-Logo-Retina.png")} />
              <img alt="logo" className="logoSize2" style={style.image} src={require("../../assets/Gopher-Logo-Logo.png")} />
            </Box>
          </Link>
        </Box>

        <Box sx={style.buttonCategory}>
          <NavLink to={CATEGORIES} className={({ isActive }) => ["fontsize", isActive ? "active" : "inactive"].filter(Boolean).join(" ")}>
            GREETING CARDS
          </NavLink>
          <NavLink
            to={GIFT_AMOUNT}
            className={({ isActive }) => ["fontsize", isActive ? "active" : "inactive"].filter(Boolean).join(" ")}
            // onClick={handleDialog}
          >
            GIFT CARDS
          </NavLink>
          <NavLink to={EGIFT_AMOUNT} className={({ isActive }) => ["fontsize", isActive ? "active" : "inactive"].filter(Boolean).join(" ")}>
            E - GIFT CARDS
          </NavLink>
        </Box>
        <Dialog
          open={popup}
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
          }}>
          <DialogContent
          // sx={{
          //   width: 500,
          // }}
          >
            <DialogContentText
              sx={{
                fontSize: "24px",
              }}>
              This feature is not available now and will come soon. Sorry for inconvenience.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPopup(false);
              }}
              autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: After_login_width,
          }}>
          {/* {storage?.fetch?.authToken()?.length > 7 && ( */}
          <IconButton onClick={() => navigate(CART)} sx={style.fab}>
            <img alt="logo" className="userIcon" src={require("../../assets/cart.png")} />
          </IconButton>
          {/* )} */}

          {storage?.fetch?.authToken()?.length > 7 && (
            <IconButton sx={style.fab}>
              <img alt="logo" className="userIcon" src={require("../../assets/search.png")} />
            </IconButton>
          )}

          <IconButton onClick={handleClick} aria-haspopup="true" aria-expanded={open ? "true" : undefined} sx={style.fab1}>
            <img alt="logo" className="userIcon" src={require("../../assets/user.png")} />
          </IconButton>

          {Before_login && (
            <Menu
              disableScrollLock
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  mt: 1.5,
                  "& .MuiAvatar-root": {},
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}>
              <MyMenuItem
                disableRipple
                onClick={() => {
                  navigate(LOGIN);
                }}>
                Login
              </MyMenuItem>
              <MyMenuItem
                disableRipple
                onClick={() => {
                  navigate(REGISTER);
                }}>
                Sign Up
              </MyMenuItem>
            </Menu>
          )}

          {After_login && (
            <Menu
              disableScrollLock
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  mt: 1,

                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}>
              <MyMenuItem
                disableRipple
                onClick={() => {
                  Active("My Profile");
                  navigate(MYPROFILE);
                }}>
                My Account
              </MyMenuItem>
              <MyMenuItem
                disableRipple
                onClick={() => {
                  localStorage.clear();
                  setAfter_login_width();
                  navigate(LOGIN);
                }}>
                <LogoutIcon />
                &nbsp; Logout
              </MyMenuItem>
            </Menu>
          )}

          <IconButton
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#3D2E57",
              },
              fontSize: "10px",
              color: "white",
              bgcolor: "#3D2E57",
            }}
            size="small"
            aria-label="delete"
            onClick={() => setState(true)}>
            <MenuIcon />
          </IconButton>
          {isMobile && <Drawer open={state} onClick={() => setState(false)} />}
        </Box>
      </AppBar>
    </Box>
  );
};
export default Header;

const style = {
  appBar: {
    bgcolor: "#FFFFFF",

    width: "100%",
    // minWidth: "1359px",
    // maxWidth: {xs:"85%",xl:"44%"},
    maxWidth: { xl: "1360px", lg: "1200px" },
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pt: { xs: 3, sm: 3.5, md: 5, lg: 4, xl: 4 },
    pb: { xs: 3, sm: 3.5, md: 5, lg: 4, xl: 4 },
    // pl:{xs:1,sm:2,md:3,lg:7,xl:7},
    // pr:{xs:1,sm:2,md:3,lg:7,xl:7},

    mr: "auto",
    ml: "auto",
    justifyContent: "space-between",
    position: "unset",
    flexDirection: "row",
    boxShadow: "none",
  },
  logoBox: {
    // display: "flex",
    // height: "100%",
    width: { xs: "55%", sm: "37%", md: "37%", lg: "60%", xl: "60%" },
  },
  logoImage: {
    // maxHeight: "100px",
    // maxWidth:"463px",
    maxWidth: "363px",
    // mt: 1.5,
  },
  image: {
    width: "100%",
  },

  fab: {
    width: { xs: "34px", sm: "32px", md: "35px", lg: "42px", xl: "45px" },
    height: { xs: "34px", sm: "32px", md: "35px", lg: "42px", xl: "45px" },
    marginRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#3D2E57",
    "&:hover": {
      bgcolor: "#3D2E57",
    },
  },
  fab1: {
    width: { sm: "30px", md: "35px", lg: "42px", xl: "45px" },
    height: { sm: "30px", md: "35px", lg: "42px", xl: "45px" },
    display: {
      xs: "none",
      sm: "flex",
      md: "flex",
      lg: "flex",
      xl: "flex",
    },
    justifyContent: "center",
    alignItems: "center",
    bgcolor: { sm: "#3D2E57" },
    "&:hover": {
      bgcolor: "#3D2E57",
    },
  },
  buttonCategory: {
    width: "100%",
    alignItems: "center",
    display: {
      xs: "none",
      sm: "flex",
      md: "flex",
      lg: "flex",
      xl: "flex",
    },
    // flex: { xs: 0, sm: 4.5, md: 4.5, lg: 0.8, xl: 0.8 },
    justifyContent: {
      xs: "center",
      sm: "flex-end",
      md: "flex-end",
      lg: "flex-end",
      xl: "flex-end",
    },
  },
};
