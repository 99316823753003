import {Api} from '../../config/request'

export const requestremoveItem = async (data) => {
  return Api.getListRequest('/remove/item/from/cart', data);
}
export const requestCheckout = async (data) => {
  // return Api.getListRequest('/validate/recipient/address', data);
}
export const requestCartItem = async (data) => {
  return Api.getListRequest('/get/items/in/cart', data);
};
export const requestAddtocart = async (data) => {
  return Api.getListRequest('/add/to/cart', data);
};
export const requestupdateMsg = async (data) => {
  return Api.getListRequest('/update/item/msg/in/cart', data);
};
export const requesthandleQty = async (data) => {
  return Api.getListRequest('/update/item/qty/in/cart', data);
};
const CartApi = {
    requestremoveItem,
    requestCheckout,
    requestCartItem,
    requestAddtocart,
    requestupdateMsg,
    requesthandleQty
};
export default CartApi;
