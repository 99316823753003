import * as React from "react";
import * as yup from "yup";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, TextField, FormLabel, Skeleton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoaderComponent from "../Loader/LoaderComponent";
import { check_message } from "../../redux/action";
import { storage } from "../../config/storage";
import Toastify from "../SnackBar/Toastify";
import { useFormik } from "formik";

const defaultValues = {
  greet_msg: "",
};
const Cardpersonalise = ({ check_message, img, limit, newState, skeleton, onClick, onClick1 }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const recipient_data = storage.fetch.message();
  const [msg, setmsg] = useState(recipient_data);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const schema = yup.object().shape({
    greet_msg: yup.string().required("Please enter message").max(limit, `Only ${limit} Characters can be printed`),
  });
  const formik = useFormik({
    initialValues: msg ? msg : {},
    validationSchema: schema,
    onSubmit: (value) => {
      setmsg(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = async (model) => {
    setLoading(true);
    let item_id = { greeting_card_id: storage.fetch.card_detail_id() };
    let item = model;
    storage.set.message({ greet_msg: item.greet_msg });
    Object.assign(item, item_id);
    check_message(item).then((res) => {
      setLoading(false);
      if (res.data.status) {
        setTimeout(() => {
          if (storage.fetch.authToken()) {
            navigate(onClick, { state: location?.state?.from || storage.fetch.card_detail_id() });
          } else {
            navigate(onClick1, { state: location?.state?.from });
          }
        }, 500);
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          // width: "100%",
          bgcolor: "#F3F0EE",
          pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
          pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        }}>
        <LoaderComponent open={loading} />
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: { xs: "space-between", sm: "space-between" },

            pt: { xs: 2, sm: 2, md: 3, lg: 5, xl: 5 },
            bgcolor: "#F3F0EE",
          }}>
          {
            skeleton && (
              //  [1].map((item) => (
              <Box sx={{ width: { xs: "100%", md: "30%" }, height: "1020px" }}>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={350}
                  sx={{
                    borderRadius: "20px",
                  }}
                />
              </Box>
            )
            // ))
          }
          {img && (
            <Stack
              direction={{ xs: "column", sm: "column" }}
              // spacing={{ xs: 1, sm: 2,  }}
              sx={{
                width: {
                  xs: "100%",
                  sm: "25%",
                  md: "50%",
                  lg: "30%",
                  xl: "30%",
                },
                // pb: 5,
              }}>
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%",
                    sm: "350px",
                    md: "400px",
                    lg: "445px",
                  },
                  maxHeight: {
                    xs: "270px",
                    sm: "350px",
                    md: "300px",
                    lg: "360px",
                  },

                  width: "100%",
                  mt: { xs: 0, sm: 5, md: 0 },
                  pl: { xs: 0, sm: 5, md: 2.5 },
                  pr: { xs: 0, sm: 5, md: 2.5 },
                  pt: { xs: 4, sm: 5, md: 5, lg: 2 },
                  pb: { xs: 4, sm: 5, md: 5, lg: 2 },
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  bgcolor: "#FFFFFF",
                  // justifyContent: "center",
                }}>
                <img alt="logo" className="cardMessagePhoto" src={img} />
              </Box>
            </Stack>
          )}
          <Box
            sx={{
              ml: { xs: 0, sm: 0, md: 7, lg: 10, xl: 10 },
              mt: { xs: 3, sm: 0 },
              width: {
                xs: "100%",
                sm: "60%",
                md: "80%",
                lg: "65%",
                xl: "65%",
              },
              display: "flex",
              flexDirection: "column",
            }}>
            <form name="addmessage" onSubmit={formik.handleSubmit} schema={schema}>
              <FormLabel
                sx={{
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: { xs: "22px", sm: "25px", md: 35 },
                  lineHeight: "42px",
                  color: "#3D2E57",
                }}>
                Add your personalised message
                <span className="star">*</span>
              </FormLabel>

              <TextField
                name="greet_msg"
                variant="filled"
                value={formik.values.greet_msg}
                onChange={formik.handleChange}
                InputProps={{ disableUnderline: true }}
                type="text"
                color="primary"
                inputProps={
                  {
                    // style: { height: "100%" },
                  }
                }
                placeholder="Enter Your Message here"
                // maxRows={8}
                rows={9}
                fullWidth={true}
                multiline
                sx={{
                  width: "100%",
                  bgcolor: "#F5F5F5",
                  pb: 0,
                  // height: "100%",
                  // rows: 4,
                  // mt: "20px",
                }}
              />
              {formik.errors.greet_msg ? <p style={{ color: "red" }}>{formik.errors.greet_msg}</p> : null}

              <Box>
                <Button
                  variant="outlined"
                  //onClick={() => navigate("/deliverydate")}
                  type="submit"
                  sx={{
                    fontFamily: "Effra",
                    fontStyle: "normal",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "19px",
                    pr: "18px",
                    pl: "25px",
                    mt: 4,
                    mb: 2,
                    borderRadius: "15px",
                    color: "#3D2E57",
                    border: "1px solid #3D2E57",
                    textTransform: "none",
                  }}>
                  Continue
                  <ArrowForwardIosIcon fontSize="20" />
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        {/* </Box> */}
        <Toastify />
        {/* </Box> */}
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    check_message: (item) => dispatch(check_message(item)),
  };
}
export default connect(null, mapDispatchToProps)(Cardpersonalise);

const style = {
  headerBox: {
    // maxWidth:'1360px',
    width: "100%",
    height: {
      xs: "45px",
      sm: "40px",
      md: "40px",
      lg: "60px",
      xl: "60px",
    },
    bgcolor: "#F9F7F6",
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    textAlign: "flex-start",
  },
  headertxt: {
    fontWeight: "700",
    maxWidth: "1360px",
    bgcolor: "red",
    fontSize: { xs: "12px", sm: "12px", md: "13px", lg: "14px", xl: "14px" },
    lineHeight: "17px",
    pl: 1,
    ml: "auto",
    mr: "auto",
    color: "#3D2E57",
    textTransform: "uppercase",
  },
};
