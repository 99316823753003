import * as React from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputField from "../Input/InputField";
import { Link, useNavigate } from "react-router-dom";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getLogin } from "../../../redux/action";
import { storage } from "../../../config/storage";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const defaultValues = {
  email: "",
  password: "",
};
const schema = yup.object().shape({
  email: yup.string().required("Please enter your email").email("Please enter valid email"),
  password: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
});
const LoginWithPassModal = ({ onCloseModal, openModal, onClickModal1, onClickModal2, width, height, getLogin, net_amount }) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  let cart_id = storage.fetch.carts_id();

  let id = {};
  if (cart_id !== undefined) {
    id = { carts_id: cart_id, carts_id_check: true };
  } else {
    id = { carts_id_check: false };
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    height: height,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };
  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const onSubmit = async (model) => {
    setloader(true);
    let item = model;
    Object.assign(item, id);
    getLogin(item).then((res) => {
      setloader(false);
      if (res.data.status) {
        toast.success(res?.data?.message);
        storage.set.authToken(res?.data?.token);
        storage.set.carts_id(res?.data?.carts_id);

        setTimeout(() => {
          navigate("/mycart/payment", { state: net_amount });
        }, 1000);
      } else {
        res?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        storage.set.userId(res?.data?.user_id);
        setTimeout(() => {
          if (res?.data?.is_email_verified === 0) {
            navigate("/emailotp");
          }
          setloader(false);
        }, 1000);
      }
    });
  };
  return (
    <div>
      <Modal open={openModal} onClose={onCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form
            name="LoginForm"
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "35px" },
                lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
                fontWeight: "600",
                textAlign: "center",
                color: "#3D2E57",
                mt: 5,
                mb: 2,
              }}>
              Login
            </Typography>

            <Box sx={{ height: { xs: "25px", md: "20px" } }} />

            <InputField
              control={control}
              helperText={errors?.email?.message}
              errors={!!errors.email}
              type={"text"}
              placeholder={"Enter email here"}
              formlabel="Email"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="email"
              required={"*"}
            />

            <Box sx={{ height: { xs: "25px", md: "20px" } }} />

            <InputField
              control={control}
              helperText={errors?.password?.message}
              errors={!!errors.password}
              variant="filled"
              placeholder={"Enter password"}
              formlabel="Password"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="password"
              type={"password"}
              required={"*"}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}></Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Button
                disableRipple
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "60%",
                    lg: "40%",
                    xl: "40%",
                  },
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                    border: "1.5px solid #3D2E57",
                  },
                  bgcolor: "#FFFFFF",
                  color: "#3D2E57",
                  border: "1.5px solid #3D2E57",
                  // p: 1,
                  mb: 2,
                  mt: 2,
                  borderRadius: "15px",
                  fontSize: { xs: "20px", md: "22px", lg: "24px" },
                  fontWeight: 500,
                  textTransform: "none",
                }}
                variant="outlined"
                type="submit">
                Login
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "center" },
              }}>
              <Link
                // to="/register"
                onClick={onClickModal1}
                className="fontlink2"
                style={{
                  fontWeight: 400,
                  color: "#FF8D2A",
                  textDecoration: "none",
                  marginBottom: "10px",
                }}>
                Register as a guest User
              </Link>
              <Button
                disableRipple
                onClick={onClickModal2}
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                  },
                  textTransform: "none",
                  fontWeight: 400,
                  color: "#3D2E57",
                  textDecoration: "none",
                  maxHeight: "37px",
                  mb: "10px",
                  fontSize: "20px",
                  pt: 0,
                  pb: 1,
                }}
                variant="text">
                Login with OTP
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (item) => dispatch(getLogin(item)),
  };
};

export default connect(null, mapDispatchToProps)(LoginWithPassModal);
