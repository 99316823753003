import { ADDNEW_ADDRESS, MYADDRESS, MYORDER, MYPROFILE, MYREMINDERS } from "../components/routes/ConstURL";

export const AccountData = [
  {
    val: "My Profile",
    routes: MYPROFILE,
  },
  {
    val: "My Order",
    routes: MYORDER,
    status: false,
  },
  {
    val: "My Reminders",
    routes: MYREMINDERS,
  },
  {
    val: "My Addresses",
    routes: MYADDRESS,
    status: false,
  },
  {
    val: "My OrderDetail",
    routes: "/myaccount/myorder/myorderdetail/",
    Inpro: "#FF8D2A",
    compt: "#219653",
  },
  {
    val: "AddNewAddress",
    routes: ADDNEW_ADDRESS,
  },
  { val: "ItemDetail", routes: "/myaccount/itemdetail" },
];

export const AddressData = [
  {
    recipient_name: "John Smith",
    recipient_email: "mailto:a@gmail.com",
    recipient_contact: "(03) 5319 3860",
    recipient_address1: "37 Wilson Street Titybong 3542",
  },
  {
    recipient_name: "John Smith",
    recipient_email: "mailto:a@gmail.com",
    recipient_contact: "(03) 5319 3860",
    recipient_address1: "37 Wilson Street Titybong 3542",
  },
  {
    recipient_name: "John Smith",
    recipient_email: "mailto:a@gmail.com",
    recipient_contact: "(03) 5319 3860",
    recipient_address1: "37 Wilson Street Titybong 3542",
  },
  {
    recipient_name: "John Smith",
    recipient_email: "mailto:a@gmail.com",
    recipient_contact: "(03) 5319 3860",
    recipient_address1: "37 Wilson Street Titybong 3542",
  },
  {
    recipient_name: "John Smith",
    recipient_email: "mailto:a@gmail.com",
    recipient_contact: "(03) 5319 3860",
    recipient_address1: "37 Wilson Street Titybong 3542",
  },
];
export const FILE_TYPE = {
  IMAGE: 1,
  GIF: 2,
  VIDEO: 3,
  AUDIO: 4,
};
export const FooterPages = [
  { val: "About", active: false, color: "#3D2E57", route: "/about" },
  { val: "Contact Us", active: false, color: "#3D2E57", route: "/contact" },
  { val: "Shipping & Delivery", active: false, color: "#3D2E57", route: "/shipping&delivery" },
  { val: "Return", active: false, color: "#3D2E57", route: "/return" },
  { val: "FAQs", active: false, color: "#3D2E57", route: "/faqs" },
];
export const FooterLastPages = [
  { val: "2022 Gopher", route: "#" },
  { val: "Privacy Policy", route: "/privacypolicy" },
  { val: "Terms of Use", route: "/termsofuse" },
];
