import React from "react";
import { Box, Typography } from "@mui/material";

const FooterHeading = ({ heading }) => {
  return (
    <Box sx={style.headerBox}>
      <Typography sx={style.headertxt}>{heading}</Typography>
    </Box>
  );
};

export default FooterHeading;
const style = {
  headerBox: {
    maxWidth: "1360px" ,
    width: "100%",
    height: {
      xs: "50px",
      sm: "50px",
      md: "60px",
      lg: "120px",
      xl: "120px",
    },
    ml: "auto",
    mr: "auto",
    bgcolor: "#F3F0EE",

    // bgcolor:"red",
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "center",
  },
  headertxt: {
    fontWeight: "700",
    fontSize: { xs: "12px", sm: "12px", md: "13px", lg: "35px", xl: "35px" },
    color: "#3D2E57",
    mt: { xs: 2, sm: 3, md: 3, lg: 1, xl: 2 },
    // pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
};
