import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Skeleton } from "@mui/material";
import { VideoPreview } from "../../redux/action";
import { connect,useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderComponent from "../Loader/LoaderComponent";
const PreviewVideo = ({ VideoPreview }) => {
  const params = useParams();
  const [load, setLoad] = useState(true);
  const [video, setVideo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    let id = {files_id:params.id}
    VideoPreview(id).then((res) => {
      if (res.data.status) {
        setLoad(false);
        setVideo(res.data.data.video_path)
        
      }
    });
  }, []);

  return (<>
      <LoaderComponent open={load} />
      
    {load &&  <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        bgcolor:'#F3F0EE',
        p: 3,
          width:'100%',
          height:300 ,
        }}
      ><Skeleton sx={{
        width:500,
        height:500 ,
      }}/>
      </Box>
      }
      {!load && (

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:400 ,
        p: 3,
        bgcolor:'#F3F0EE'
      }}
    >
        <video width="500" height="300" controls>
          <source src={video} />
        </video>
    </Box>
      )}
      </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    VideoPreview: (data) => dispatch(VideoPreview(data)),
  };
};
export default connect(null, mapDispatchToProps)(PreviewVideo);
