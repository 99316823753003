import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { TextField, FormLabel, Button, Typography, InputLabel, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputField from "../Custom/Input/InputField";
import { stripePayment } from "../../redux/action";
import { useForm } from "react-hook-form";
import { storage } from "../../config/storage";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import LoaderComponent from "../Loader/LoaderComponent";
import { CART } from "../routes/ConstURL";
const schema = yup.object().shape({
  card_cvc: yup
    .string()
    .min(3, " Too short - should be 3 chars .")
    .max(3, "Too long - should be 3 chars  ")
    .required("Please enter Card number"),
  card_number: yup.string().min(16, "Card number should be of 16 number").required("Please enter Card number"),
  card_exp_year: yup.string().required("Please Select Year"),
  card_exp_month: yup.string().required("Please Select Month"),
});
const defaultValues = {
  card_number: "",
  card_cvc: "",
  card_exp_year: "",
  card_exp_month: "",
};

const Payment = ({ stripePayment }) => {
  const [loader, setloader] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { control, formState, setValue, handleSubmit, setError } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  const years = [];
  var min = new Date().getFullYear();
  var max = min + 50;

  for (var i = min; i <= max; i++) {
    years.push(i);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state) {
      toast.error("Please come through my cart page");
      setTimeout(() => {
        navigate(CART);
      }, 1200);
    }
  }, []);
  const onSubmit = (val) => {
    setloader(true);
    let carts_id = { carts_id: storage.fetch.carts_id() };
    let total_amount = { net_amount: location?.state };
    let item = val;
    Object.assign(item, carts_id);
    Object.assign(item, total_amount);
    setTimeout(() => {
      stripePayment(item).then((res) => {
        setloader(false);
        if (res.data.status) {
          toast.success(res?.data?.message);

          setTimeout(() => {
            setloader(false);
            navigate("/confirmation", { state: true });
          }, 1000);
        } else {
          setTimeout(() => {
            res?.data?.errors?.map((item) => {
              return toast.error(item);
            });
          }, 1000);
        }
      });
    }, 10);
  };
  return (
    <>
      <LoaderComponent open={loader} />

      <Box
        sx={{
          pt: 5,
          pb: 10,
          display: "flex",
          justifyContent: "center",
          bgcolor: "#F3F0EE",
        }}>
        <Box
          sx={{
            pb: 7,
            width: { md: "60%", xs: "95%" },
            bgcolor: "white",
            borderRadius: "15px",
            boxShadow: 2,
            pt: 5,
          }}>
          <Typography display="block" sx={Style.typographyStyle}>
            Payment
          </Typography>

          <Typography
            display="block"
            sx={{
              fontSize: "20px",
              width: "70%",
              fontWeight: "400",
              lineHeight: "21px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#3D2E57",
              pt: 3,
              pl: { lg: 5, md: 4, sm: 2, xs: 2 },
            }}>
            Payment with Card
          </Typography>
          <form name="stripeForm" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 2,
                pt: 2,
              }}>
              <Box sx={Style.inputStyle}>
                <InputField
                  control={control}
                  variant="filled"
                  placeholder={"Enter name on card"}
                  type={"text"}
                  formlabel="Name on card"
                  name="name_on_card"
                  color="#A8A8A8"
                  size="18px"
                />
              </Box>
              <Box sx={Style.inputStyle}>
                <FormLabel
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#A8A8A8",
                  }}>
                  Card Number
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="card_number"
                  control={control}
                  sx={{
                    pb: 0,
                    pt: 0,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        pt: "10px",
                        endAdornment: (
                          <Box
                            sx={{
                              display: { xs: "none", md: "flex" },
                            }}>
                            <img src={VisaImg.img} alt="visa" height="30px" />
                          </Box>
                        ),
                      }}
                      error={!!errors.card_number}
                      helperText={errors?.card_number?.message}
                      color="primary"
                      inputProps={{
                        max: max,
                        min: min,
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      sx={{
                        width: "100%",
                        pb: 0,
                      }}
                    />
                  )}
                />
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    mt: 1,
                  }}>
                  <img src={VisaImg.img} alt="visa" height="30px" />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  justifyContent: "space-between",
                  pt: 2,
                  pl: { lg: 5, md: 4, sm: 2, xs: 2 },
                  pr: { lg: 5, md: 4, sm: 2, xs: 2 },
                  width: "90%",
                }}>
                <Box sx={Style.inputStyle1}>
                  <InputLabel
                    id="demo-simple-select-standard-label"
                    placeholder="Please Select Country"
                    sx={{
                      fontSize: "18px",
                      color: "#A8A8A8",
                      mb: -0.3,
                      fontWeight: 400,
                    }}>
                    Expiry Month
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      height: "60px",
                      color: "#A8A8A8",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        bgcolor: "rgba(0, 0, 0, 0.06)",
                        color: "A8A8A8",
                      },
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="card_exp_month"
                    label="Expiry Month"
                    defaultValue="select"
                    placeholder="MM"
                    onChange={(e) =>
                      setValue("card_exp_month", e.target.value, {
                        shouldValidate: true,
                      })
                    }>
                    <MenuItem
                      value="select"
                      sx={{
                        color: "A8A8A8",
                      }}>
                      MM
                    </MenuItem>
                    {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.card_exp_month && <p style={{ color: "#d32f2f", fontSize: "10px" }}>{errors.card_exp_month.message}</p>}
                </Box>
                <Box sx={Style.inputStyle1}>
                  <InputLabel
                    id="demo-simple-select-standard-label"
                    placeholder="Please Select Country"
                    sx={{
                      fontSize: "18px",
                      color: "#A8A8A8",
                      fontWeight: 400,
                      mb: -0.3,
                    }}>
                    Expiry Year
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      color: "#A8A8A8",
                      height: "60px",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        bgcolor: "rgba(0, 0, 0, 0.06)",
                      },
                    }}
                    defaultValue="select"
                    name="card_exp_year"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Expiry Year"
                    onChange={(e) =>
                      setValue("card_exp_year", e.target.value, {
                        shouldValidate: true,
                      })
                    }>
                    <MenuItem
                      hidden
                      value="select"
                      sx={{
                        color: "#A8A8A8",
                      }}>
                      YYYY
                    </MenuItem>
                    {years.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.card_exp_year && <p style={{ color: "#d32f2f", fontSize: "10px" }}>{errors.card_exp_year.message}</p>}
                </Box>
                <Box sx={Style.inputStyle1}>
                  <InputField
                    control={control}
                    variant="filled"
                    helperText={errors?.card_cvc?.message}
                    errors={!!errors.card_cvc}
                    type={"text"}
                    formlabel="CVC code"
                    required="*"
                    name="card_cvc"
                    color="#A8A8A8"
                    size="18px"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  pl: { lg: 5.5, md: 4, sm: 2, xs: 2 },
                  width: "100%",
                }}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    color: "#3D2E57",
                    borderColor: "#3D2E57",
                    fontSize: { lg: 16, md: 14, sm: 12, xs: 16 },
                    fontWeight: "700",
                    borderRadius: "15px",
                    width: { xs: "50%", md: "25%" },
                    borderWidth: 1.5,
                    textTransform: "none",
                  }}>
                  Pay Now ${location?.state}
                  <ArrowForwardIosIcon fontSize="20" />
                </Button>
              </Box>
            </Box>
          </form>
          <Toastify />
        </Box>
      </Box>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    stripePayment: (item) => dispatch(stripePayment(item)),
  };
}
export default connect(null, mapDispatchToProps)(Payment);
const VisaImg = {
  img: require("../../assets/visacards.png"),
};
export const Style = {
  typographyStyle: {
    fontSize: "35px",
    fontWeight: "700",
    lineHeight: "42px",
    color: "#3D2E57",
    pl: { lg: 5, md: 4, sm: 2, xs: 2 },
  },
  inputStyle: {
    pl: { lg: 5, md: 4, sm: 2, xs: 2 },
    width: "90%",
    mb: 2,
  },
  inputStyle1: {
    width: { md: "30%", xs: "100%" },
    mb: 2,
  },
};
