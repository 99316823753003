import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { CATEGORIES } from "../routes/ConstURL";
const Confirmation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box sx={style.mainBox}>
        <Box sx={style.innerBox}>
          <Box>
            <img alt="img" height="100px" width="100px" src={require("../../assets/Group 768.png")} />
          </Box>
          <Typography
            sx={{
              width: { xs: "90%", md: "50%" },
              fontWeight: "700",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              p: 3,
              height: {
                xs: "45px",
                sm: "40px",
                md: "40px",
                lg: "60px",
                xl: "60px",
              },
            }}>
            Your order has been successfully placed
          </Typography>
          <Button
            onClick={() => navigate(CATEGORIES)}
            sx={{
              width: { xs: "50%", md: "40%" },
              border: "1px solid #3D2E57",
              color: "#3D2E57",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              fontSize: { xs: "9px", md: "16px" },
              textTransform: "none",
              ml: 2,
              // mt: 2,
            }}
            variant="outlined">
            Continue Shopping
            <ArrowForwardIos sx={{ ml: 2, fontSize: { xs: 10, md: 20 } }} />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Confirmation;
const style = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#F3F0EE",
    pt: { xs: 1, md: 5 },
    width: "100%",
    height: 400,
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: { xs: "90%", md: "50%" },
    height: "90%",
    borderRadius: "15px",
    bgcolor: "white",
  },
};
