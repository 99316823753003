import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CardAmount from "../Custom/CardAmount";
import { EGIFT_VIDEO_RECIPIENT } from "../routes/ConstURL";

const img = require("../../assets/Group 640.png");

const Recordvideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <CardAmount
      headerText={"E-Gift card / select amount / Record a video message"}
      // amount={}
      record={true}
      heading={"Record video"}
      image={img}
      width={"100%"}
      bgcolor={"inherit"}
      onClick={() => navigate(EGIFT_VIDEO_RECIPIENT)}
    />
  );
};

export default Recordvideo;
