import React from "react";
import { storage } from "../../config/storage";
import Recipients from "../Custom/Recipients";
import {
  GREET_DELIVERY_AFTER_GIFT_LOGIN,
  GREET_DELIVERY_AFTER_GIFT_LOGOUT,
  GREET_DELIVERY_LOGIN,
  GREET_DELIVERY_LOGOUT,
} from "../routes/ConstURL";

const GreetingCardRecipients = () => {
  return (
    <Recipients
      headerText={"Greeting card / Add address"}
      heading={"Recipient details"}
      buttonText={"Continue"}
      bgcolor={"#F3F0EE"}
      inputBgcolor={"#F6F6F6"}
      validate={true}
      route={
        storage.fetch.additional_card() === "GREETING_CARD_STATE" && !storage.fetch.authToken()
          ? GREET_DELIVERY_LOGOUT
          : storage.fetch.additional_card() === "GREETING_CARD_STATE" && storage.fetch.authToken()
          ? GREET_DELIVERY_LOGIN
          : storage.fetch.additional_card() === "GREETING_CARD_STATE_WITH_GIFT" && storage.fetch.authToken()
          ? GREET_DELIVERY_AFTER_GIFT_LOGIN
          : GREET_DELIVERY_AFTER_GIFT_LOGOUT
      }
      recipient={true}
    />
  );
};
export default GreetingCardRecipients;
