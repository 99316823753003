import { Api } from "../../config/request";

export const requestUserRegister = async (data) => {
  return Api.PostRequest("/user/register", data);
};
export const requestForgotPassword = async (data) => {
  return Api.PostRequest("/user/forgot_password/generate_token", data);
};
export const requestMatchOtp = async (data) => {
  return Api.PostRequest("/user/forgot_password/match_otp", data);
};
export const requestResetPassword = async (data) => {
  return Api.PostRequest("/user/forgot_password/reset_password", data);
};
export const requestLogin = async (data) => {
  return Api.PostRequest("/login/email/password", data);
};
export const requestLoginWithPhone = async (data) => {
  return Api.PostRequest("/phone/otp/create", data);
};
export const requestLoginWithEmailOTP = async (data) => {
  return Api.PostRequest("/email/otp/create", data);
};
export const requestPhoneVerify = async (data) => {
  return Api.getPhoneNumber("/user/phone/phone_create_token", data);
};
export const requestEmailOtp = async (data) => {
  return Api.PostRequest("/user/email/confirm_token", data);
};
export const requestOtpVerify = async (data) => {
  return Api.getOtpVerified("/user/phone/phone_confirm_token", data);
};
export const requestEmailResendOtp = async (data) => {
  return Api.PostRequest("/user/email/create_token", data);
};
export const requestForgotResendOtp = async (data) => {
  return Api.PostRequest("/user/forgot_password/resend_otp", data);
};
export const requestverifyEmailOtp = async (data) => {
  return Api.PostRequest("/login/email/otp", data);
};
export const requestverifyPhoneOtp = async (data) => {
  return Api.PostRequest("/login/phone/otp", data);
};
export const requestGuestRegister = async (data) => {
  return Api.PostRequest("/login/phone/otp", data);
};
const RegisterApi = {
  requestUserRegister,
  requestForgotPassword,
  requestMatchOtp,
  requestResetPassword,
  requestLogin,
  requestLoginWithPhone,
  requestPhoneVerify,
  requestOtpVerify,
  requestEmailOtp,
  requestEmailResendOtp,
  requestForgotResendOtp,
  requestLoginWithEmailOTP,
  requestverifyEmailOtp,
  requestverifyPhoneOtp,
  requestGuestRegister
};
export default RegisterApi;
