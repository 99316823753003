import * as React from "react";
import Box from "@mui/material/Box";
import { CardContent, Typography } from "@mui/material";

import LinkButton from "../Custom/Buttons/LinkButton";
import { CATEGORIES, EGIFT_AMOUNT, GIFT_AMOUNT } from "../routes/ConstURL";

const GridCards = () => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Box sx={style.mainBox}>
        {cardData.map((cardItem, index) => (
          <Box sx={style.card} key={index}>
            <Box sx={style.imageBox}>
              <Box sx={style.image}>
                <img style={{ height: "100%", width: "100%" }} src={cardItem.img} alt="green iguana" />
              </Box>
            </Box>

            <CardContent sx={style.cardContent}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: { xs: "10px", sm: "0px", lg: "20px", md: "10px", xl: "50px" },
                  pl: { xs: "10px", sm: "0px", lg: "20px", md: "10px", xl: "50px" },

                  // height:'70%'
                }}>
                <Typography gutterBottom sx={style.text1} component="div">
                  {cardItem.text1}
                </Typography>
                <Typography variant="body2" sx={style.text2} color="text.secondary">
                  {cardItem.text2}
                </Typography>
              </Box>

              <Box
                sx={{
                  alignItems: "flex-end",
                  justifyContent: "center",
                  display: "flex",
                  fontWeight: 800,
                  borderRadius: "5px",
                  pt: "10px",
                  pb: "30px",
                  // width:"50%"
                  //  minHeight:"77px",
                }}>
                <LinkButton
                  ROUTE_URL={cardItem.id === 1 ? CATEGORIES : cardItem.id === 2 ? GIFT_AMOUNT : EGIFT_AMOUNT}
                  bgcolor={"#553B81"}
                  button_link_name={"SHOP NOW"}
                  textColor={"white"}
                  pr={"21px"}
                  pl={"21px"}
                  padding={"8.3px"}
                />
              </Box>
            </CardContent>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default GridCards;
const cardData = [
  {
    img: require("../../assets/img1.png"),
    text1: "Send a Greeting Card",
    text2: " Select the perfect greeting card, write your message and we'll post it for you .",
    id: 1,
  },
  {
    img: require("../../assets/img2.png"),
    text1: "Send a Gift Card",
    text2: " Select the perfect gift card, add a greeting card, write your message and we'll post it for you .",
    id: 2,
  },
  {
    img: require("../../assets/img3.png"),
    text1: " Email/ SMS an eGift Card",
    text2: " Go digital and email or SMS a digital gift card along with an eGreeting card or personalised video message .",
    id: 3,
  },
];
const style = {
  mainBox: {
    flexDirection: {
      xs: "column",
      sm: "row",
      md: "row",
      lg: "row",
      xl: "row",
    },
    display: "flex",
    // width: { xs: "95%", sm: "90%", md: "90%", lg: "90%", xl: "90%" },
    width: "auto",
    maxWidth: "1360px",
    alignItems: "center",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: "0px" },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: "0px" },
    justifyContent: "space-between",
  },
  card: {
    width: { xs: "100%", sm: "32%", md: "32%", lg: "32%", xl: "32%" },
    // height: { xl: "530px", sm: "542px", xs: "515px" },
    // pb:3,
    mb: 3,
    justifyContent: "center",
    borderRadius: "10px",
    bgcolor: "rgb(224,218,212,0.3)",
  },
  imageBox: {
    alignItems: "center",
    p: "50px",
    minHeight: { xs: "80px", md: "250px" },
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
  },
  image: {
    // height: { xs: 210, sm: 210, md: 220, lg: 250, xl: 250 },
    // mt: { xs: 2,sm: 6, md: 8, lg: 3, xl: 8 },
    // width: { xs: 206, sm: 220, md: 240, lg: 258, xl: 258 },
    maxWidth: "260px",
    height: "auto",
    minHeight: "100px",
    maxHeight: { sm: "200px", md: "260px" },
  },
  cardContent: {
    paddingTop: "15px",
    "&:last-child": {
      paddingBottom: "0px",
    },

    bgcolor: "#ECE7EB",
    // bgcolor:'red',
    minHeight: { md: "200px", sm: "250px", xs: "180px" },
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: "10px",
    justifyContent: "space-around",
  },
  text1: {
    fontWeight: "800",
    textAlign: "center",
    width: "100%",
    fontSize: { xs: 20, sm: 14, md: 18, lg: 24, xl: 26 },
    color: "#553B81",
    // minHeight:{sm:"40px"},
  },
  text2: {
    fontWeight: "500",
    color: "#553B81",
    width: { xl: "100%", lg: "95%", sm: "90%", xs: "100%", md: "100%" },
    height: { xs: "77px", sm: "40px", md: "80px", lg: "60px", xl: "60px" },
    mb: { xs: "", sm: "35px", md: "15px", lg: "15px", xl: "20px" },
    fontSize: { xs: 18, sm: 14, md: 18, lg: 18, xl: 18 },
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    // bgcolor:'green',
    // height: "60%",
  },
};
