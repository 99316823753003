import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Box, FormControl, FormLabel, Typography, Button, TextField, Backdrop, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { getResetPassword } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import Toastify from "../SnackBar/Toastify";
import { storage } from "../../config/storage";
import InputField from "../Custom/Input/InputField";
import { LOGIN } from "../routes/ConstURL";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  password: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
  confirm_password: yup
    .string()
    .required("Please enter your confirm password.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  password: "",
  confirm_password: "",
};

const ResetPassword = ({ getResetPassword }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const item = {};
  let user = storage.fetchForForgot.forgotToken();
  item.user = user;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit(model) {
    setLoading(true);
    let item = model;
    item.user = storage.fetchForForgot.forgotToken();

    getResetPassword(item).then((res) => {
      setLoading(false);
      if (res) {
        setTimeout(() => {
          navigate(LOGIN);
        }, 1000);
      }
    });
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F3F0EE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt: { xl: 18, md: 18, xs: 12 },
        }}>
        <Box
          sx={{
            maxWidth: "750px",
            width: { xs: "90%", sm: "80%", md: "65%", lg: "60%", xl: "50%" },
            mt: { sm: "10px", md: "20px", lg: "30px", xl: "30px", xs: "0px" },

            borderRadius: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "20px",
              xl: "20px",
            },
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form
            name="forgetPasswordForm"
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "90%",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "35px" },
                lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
                fontWeight: 700,
                textAlign: "center",
                color: "#3D2E57",
                mt: 5,
                mb: 2,
              }}>
              Reset Your Password
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <Typography
                sx={{
                  width: {
                    xs: "100%",
                    sm: "78%",
                    md: "68%",
                    lg: "80%",
                    xl: "80%",
                  },
                  fontSize: "18px",
                  lineHeight: "21px",
                  fontWeight: "400",
                  textAlign: "center",
                  color: "#3D2E57",
                  mb: 5,
                }}>
                Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.
              </Typography>
            </Box>

            <InputField
              control={control}
              helperText={errors?.password?.message}
              errors={!!errors.password}
              type={"password"}
              variant="filled"
              placeholder={"Enter new password here"}
              formlabel="New Password"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="password"
            />

            <Box sx={{ height: { xs: "25px", md: "35px" } }} />

            <InputField
              control={control}
              helperText={errors?.confirm_password?.message}
              errors={!!errors.confirm_password}
              variant="filled"
              type={"password"}
              placeholder={"Enter confirm password here"}
              formlabel="Confirm Password"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name="confirm_password"
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "60%",
                    lg: "40%",
                    xl: "40%",
                  },
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                    border: "1.5px solid #3D2E57",
                  },
                  color: "#3D2E57",
                  border: "1.5px solid #3D2E57",
                  mt: 4,
                  mb: 5,
                  borderRadius: "15px",
                  fontSize: { xs: "20px", md: "22px", lg: "24px" },
                  fontWeight: 500,
                  textTransform: "none",
                }}
                // color="warning"
                variant="outlined"
                type="submit">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getResetPassword: (item) => dispatch(getResetPassword(item)),
  };
}

export default connect(null, mapDispatchToProps)(ResetPassword);
