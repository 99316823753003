import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config/apiUrl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { storage } from "../../config/storage";
import InputField from "../Custom/Input/InputField";
import { connect } from "react-redux";
import { getForgotResendOtp, getMatchOtp } from "../../redux/action";
import Toastify from "../SnackBar/Toastify";

const schema = yup.object().shape({
  password_token: yup.string().required("Please enter OTP Here"),
});

const defaultValues = {
  password_token: "",
};
const VerifyEmailForPass = ({ getMatchOtp, getForgotResendOtp }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const item = {};
  let user_id = storage.fetchForForgot.forgotToken();
  item.user_id = user_id;
  const { control, formState, handleSubmit } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  // function onSubmit(model) {
  //       navigate("/accounts/myprofile")
  // }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (model) => {
    setLoading(true);
    let item = model;
    item.user_id = storage.fetchForForgot.forgotToken();
    getMatchOtp(item).then((res) => {
      if (res) {
        setTimeout(() => {
          setLoading(false);
          navigate("/resetpassword");
        }, 1000);
      } else {
        setLoading(false);
      }
    });
  };
  const resendFunction = () => {
    setLoading(true);
    getForgotResendOtp(item).then((res) => {
      if (res) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Box
      sx={{
        bgcolor: "#F3F0EE",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        pt:{xl:18,md:18,xs:12}
      }}
    >
      <Box
        sx={{
          maxWidth: "750px",
          width: { xs: "90%", sm: "80%", md: "65%", lg: "60%", xl: "50%" },
          mt: { sm: "10px", md: "20px", lg: "30px", xl: "30px", xs: "0px" },

          borderRadius: {
            xs: "10px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "20px",
          },
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
          // boxShadow: 2,
          // mt: { lg: "9%", md: "10%", sm: "13%", xs: "25%" },
          // mb: "10%",
          // pt: 5,
          // pb: "30px",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Toastify />
        <form
          onSubmit={handleSubmit(onSubmit)}
          schema={schema}
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "26px", md: "30px", lg: "35px" },
              lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
              fontWeight: 700,
              textAlign: "center",
              color: "#3D2E57",
              mt: 5,
              mb: 3,
            }}
          >
            OTP Verification
          </Typography>

          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "21px",
              fontWeight: "400",
              textAlign: "center",
              color: "#3D2E57",
              mb: 4,
            }}
          >
            Enter the OTP sent to your email
          </Typography>

          <InputField
            control={control}
            helperText={errors?.password_token?.message}
            errors={!!errors.password_token}
            type={"text"}
            placeholder={"Enter Email OTP"}
            variant="filled"
            formlabel="Enter Email OTP"
            size={{ xs: "20px", md: "22px", lg: "24px" }}
            color={"#333333"}
            name="password_token"
            required={"*"}
          />

          <Typography
            sx={{
              // fontSize: "1rem",
              // fontWeight: "400",
              // lineHeight: "31px",
              // letterSpacing: "0em",
              // color: "#3D2E57",
              // //  width:{lg:'60%',md:'70%',sm:'80%',xs:'90%'},
              // pb: 3,
              // pr: 3,
              textAlign: "right",
              fontSize: { xs: "16px", lg: "18px" },
              lineHeight: { xs: "19px", lg: "21px" },
              fontWeight: "400",
              color: "#3D2E57",
              mt: 2,
            }}
          >
            Don’t receive the OTP ?
            <Button
              sx={{
                fontSize: { xs: "16px", lg: "18px" },
                lineHeight: { xs: "19px", lg: "21px" },
                fontWeight: "400",
                color: "#FF8D2A",
                textDecoration: "none",
                textTransform: "none",
              }}
              onClick={() => resendFunction()}
            >
              Resend OTP
            </Button>
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                width: {
                  xs: "100%",
                  sm: "90%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%",
                },
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                  border: "1.5px solid #3D2E57",
                },
                color: "#3D2E57",
                border: "1.5px solid #3D2E57",
                mt: 4,
                mb: 5,
                borderRadius: "15px",
                fontSize: { xs: "20px", md: "22px", lg: "24px" },
                fontWeight: 500,
                textTransform: "none",
              }}
              // color="warning"
              variant="outlined"
              type="submit"
            >
              Verify & Proceed
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getMatchOtp: (item) => dispatch(getMatchOtp(item)),
    getForgotResendOtp: (item) => dispatch(getForgotResendOtp(item)),
  };
}

export default connect(null, mapDispatchToProps)(VerifyEmailForPass);
