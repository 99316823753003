import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Box, Typography, TextField, Select, MenuItem, Table, TableRow, TableCell, TableHead, TableBody, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomButton from "../Custom/Buttons/CustomButton";
import Pagination from "@mui/material/Pagination";
import { getOrders } from "../../redux/action";
import LoaderComponent from "../Loader/LoaderComponent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { MYORDER_DETAIL } from "../routes/ConstURL";

const MyOrder = ({ setAccountData, getOrders }) => {
  const [sortby, setsortby] = React.useState("");
  const [orderData, setOrderData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [search_val, setSearch_val] = React.useState("");
  const [perv_search_val, setPerv_Search_val] = React.useState("");
  const [empty, setEmpty] = React.useState(false);
  const [asc, setAsc] = React.useState(false);
  const [col_1, setCol_1] = React.useState(false);
  const [col_2, setCol_2] = React.useState(false);
  const [col_3, setCol_3] = React.useState(false);
  const [col_4, setCol_4] = React.useState(false);
  const [sortConfig, setSortConfig] = React.useState();
  let length = 4;
  let data = {
    start: start,
    length: length,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);
  const getData = () => {
    getOrders(data).then((res) => {
      setLoading(false);
      if (res.data.data?.order_list?.length === 0) {
        setEmpty(true);
        setPages(1);
      }
      if (res.data.status) {
        setEmpty(false);
        setPages(res.data.data.pages);
        const result = res.data.data.orders;
        setOrderData(result);
      }
    });
  };
  const requestSort = (name) => {
    setLoading(true);
    if (name === "order_name") {
      setCol_1(true);
      setCol_2(false);
      setCol_3(false);
      setCol_4(false);
    } else if (name === "total_amount") {
      setCol_2(true);
      setCol_1(false);
      setCol_3(false);
      setCol_4(false);
    } else if (name === "status") {
      setCol_3(true);
      setCol_1(false);
      setCol_2(false);
      setCol_4(false);
    } else if (name === "created_at") {
      setCol_4(true);
      setCol_1(false);
      setCol_2(false);
      setCol_3(false);
    }
    let sort_by = "asc";
    if (sortConfig && sortConfig.sort_column == name && sortConfig.sort_by === "asc") {
      sort_by = "desc";
    } else {
      sort_by = "asc";
    }
    if (sort_by == "asc") {
      setAsc(true);
    } else {
      setAsc(false);
    }
    setSortConfig({ sort_column: name, sort_by });

    let col_name = { sort_column: name };
    let col_type = { sort_by: sort_by };
    Object.assign(data, col_name, col_type);
    let sort = { order_status: sortby };
    if (sortby === "") {
    } else {
      Object.assign(data, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal, sort);
    }
    getData();
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.sort_column === name ? sortConfig.sort_by : undefined;
  };
  const handleChange = (event) => {
    setLoading(true);
    setsortby(event?.target?.value);
    let sort = { order_status: event.target.value };
    setStart(0);
    setPage(1);
    data = {
      start: 0,
      length: length,
    };
    if (sortConfig) {
      Object.assign(data, sortConfig);
    }
    if (event.target.value === "") {
    } else {
      Object.assign(data, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal, sort);
    }
    getData();
  };
  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    if (sortConfig) {
      Object.assign(data, sortConfig);
    }
    let sort = { order_status: sortby };
    if (sortby === "") {
    } else {
      Object.assign(data, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal, sort);
    }

    getData();
  };
  const handleSearch = (e) => {
    setSearch_val(e.target.value);
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") onSubmit(search_val);
    if (e.key === "Backspace" && perv_search_val !== "" && search_val.length === 0) {
      setSearch_val("");
      onSubmit("");
    }
  };
  const onSubmit = () => {
    if ((search_val !== "" && search_val.trim().length !== 0) || perv_search_val !== "") {
      setPerv_Search_val(search_val);
      setLoading(true);
      let sort = { order_status: sortby };
      setStart(0);
      setPage(1);
      data = {
        start: 0,
        length: length,
      };
      let searchVal = { search_val: search_val };
      if (sortConfig) {
        Object.assign(data, sortConfig);
      }
      Object.assign(data, searchVal, sort);
      getData();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        pb: 5,
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
      }}>
      <LoaderComponent open={loading} />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          height: "100%",
          borderRadius: 4,
          justifyContent: "center",
        }}>
        <Typography sx={Style.typographyStyle}>My Orders</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            mb: 5,
            pl: 3,
            pr: 3,
          }}>
          <LoaderComponent open={loading} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "100%", md: "65%", lg: "55%" },
              // bgcolor:"red"
            }}>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon color="inherit" />,
                sx: {
                  borderRadius: "5px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #3D2E57",
                  },
                  width: { xs: "45vw", sm: "60vw", md: "250px", lg: "334px" },
                  height: "42px",
                },
              }}
              value={search_val}
              onChange={handleSearch}
            />
            <CustomButton
              variant="outlined"
              default_bgcolor={"white"}
              item={"Search"}
              size={"large"}
              textColor="#3D2E57"
              fontSize={"16px"}
              lineHeight={"19px"}
              fontWeight={"700"}
              textTransform={"none"}
              borderRadius={"15px"}
              pl="30px"
              pr="30px"
              pt={"7px"}
              pb={"7px"}
              height={"33px"}
              width={"110px"}
              // ml={{xs:5}}
              border={"1px solid #3D2E57"}
              onclick_border={"1px solid #3D2E57"}
              onClickButton={search_val ? () => onSubmit() : undefined}
              // onKeyDown={
              //   search_val ? (e) => e.key === "Enter" && onSubmit : undefined
              // }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "30%" },
              alignItems: "center",
              justifyContent: { md: "flex-end" },
              mt: { xs: 4, md: 0 },
            }}>
            <Typography style={Style.typographyStyle1}> Sort: </Typography>
            <Select
              value={sortby}
              onChange={handleChange}
              displayEmpty
              SelectDisplayProps={{ style: { padding: 3, marginLeft: "5px" } }}
              MenuProps={{ disableScrollLock: true }}
              sx={{
                height: "42px",
                minWidth: "129.29px",
                fontSize: "18px",
                fontWeight: "400",
                color: "#000000",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #3D2E57",
                },
              }}>
              <MenuItem sx={Style.typographyStyle2} value="">
                All
              </MenuItem>
              <MenuItem sx={Style.typographyStyle2} value={0}>
                Pending
              </MenuItem>
              <MenuItem sx={Style.typographyStyle2} value={1}>
                Inprogress
              </MenuItem>
              <MenuItem sx={Style.typographyStyle2} value={2}>
                Completed
              </MenuItem>
            </Select>
          </Box>
        </Box>
        {empty === true && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}>
            <Typography sx={Style.typographyStyle}>No Order Found</Typography>
          </Box>
        )}
        <Box sx={{ overflow: "auto", ml: { xs: 1, sm: 3 }, mr: { xs: 1, sm: 3 } }}>
          {empty === false && (
            <Table sx={{ minWidth: { xs: "775px", md: "100%" } }}>
              <TableHead sx={{ bgcolor: "#FFE7CA" }}>
                <TableCell sx={Style.tableHeader} onClick={() => requestSort("order_name")} className={getClassNamesFor("order_name")}>
                  <Box
                    sx={{
                      display: "flex",
                    }}>
                    Order number
                    {col_1 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                        {asc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={Style.tableHeader}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                    onClick={() => requestSort("total_amount")}
                    className={getClassNamesFor("total_amount")}>
                    Price
                    {col_2 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                        {asc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={Style.tableHeader}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                    onClick={() => requestSort("status")}
                    className={getClassNamesFor("status")}>
                    Status
                    {col_3 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                        {asc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={Style.tableHeader}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                    onClick={() => requestSort("created_at")}
                    className={getClassNamesFor("created_at")}>
                    Order date
                    {col_4 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}>
                        {asc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                      </Box>
                    )}
                  </Box>
                </TableCell>

                <TableCell sx={Style.tableHeader}>Action</TableCell>
              </TableHead>

              <TableBody>
                {orderData.map((row, index) => (
                  <TableRow key={row.orders_id}>
                    <TableCell sx={Style.tableCell}>
                      {loading && <Skeleton variant="rectangular" height={50} />}
                      {!loading && row.order_number}
                    </TableCell>
                    <TableCell sx={Style.tableCell}>
                      {loading && <Skeleton variant="rectangular" height={50} />}
                      {!loading && "$ " + row.total_amount}
                    </TableCell>
                    <TableCell
                      sx={Style.tableCell}
                      style={{
                        color: row?.status.toLowerCase === "complete" ? "#219653" : "#FF8D2A",
                      }}>
                      {loading && <Skeleton variant="rectangular" height={50} />}
                      {!loading && row.status}
                    </TableCell>
                    <TableCell sx={Style.tableCell}>
                      {loading && <Skeleton variant="rectangular" height={50} />}
                      {!loading && row.created_at}
                    </TableCell>
                    <TableCell sx={Style.tableCell}>
                      {loading && <Skeleton variant="rectangular" height={50} />}
                      {!loading && (
                        <Link
                          to={MYORDER_DETAIL}
                          state={{
                            id: row.orders_id,
                          }}
                          style={Style.linkButton}>
                          View
                          <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 3,
          }}>
          {pages > 1 && <Pagination count={pages} page={page} boundaryCount={2} onChange={handlePageChange} />}
        </Box>
      </Box>
    </Box>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getOrders: (data) => dispatch(getOrders(data)),
  };
}
export default connect(null, mapDispatchToProps)(MyOrder);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: { xs: "24px", sm: "35px" },
    fontWeight: { xs: "400", sm: "700" },
    lineHeight: { xs: "29px", sm: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
    p: 3,
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: "400",
    marginRight: "10px",
  },
  typographyStyle2: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    color: "#000000",
  },
  tableHeader: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: { xs: "18px", md: "22px" },
    lineHeight: { xs: "21px", md: "26px" },
    fontWeight: 400,
    color: "#3D2E57",
    cursor: "pointer",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: { xs: "16px", md: "18px" },
    lineHeight: { xs: "19px", md: "21px" },
    fontWeight: 400,
    color: "#000000",
  },
  linkButton: {
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "15px",
    border: "1px solid #000000",
    color: "#000000",
    fontSize: "16px",
    lineHeight: "19px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    cursor: "pointer",
    paddingLeft: "10px",
    paddingRight: "10px",
    maxWidth: "79px",
  },
};
