import { Box } from "@mui/material";
const BodyTitle = ({ children }) => {
  return <Box sx={style.headerBox}>{children}</Box>;
};

export default BodyTitle;
const style = {
  headerBox: {
    width:'100%',
    ml: "auto",
    mr: "auto",
    bgcolor: "#F9F7F6",
    pt: {
      xs: "75px",
      sm: "80px",
      md: "110px",
      lg: "130px",
      xl: "130px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
