/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { storage } from "../../config/storage";
import { getCategoryName } from "../../redux/action";
import Categories from "../Custom/Categories";
import LoaderComponent from "../Loader/LoaderComponent";
import { CARDLISTING } from "../routes/ConstURL";

const GreetingCategories = ({ getCategoryName }) => {
  const location = useLocation();
  const trimmedURL = location.pathname.slice(0, 10);
  const newState = useSelector((state) => state?.User?.categoryList);
  const [dataEmpty, setDataEmpty] = React.useState(false);
  const [load, setLoad] = useState(true);
  const [start, setStart] = useState(1);

  let length = 8 * start;
  let data = {
    start: 0,
    length: length,
  };

  const handleScrolling = () => {
    setStart(start + 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (trimmedURL === "/categorie") {
      storage.set.additional_card("GREETING_CARD_STATE");
    }
    handleDataFetch();
  }, [start]);
  const handleDataFetch = () => {
    setDataEmpty(true);
    getCategoryName(data).then((res) => {
      if (res?.data?.status) {
        setDataEmpty(false);
        setLoad(false);
      } else {
        setLoad(false);
      }
    });
  };

  return (
    <>
      <LoaderComponent open={load} />
      <Categories newState={newState} dataEmpty={dataEmpty} heading={"Categories"} link={CARDLISTING} next={handleScrolling} />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getCategoryName: (data) => dispatch(getCategoryName(data)),
  };
}
export default connect(null, mapDispatchToProps)(GreetingCategories);
