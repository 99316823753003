import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardMedia,
  Typography,
} from "@mui/material";
import CustomButton from "../Custom/Buttons/CustomButton";

const BoxContainer1 = () => {
  return (
    <Box sx={style.mainBox}>
      <Box sx={style.wrapBox}>
        <Box sx={style.card}>
          <Box sx={style.cardBox1}>
            <Box sx={style.cardBox2}>
              <Box sx={style.cardBox3}>
                <Typography variant="h2" sx={style.text1}>Paying it forward</Typography>
                <Typography sx={style.text2}>
                  $2 from every order is donated to help and support our
                  community.
                </Typography>

                <CustomButton
                  variant="contained"
                  fontSize={'16px'}
                  default_bgcolor={"#FFFFFF"}
                  onclick_bgcolor={"#FFFFFF"}
                  item={"Learn More"}
                  borderRadius={"6px"}
                  textColor="#38B99F"
                  pt={"6px"}
                  // pb={"10px"}
                  pr={"27px"}
                  pl={"27px"}
                  fontWeight={"800"}

                />
              </Box>
            </Box>
            <Box sx={style.imageBox}>
              <Box
                sx={
                  {
                    maxWidth:'472px',
                    
                  }
                }
              >
                <CardMedia
                  component="img"
                  // height="100%"
                  width="100%"
                  image={require("../../assets/heartimg.png")}
                  alt="green iguana"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BoxContainer1;
const style = {
  mainBox: { display: "flex", width: "100%", justifyContent: "center" },
  wrapBox: {
    flexDirection: "row",
    
    display: "flex",
    maxWidth: "1360px",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    width: "100%",
 pt:3,
    justifyContent: "space-between",
  },
  card: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    bgcolor: "#38B99F",
    borderRadius: 3,
   pt:'80px',
   pb:'80px',
    justifyContent: "center",
  },
  cardBox1: {
    flexDirection: {
      xs: "column-reverse",
      sm: "column-reverse",
      md: "row",
      lg: "row",
      xl: "row",
    },
    // pt:'100px',
    // pb:'100px',
   
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // height: {xs:"70%",sm:"90%"},
  },
  cardBox2: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    height: "90%",
  },
  cardBox3: {
    width: "100%%",
    alignItems: "center",
    display: "flex",
    height: "70%",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  text1: {
    color: "white",
    fontSize:{xs:'35px',sm:'35px',md:'40px',lg:'50px',xl:'50px'},
    lineHeight: '50px',
    mb:'20px',
    fontWeight: "900",
    width: "100%",
    letterSpacing: '-0.015em',
    textAlign: "center",
  },
  text2: {
    color: "white",
    fontSize: {sm:'18px',xs:'19px'},
    fontWeight: "500",
    lineHeight: '26px',
    letterSpacing: '-0.015em',
    textAlign: "center",
    width: "80%",
    mb:2
  },
  button: {
    fontSize: {xs:10,sm:15},
    bgcolor: "white",
    color: "#38B99F",
  },
  imageBox: {
    // bgcolor:'blue',
    width: "55%",
    height: "90%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
};
