import { Api } from "../../config/request";

export const requestCategoryList = async (data) => {
  return Api.getListRequest("/products/categories/categoryList", data);
};
export const requestCardListing = async (data) => {
  return Api.getListRequest("/products/greetingCards/greetingCardsList", data);
};
export const requestCarddetail = async (data) => {
  return Api.getListRequest("/products/greetingCards/cardDetails", data);
};
export const requestAddressList = async (data) => {
  return Api.getListRequest('/user/addresses', data);
};
export const requestDeleteAddress = async (data) => {
  return Api.getListRequest('/remove/user/address', data);
};
export const requestSaveAddress = async (data) => {
  return Api.getListRequest('/save/user/address', data);
};
export const requestUserAddress = async (data) => {
  return Api.getListRequest('/get/user/address', data);
};
export const requestMessageCardDetails = async (data) => {
  return Api.getListRequest("/get/add/your/message/greeting/cards", data);
};
export const requestCheckMsg = async (data) => {
  return Api.getListRequest("/validate/add/your/message/greeting/cards", data);
};

export const requestCheckrecipent = async (data) => {
  return Api.getListRequest("/validate/recipient/address", data);
};
export const requestCountryData = async (data) => {
  return Api.getListRequest("/get/all/countries", data);
};

  const CategoryApi = {
  requestCategoryList,
  requestCardListing,
  requestCarddetail,
  requestMessageCardDetails,
  requestCheckMsg,
  requestCheckrecipent,
  requestCountryData,
  requestAddressList,
  requestDeleteAddress,
  requestSaveAddress,
  requestUserAddress,
};
export default CategoryApi;
