import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AccountData } from "../../../config/mockData";
import { useLocation, useNavigate } from "react-router-dom";

const SideComponent = ({ setAccountData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let URL = location.pathname;
  const trimmedURL = URL.slice(0, 20);
  if (
    trimmedURL === "/myaccount/myorder/m" 
  ) {
    AccountData[1].status = true;
  } else if (
    trimmedURL === "/myaccount/addnewadd" ||
    trimmedURL === "/myaccount/editaddre"
  ) {
    AccountData[3].status = true;
  } else {
    AccountData[1].status = false;
    AccountData[3].status = false;
  }

  const Active = (item) => {
    navigate(item.routes);
    setAccountData([...AccountData]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        pb: 1
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%" },
          borderRadius: "10px",
          height: "40%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <List
          sx={{
            borderRadius: "10px",
            width: "100%",
            bgcolor: { md: "white" },
          }}
        >
          {AccountData.slice(0, 4).map((item, index) => (
            <ListItem disablePadding 
            key={index}
            >
              <ListItemButton
                disableTouchRipple
                onClick={() => Active(item)}
                sx={{
                  width: "30%",
                  fontSize: "30px",
                  fontWeight: 500,
                  pl: { md: 5 },
                  mb: { xs: 1, md: 0 },
                  borderRadius: "5px",
                  bgcolor: {
                    xs:
                      item.routes === location.pathname || item.status
                        ? "#3D3264"
                        : "#3D2E57",
                    md: "white",
                  },
                  "&:hover": {
                    bgcolor: {
                      xs:
                        item.routes === location.pathname || item.status
                          ? "#3D3264"
                          : "#3D2E57",
                      md: "white",
                    },
                  },
                }}
              >
                <ListItemText
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "26px",
                    color: {
                      xs: "white",
                      md:
                        item.routes === location.pathname || item.status
                          ? "#FF8D2A"
                          : "#3D2E57",
                    },
                    bgcolor: {
                      xs:
                        item.routes === location.pathname || item.status
                          ? "#3D3264"
                          : "#3D2E57",
                      md: "white",
                    },
                  }}
                  primaryTypographyProps={{
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                  primary={item.val}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
export default SideComponent;
