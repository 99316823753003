import * as React from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography, Skeleton, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useLocation } from "react-router-dom";
import { storage } from "../../config/storage";
import { UserContext } from "../home/main";
import "../header/Header.css";

const CardDetailPage = ({ headerParentText, headerChildText, headeChild1, viewDetail, newState, goForward, goBack, handleClick }) => {
  const prev_id = storage.fetch.prev_id();
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  let photos = newState?.card_detail?.files;
  const nextPhoto = () => {
    if (photos.length > 1) {
      if (count < 2) {
        setCount(count + 1);
      } else {
        setCount(0);
      }
    }
  };
  const prevPhoto = () => {
    if (photos.length > 1) {
      if (count > 0) {
        setCount(count - 1);
      }
    }
  };
  return (
    <Box
      sx={{
        bgcolor: "#F3F0EE",
        pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        // width: "100%",

        ml: "auto",
        mr: "auto",
      }}>
      <Box sx={style.detailsContent}>
        <Box sx={style.photosBox}>
          <Stack
            direction={{ xs: "row", sm: "column" }}
            sx={{
              justifyContent: { xs: "space-between", sm: "space-between" },
              alignItems: "center",
              minHeight: { xs: 130, sm: 320, md: 380, lg: 420 },
              order: { xs: 2, sm: 1 },
              width: { xs: "90%", sm: "12%", md: "8.7%" },
            }}>
            {newState?.length === 0 &&
              [1, 2, 3].map((item, index) => (
                <Box sx={{ width: { xs: "50%", md: "100%" }, height: "120px" }} key={index}>
                  <Skeleton sx={{ borderRadius: "10px" }} variant="rectangular" width={"90%"} height={120} />
                </Box>
              ))}
            {photos &&
              photos?.slice(0, 3).map((card, index) => (
                <Box sx={style.item1} key={index}>
                  <img alt="logo" className="cardDetailPagePics" src={card} />
                </Box>
              ))}
          </Stack>
          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              order: { xs: 1, sm: 2 },
              width: { xs: "100%", sm: "30%", md: "31%" },
            }}>
            {newState?.length === 0 && <Skeleton variant="rectangular" width={"420px"} sx={{ borderRadius: "10px" }} height={"420px"} />}
            {photos && photos && (
              <Box sx={style.item2}>
                <IconButton onClick={() => prevPhoto()}>
                  <ArrowBackIosNewIcon
                    sx={{
                      color: "gray",
                      fontSize: { sm: 20, md: 30, lg: 40 },
                    }}
                  />
                </IconButton>

                <img alt="logo" className="cardDetailMainPhoto" src={photos && photos[count]} />
                <IconButton onClick={() => nextPhoto()}>
                  <ArrowForwardIosIcon
                    sx={{
                      color: "gray",
                      fontSize: { sm: 20, md: 30, lg: 40 },
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>

          <Box sx={style.contentTextBox}>
            {newState?.length === 0 && <Skeleton variant="rectangular" width={"100%"} height={"70px"} sx={{ borderRadius: "10px" }} />}
            <Typography sx={style.text1}>{newState?.card_detail?.title}</Typography>
            {newState?.length === 0 && (
              <Skeleton variant="rectangular" width={"100%"} height={"50px"} sx={{ mb: 5, borderRadius: "10px" }} />
            )}
            {newState?.card_detail?.price && <Typography sx={style.text2}>${newState?.card_detail?.price}</Typography>}

            {newState?.length === 0 && (
              <>
                <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={"100%"} height={"160px"} />
                <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={"100%"} height={"60px"} />
              </>
            )}
            {newState?.card_detail && (
              <>
                <Typography sx={style.text3}>{newState?.card_detail?.description}</Typography>
                <Box sx={style.btnBox}>
                  <Link
                    // to={`${goForward}/${newState?.card_detail?.card_id}`}
                    to={goForward}
                    state={{
                      from: location?.state?.from,
                      headerVal1: headeChild1,
                      headerVal2: headerChildText,
                      URL: location?.state?.URL,
                      prevURl: location.pathname,
                    }}
                    className="detailpageButtons"
                    variant="outlined">
                    Add your message
                    <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
                  </Link>
                  <Link to={goBack} variant="outlined" className="detailpageButtons">
                    <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
                    Back to cards
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box sx={style.header2Box}>
          <Typography sx={style.headertxt2}>{newState?.more_cards?.length > 0 ? "You May also like" : ""}</Typography>
          <Typography sx={style.headertxt3}>{newState?.more_cards?.length > 0 ? "Related" : ""}</Typography>
        </Box>
        <Box
          sx={{
            // bgcolor:'red',
            width: "100%",
          }}>
          <Stack direction={{ xs: "column", sm: "row" }} sx={style.stack2}>
            {newState?.length === 0 &&
              [1, 2, 3, 4].map((item, index) => (
                <Box sx={style.contentBox} key={index}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: "10px",

                      pt: "45px",
                      pb: "45px",
                      pr: "13px",
                      pl: "13px",
                    }}
                    width={"100%"}
                    height={330}
                  />

                  <Skeleton
                    height={170}
                    sx={{
                      borderRadius: "10px",
                      pr: "13px",
                      pl: "13px",
                    }}
                    width={"100%"}
                    animation="wave"
                  />
                </Box>
              ))}

            {newState &&
              newState?.more_cards?.map((card, index) => (
                <Box sx={style.contentBox} key={index}>
                  <Box sx={style.item3}>
                    <img alt="logo" className="listCardImage" src={card.file_path} />
                  </Box>
                  <Box sx={style.textBox}>
                    <Typography sx={style.moreText}>{card.title}</Typography>
                    <Typography sx={style.moreText}>$ {card.price}</Typography>
                    <Box
                      sx={{
                        // bgcolor:'red',
                        display: "flex",
                        justifyContent: { xs: "center", sm: "left" },
                      }}>
                      <Box sx={style.linkButtonBox}>
                        <Link onClick={() => handleClick(card.card_id)} to={`${viewDetail}/${card.card_id}`} className="cardLinkButton">
                          View detail
                          <ArrowForwardIosIcon sx={{ ml: "5px", fontSize: 15 }} />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CardDetailPage;
const style = {
  detailsContent: {
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    // pl: { xs: 0, md: 8, lg: 1 ,xl:0},
    // pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
  photosBox: {
    display: "flex",
    justifyContent: { xs: "center", sm: "space-between" },
    flexWrap: "wrap",
    // flexDirection: {
    //   xs: "column",
    //   sm: "column",
    //   md: "row",
    //   lg: "row",
    //   xl: "row",
    // },
    pt: 5,
    width: "100%",
  },
  item1: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: { xs: "25%", sm: "100%", md: "100%", xl: "100%" },
    // maxHeight: "120px",
    borderRadius: { xs: "10px", sm: "5px" },
    // bgcolor:'blue',
    pt: 1.45,
    border: "1px solid rgba(61, 46, 87, 0.4)",
    pb: 1.45,
    bgcolor: "#FFFFFF",

    // width: {sm:80,xs:60},
  },
  item2: {
    maxWidth: { xs: "99%", sm: "350px", md: "380px", lg: "416px" },
    width: "100%",
    maxHeight: { xs: "450px", sm: "350px", md: "380px", lg: "418px" },
    borderRadius: { xs: "20px", sm: "10px" },
    ml: { xs: 0, sm: 1, md: 0 },
    border: "1px solid rgba(61, 46, 87, 0.4)",
    mr: { xs: 0, sm: 0, md: 0 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    bgcolor: "#FFFFFF",
  },
  contentTextBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    // alignItems:{xs:'center',md:"space-between"},
    // ml: "10px",
    pl: { xs: 0, sm: 2 },
    width: { xs: "100%", sm: "50%", md: "55%", lg: "55%" },
    order: 3,
  },
  text1: {
    fontWeight: "700",
    color: "#3D2E57",

    fontSize: { xs: "35px", sm: "22px", md: "25px", lg: "44px" },
    width: { md: "98%", xs: "100%", lg: "80%" },
    mt: { xs: "10px", sm: 0 },
  },
  text2: {
    fontWeight: "400",
    fontSize: { xs: "35px", sm: "22px", md: "30px", lg: "35px" },
    width: "80%",
    lineHeight: "42px",
    mt: { xs: "10px", sm: 0 },
  },
  text3: {
    fontWeight: "300",
    fontSize: { xs: "18px", sm: "15px", md: "18px" },
    width: { xs: "100%", sm: "100%", md: "80%" },
    display: "flex",
    mt: { xs: "10px", sm: 0 },
  },
  btnBox: {
    display: "flex",
    justifyContent: "space-between",
    width: { xs: "93%", sm: "80%", md: "59%", lg: "55%", xl: "50%" },
    mt: { xs: "20px", sm: 0 },
    pl: { xs: "1px", sm: 0 },
  },

  header2Box: {
    display: "flex",
    width: "100%",
    pt: "30px",
    pb: "30px",
  },
  headertxt2: {
    display: {
      xs: "none",
      sm: "flex",
    },
    fontWeight: "700",
    fontSize: { xs: 25, md: 35 },
  },
  headertxt3: {
    display: {
      xs: "flex",
      sm: "none",
      md: "none",
      lg: "none",
      xl: "none",
    },
    fontWeight: "700",
    fontSize: { xs: 32 },
  },
  stack: {
    display: "flex",
    flexWrap: "wrap",
    // height: "100%",
    // ml: { xs: 0, sm: -2, md: -4.5, lg: -4, xl: -4 },

    // mr: { xs: 0, sm: -2, md: -4.5, lg: -4, xl: -4 },
    // pl: { xs: 1, sm: 6, md: 8, lg: 8 ,xl:8},
    mb: 3,
  },
  stack2: {
    display: "flex",
    flexWrap: "wrap",
    // height: "100%",
    ml: { xs: 0, sm: -2, md: -4.5, lg: -4, xl: -4 },
    mr: { xs: 0, sm: -2, md: -4.5, lg: 0, xl: -4 },
    // pl: { xs: 1, sm: 6, md: 8, lg: 8 ,xl:8},
    mb: 3,
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    mt: { xs: 2, sm: 5, md: 8, lg: 5, xl: 5 },
    pl: { xs: "%", sm: "3%", md: "3.5%", lg: "2.7%", xl: "2%" },
    pr: { xs: "1%", sm: "3%", md: "3.5%", lg: "2%", xl: "2%" },
    maxWidth: { xs: "92%", sm: "18%", md: "17.2%", lg: "20.25%", xl: "20.25%" },
  },

  item3: {
    width: "100%",
    maxHeight: {
      xs: "max-content",
      sm: "150px",
      md: "200px",
      lg: "300px",
      xl: "300px",
    },
    pt: "45px",
    pb: "45px",
    pr: "13px",
    pl: "13px",
    border: "1px solid rgba(61, 46, 87, 0.4)",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "#FFFFFF",
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    pl: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    pr: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    pb: 2,
  },
  moreText: {
    pt: 2,
    fontWeight: "400",
    color: "#3D2E57",
    fontSize: { xs: "24px", sm: "16px", md: "20px", lg: "24px", xl: "24px" },
    textAlign: "center",
    pb: 1,
  },
  linkButtonBox: {
    border: "1px solid #3D2E57",
    borderRadius: "15px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    // width: { md: "70%", xs: "50%" },
    fontSize: "16px",
    textAlign: "center",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    paddingLeft: "25px",
    paddingRight: "10px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    width: "100%",
    height: "100%",
  },
};
