import React from "react";
import { useNavigate } from "react-router-dom";
import AddingCards from "../Custom/AddingCards";

const img = require("../../assets/Group 592.png");

const GiftAddCard = () => {
  const [value, setValue] = React.useState("Add a greeting card");

  const navigate = useNavigate();
  return (
    <AddingCards
      heading={"Add greeting card"}
      value={value}
      setValue={setValue}
      pt={"100px"}
      pb={"100px"}
      width={"392px"}
      text1={"Add a greeting card"}
      text2={"Not Today"}
      bgcolor={"#3DD5B7"}
      image={img}
    />
  );
};

export default GiftAddCard;
