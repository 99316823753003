import React, { useContext, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Toastify from "../SnackBar/Toastify";
import { toast } from "react-toastify";
import { UserContext } from "../home/main";
import { Box, Button, Typography, FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useLocation } from "react-router-dom";
import { storage } from "../../config/storage";
import {
  EGIFT_GIF,
  EGIFT_VIDEO,
  GIFT_CATEGORIES,
  GIFT_ONLY_ADDRESS_LOGIN,
  GIFT_ONLY_RECIPIENT_LOGOUT,
  GREETING_ADDRESS,
  GREET_ADD_AMOUNT,
  GREET_RECIPIENT_LOGOUT,
} from "../routes/ConstURL";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddingCards = ({ headerText, image, text1, bgcolor, width, pt, pb, heading, text2, value, setValue }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const trimmedURL = location.pathname.slice(0, 10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (value === "Record a video message") {
      navigate(EGIFT_VIDEO, { state: { value: "E_gift_cards" } });
    } else if (value === "Select an animated e-greeting card GIF") {
      navigate(EGIFT_GIF);
    } else if (value === "Add a greeting card") {
      storage.set.additional_card("GREETING_CARD_STATE_WITH_GIFT");
      navigate(GIFT_CATEGORIES, { state: "Greeting Gift cards" });
    } else if (value === "Add a gift card") {
      storage.set.additional_card("GIFT_CARD_STATE_WITH_GREETING");
      navigate(GREET_ADD_AMOUNT, {
        state: "Greeting Gift cards",
      });
    } else if (value === "Not Today") {
      if (trimmedURL === "/giftcarda") {
        storage.set.additional_card("GIFT_CARD_STATE");
        navigate(storage.fetch.authToken() ? GIFT_ONLY_ADDRESS_LOGIN : GIFT_ONLY_RECIPIENT_LOGOUT, {
          state: "Gift cards",
        });
      } else {
        storage.set.additional_card("GREETING_CARD_STATE");
        navigate(storage.fetch.authToken() ? GREETING_ADDRESS : GREET_RECIPIENT_LOGOUT, {
          state: "Greeting cards",
        });
      }
    } else {
      toast.error("Please Select One");
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "#F3F0EE",
          pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
          pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: "center",
            pt: { xs: 2, sm: 2, md: 3, lg: 5, xl: 5 },
            pb: 5,
            bgcolor: "#F3F0EE",
          }}>
          <Stack
            direction={{ xs: "column", sm: "column" }}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "50%",
                xl: "40%",
              },
            }}>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "85%",
                  md: "100%",
                  lg: "85%",
                  xl: "85%",
                },
                pt: pt,
                pb: pb,
                borderRadius: "10px",
                bgcolor: bgcolor,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                boxShadow: "5px solid gray",
              }}>
              <img alt="logo" style={{ maxWidth: width }} src={image} />
            </Box>
          </Stack>

          <Box
            sx={{
              ml: { xs: 1, sm: 3, md: 6, lg: 10, xl: 10 },
              width: {
                xs: "100%",
                sm: "80%",
                md: "80%",
                lg: "65%",
                xl: "65%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
            <Typography
              sx={{
                mt: 3,
                mb: 3,
                color: "#3D2E57",
                fontWeight: 700,
                fontSize: { xs: 22, sm: 22, md: 26, lg: 35, xl: 35 },
              }}>
              {heading}
            </Typography>

            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "70%",
                  xl: "60%",
                },
              }}>
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={text1}
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleRadioChange}>
                    <FormControlLabel
                      componentsProps={{
                        typography: {
                          fontSize: "18px",
                          fontWeight: 400,
                          color: "#3D2E57",
                        },
                      }}
                      value={text1}
                      control={
                        <Radio
                          sx={{
                            color: "#3D2E57",
                            "&.Mui-checked": {
                              color: "#3D2E57",
                            },
                          }}
                        />
                      }
                      label={text1}
                    />
                    <FormControlLabel
                      componentsProps={{
                        typography: {
                          fontSize: "18px",
                          fontWeight: 400,
                          color: "#3D2E57",
                        },
                      }}
                      value={text2}
                      control={
                        <Radio
                          sx={{
                            color: "#3D2E57",

                            "&.Mui-checked": {
                              color: "#3D2E57",
                            },
                          }}
                        />
                      }
                      label={text2}
                      sx={{
                        fontSize: "18px",
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <Box>
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      fontSize: "16px",
                      borderRadius: "15px",
                      mt: 4,
                      mb: 2,
                      color: "#3D2E57",
                      borderColor: "black",
                      textTransform: "none",
                    }}>
                    {" "}
                    Continue
                    <ArrowForwardIosIcon fontSize="20" />
                  </Button>
                </Box>
              </form>
            </Box>
            <Toastify />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AddingCards;
