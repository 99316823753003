import React from "react";
import { useLocation } from "react-router-dom";
import { storage } from "../../config/storage";
import Recipients from "../Custom/Recipients";
import { GIFT_DELIVERY_LOGIN, GIFT_DELIVERY_LOGOUT, GIFT_ONLY_DELIVERY_LOGIN, GIFT_ONLY_DELIVERY_LOGOUT } from "../routes/ConstURL";
const GiftCardRecipients = () => {
  const location = useLocation();
  return (
    <Recipients
      headerText={"Greeting card / Add address"}
      heading={"Recipient details"}
      buttonText={"Continue"}
      bgcolor={"#F3F0EE"}
      inputBgcolor={"#F6F6F6"}
      // onClick={
      //  GREET_DELIVERY_LOGIN
      // }
      validate={true}
      route={
        location.pathname.length < 50 && !storage.fetch.authToken()
          ? GIFT_ONLY_DELIVERY_LOGOUT
          : location.pathname.length < 50 && storage.fetch.authToken()
          ? GIFT_ONLY_DELIVERY_LOGIN
          : location.pathname.length > 50 && storage.fetch.authToken()
          ? GIFT_DELIVERY_LOGIN
          : GIFT_DELIVERY_LOGOUT
      }
      recipient={true}
    />
  );
};

export default GiftCardRecipients;
