import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  FormLabel,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getForgotOtp } from "../../redux/action";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import InputField from "../Custom/Input/InputField";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().required("Please enter your  email").email("Please enter valid email"),
});

const defaultValues = {
  email: "",
};

const ForgetPassword = ({ getForgotOtp }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(model) {
    setLoading(true);
    let item = model;
    getForgotOtp(item).then((res) => {
      setLoading(false);
      if (res) {
        toast.success(res?.data?.message)
        setTimeout(() => {
          navigate("/verifyemailforpassword");
        }, 1000);
      } else {
        res?.data?.errors?.map((item) => {
              return toast.error(item);
            });
      }
    });
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F3F0EE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        pt:{xl:18,md:18,xs:14}
      }}
      >
        <Box
          sx={{
            maxWidth: "750px",
            width: { xs: "90%", sm: "80%", md: "65%", lg: "60%", xl: "50%" },
            mt: {  sm: '10px', md: '20px', lg: '30px', xl: '30px',xs:'0px' },

            borderRadius: {
              xs: "10px",
              sm: "10px",
              md: "15px",
              lg: "20px",
              xl: "20px",
            },
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <form
            name="forgetPasswordForm"
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "90%",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "35px" },
                lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
                fontWeight: "700",
                textAlign: "center",
                color: "#3D2E57",
                mt: 5,
                mb: 3,
              }}
            >
              Forgot Your Password
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  width: {
                    xs: "100%",
                    sm: "74%",
                    md: "68%",
                    lg: "63%",
                    xl: "63%",
                  },
                  fontSize: "18px",
                  lineHeight: "21px",
                  fontWeight: "400",
                  textAlign: "center",
                  color: "#3D2E57",
                  mb: 4,
                }}
              >
                Lost your password? Please enter your email address. You will
                receive an OTP for Verification.
              </Typography>
            </Box>
            <InputField
              control={control}
              helperText={errors?.email?.message}
              errors={!!errors.email}
              type={"text"}
              variant="filled"
              placeholder={"Enter  email here"}
              formlabel="Email"
              size={{ xs: "20px", md: "22px", lg: "24px" }}
              color={"#333333"}
              name={"email"}
              required={"*"}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "60%",
                    lg: "40%",
                    xl: "40%",
                  },
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FFFFFF",
                    border: "1.5px solid #3D2E57",
                  },
                  color: "#3D2E57",
                  border: "1.5px solid #3D2E57",
                  mt:4,
                  mb:5,
                  borderRadius: "15px",
                  fontSize: { xs: "20px", md: "22px", lg: "24px" },
                  fontWeight: 500,
                  textTransform: "none",
                }}
                variant="outlined"
                type="submit"
              >
                
                Reset Password
              </Button>
            </Box>
          </form>
          <Toastify/>
        </Box>
        <Toastify />
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getForgotOtp: (item) => dispatch(getForgotOtp(item)),
  };
}
export default connect(null, mapDispatchToProps)(ForgetPassword);
