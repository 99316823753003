/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation } from "react-router-dom";
import "../header/Header.css";
import InfiniteScroll from "react-infinite-scroll-component";

// const handleClick = (id) => {
//   console.log(id)
//   storage.set.item_id(id);
// };
const Categories = ({ newState, dataEmpty, link, heading, gif, next }) => {
  const location = useLocation();
  const arrayData = gif ? newState?.gifs : newState?.categories;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={style.mainBox}>
      <Typography sx={style.heading}>{heading}</Typography>
      <Box sx={style.box1}>
        <Box>
          <InfiniteScroll
            dataLength={newState?.total_records ? newState?.total_records : 8}
            next={next}
            style={{ overflowX: "hidden", paddingBottom: "50px" }}
            hasMore={newState?.total_records > arrayData?.length ? true : false}
            loader={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  sx={{
                    display: "flex",
                    overflowX: "hidden",
                    height: "60px",
                    width: "60px",
                  }}
                />
              </Box>
            }
            scrollThreshold={1}
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>You've seen it all!</b>
            //   </p>
            // }
            scrollableTarget="scrollableDiv">
            <Stack direction={{ xs: "column", sm: "row" }} sx={style.stack}>
              {dataEmpty &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                  <Box sx={style.itemBox} key={index}>
                    <Skeleton variant="rectangular" sx={{ borderRadius: "10px", pl: "25px" }} width={"100%"} height={300} />
                    <Skeleton variant="rectangular" sx={{ mt: 3, borderRadius: "10px", pl: "25px" }} width={"100%"} height={80} />
                  </Box>
                ))}
              {newState &&
                arrayData?.map((card, index) => (
                  <Box sx={style.itemBox} key={index}>
                    <Box sx={style.imageItem}>
                      <img alt="category_Image" className="categoryCardImage" src={card.file_path} />
                    </Box>

                    <Box sx={style.textContent}>
                      <Typography sx={style.catName}>{card.category_name ? card.category_name : card.title}</Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "center", sm: "left" },
                        }}>
                        <Box sx={style.linkButtonBox}>
                          {!gif && (
                            <Link
                              // to={`${link}/${card.category_id}`}
                              to={link}
                              state={{
                                crumbValue: card.category_name,
                                from: location.state,
                                URL: location.pathname,
                                id: card.category_id,
                              }}
                              className="cardLinkButton">
                              {gif ? "Select" : "View All"}

                              <ArrowForwardIosIcon sx={{ ml: "5px", fontSize: 15 }} />
                            </Link>
                          )}
                          {gif && (
                            <Link
                              // to={`${link}/${card.file_id}`}
                              to={link}
                              state={{
                                value: "E_gift_cards",
                                URL: location.pathname,
                                id: card.file_id,
                              }}
                              className="cardLinkButton"
                              // onClick={handleClick(card.file_id)}
                            >
                              {gif ? "Select" : "View All"}

                              <ArrowForwardIosIcon sx={{ ml: "5px", fontSize: 15 }} />
                            </Link>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Stack>
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};

export default Categories;

const style = {
  outerBox: {
    bgcolor: "#F3F0EE",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainBox: {
    ml: "auto",
    mr: "auto",
    bgcolor: "#F3F0EE",
    pl: { xs: "10px", sm: "20px", md: "", lg: 2.5, xl: 0 },
    pr: { xs: "10px", sm: "20px", md: "", lg: 4, xl: 0 },
  },
  heading: {
    color: "#3D2E57",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "44px",
    lineHeight: "42px",
    pt: 3,
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    pb: "100px",
  },
  stack: {
    display: "flex",
    flexWrap: "wrap",
    mb: 3,
    ml: { xs: 0, sm: -2, md: -4, lg: -2, xl: -3.5 },
    mr: { xs: 0, sm: -2, md: -2, lg: -2, xl: -4 },
  },
  itemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    mt: { xs: 5, sm: 5, md: 8, lg: 5, xl: 5 },
    pl: { xs: "1%", sm: "3%", md: "3.5%", lg: "2%", xl: "2%" },
    pr: { xs: "1%", sm: "3%", md: "3.5%", lg: "2%", xl: "2%" },
    maxWidth: { xs: "92%", sm: "18%", md: "17.5%", lg: "20.5%", xl: "20.25%" },
    width: { xs: "90%", sm: "18%", md: "17.5%", lg: "20.5%", xl: "20.25%" },
  },
  imageItem: {
    width: "100%",
    maxHeight: {
      xs: "max-content",
      sm: "150px",
      md: "200px",
      lg: "200px",
      xl: "200px",
    },
    pt: "35px",
    pb: "35px",
    pr: "13px",
    pl: "13px",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(61, 46, 87, 0.4)",
    bgcolor: "#FFFFFF",
  },
  textContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    pl: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    pr: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    pb: 2,
  },
  catName: {
    pt: 2,
    pb: 2,
    fontWeight: "500",
    color: "#3D2E57",
    lineHeight: "50px",
    fontSize: { xs: "30px", sm: "14px", md: "20px", lg: "24px", xl: "24px" },
    textAlign: {
      xs: "center",
      sm: "left",
      lg: "left",
      xl: "left",
    },
    textTransform: "none",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
  linkButtonBox: {
    // pt: "5px",
    // pb: "5px",
    border: "1.2px solid #3D2E57",
    borderRadius: "15px",
    alignItems: "center",
  },
};
