import React, { createContext, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Index from "../routes/index";
import Wrapper from "../Custom/wrapper/Wrapper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import BodyTitle from "../Custom/BodyTitle";
import HeaderCrums from "../Custom/HeaderCrums";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../config/storage";
import { toast } from "react-toastify";
const THEME = createTheme({
  typography: {
    fontFamily: "effra, serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    text: {
      primary: "#3D2E57",
    },
  },
});

// import HomePage from './homePage';
export const UserContext = createContext();

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const trimmedURL = location.pathname.slice(0, 10);
  let greeting_id = storage.fetch.category_id();
  let egift_id = storage.fetch.amountID();
  // if (trimmedURL === "/greetingc" && greeting_id == undefined) {
  //   setTimeout(() => {
  //     navigate("/greetingcategories");
  //   }, 3000);
  // }
  // if (trimmedURL === "/egiftcard" && egift_id == undefined) {
  //   setTimeout(() => {
  //     navigate(EGIFT_AMOUNT);
  //   }, 3000);
  // }
  // React.useEffect(() => {
  //   if (trimmedURL === "/greetingc" && !greeting_id) {
  //     toast.error("please select a card category");
  //   }
  //   if (trimmedURL === "/egiftcard" && !egift_id) {
  //     toast.error("please select a amount for egift");
  //   }
  // }, []);

  const headerState =
    location.pathname !== "/" &&
    location.pathname !== "/register" &&
    location.pathname !== "/emailotp" &&
    location.pathname !== "/login" &&
    location.pathname !== "/resetpassword" &&
    location.pathname !== "/ForgetPassword" &&
    location.pathname !== "/verifyemailforpassword" &&
    location.pathname !== "/phoneverify" &&
    location.pathname !== "/otpverify" &&
    location.pathname !== "/loginwithOTP";
  // const [headerTextItem, setHeaderTextItem] = useState([
  // ]);

  return (
    <ThemeProvider theme={THEME}>
      <UserContext.Provider value={{}}>
        <Header />
        {headerState && (
          <BodyTitle>
            <HeaderCrums />
          </BodyTitle>
        )}

        <Wrapper>
          <Index />
        </Wrapper>
        <Footer />
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default Main;
