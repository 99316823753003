import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography, FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useForm } from "react-hook-form";
import InputField from "../Custom/Input/InputField";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import LoaderComponent from "../Loader/LoaderComponent";
const schema = yup.object().shape({
  post_on_or_after_date: yup.string().required("Please enter Post on or after Date"),
  post_date: yup.string().required("Please enter Post Date"),
});
const defaultValue = {
  post_on_or_after_date: "",
  post_date: "",
};
const DeliveryDate = ({ onSubmit, loading, headerText, egift }) => {
  const [value, setValue] = React.useState("Postdate");
  const [value1, setValue1] = React.useState("sendnow");
  const [postDate, setpostDate] = React.useState(false);
  const [postafterDate, setpostafterDate] = React.useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formik = useFormik({
    initialValues: defaultValue,
    validationSchema: schema,
    onSubmit: (value) => {
      onSubmit(value);
    },
  });
  const { control, formState, handleSubmit, setError } = useForm({});

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setValue1(event.target.value);
    if (event.target.value == "Postdate") {
      setpostDate(false);
      setpostafterDate(true);
    } else if (event.target.value == "Postafterdate") {
      setpostafterDate(false);
      setpostDate(true);
    } else if (event.target.value == "sendnow") {
      // setValue1("sendnow");
      setpostafterDate(true);
    }
  };
  const Submit = (model) => {
    if (value1 == "sendnow" && egift) {
      let sendNow = { post_date: new Date().toISOString().split("T")[0] };
      Object.assign(model, sendNow);
      onSubmit(model);
    } else {
      if (!postDate && value == "Postdate") {
        if (model.post_date == undefined) {
          toast.error("Please Select Date");
        } else {
          onSubmit(model);
        }
      } else if (!postafterDate && value == "Postafterdate") {
        if (model.post_on_or_after_date == undefined) {
          toast.error("Please Select Date");
        } else {
          onSubmit(model);
        }
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          height: "50%",
          minHeight: "500px",
          maxWidth: "1360px",
          // width: "100%",
          ml: "auto",
          mr: "auto",
          bgcolor: "#F3F0EE",
          pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
          pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
        }}>
        <LoaderComponent open={loading} />
        <Box
          sx={{
            paddingTop: 5,
          }}>
          <Typography
            display="block"
            sx={{
              fontSize: "35px",
              fontWeight: "700",
              lineHeight: "31px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#3D2E57",
            }}>
            Schedule delivery
          </Typography>
        </Box>
        {egift && (
          <form onSubmit={handleSubmit(Submit)}>
            <FormControl
              sx={{
                width: "100%",
                mt: 2,
              }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="Postdate"
                name="radio-buttons-group"
                value={value1}
                onChange={handleRadioChange}
                sx={{
                  width: "100%",
                  flexDirection: "row",
                }}>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      fontSize: "24px",
                      fontWeight: 400,
                      color: "#3D2E57",
                    },
                  }}
                  value="sendnow"
                  control={
                    <Radio
                      sx={{
                        color: "#3D2E57",
                        "&.Mui-checked": {
                          color: "#3D2E57",
                          fontSize: "24px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  }
                  label="Send Now"
                />
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      fontSize: "24px",
                      fontWeight: 400,
                      color: "#3D2E57",
                    },
                  }}
                  value="Postafterdate"
                  control={
                    <Radio
                      sx={{
                        color: "#3D2E57",
                        "&.Mui-checked": {
                          color: "#3D2E57",
                          fontSize: "24px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  }
                  label="Arrive no earlier than"
                />
              </RadioGroup>
              <Box
                sx={{
                  width: { xs: "100%", md: "50%" },
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "50%", lg: "50%" },
                    pt: { xs: 1, sm: 0 },
                    pb: { xs: 1, sm: 0 },
                    mt: 2,
                    mb: 2,
                    bgcolor: "#F6F6F6",
                  }}>
                  <InputField
                    control={control}
                    placeholder={"DD/MM/YYYY"}
                    name="post_on_or_after_date"
                    type={"date"}
                    textTransform="uppercase"
                    min={new Date().toISOString().split("T")[0]}
                    disabled={postafterDate}
                  />
                </Box>
              </Box>

              <Box sx={{}}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    mt: 4,
                    mb: 2,
                    color: "#3D2E57",
                    borderColor: "black",
                    textTransform: "none",
                    borderRadius: "15px",
                  }}>
                  {" "}
                  Add to Cart
                  <ArrowForwardIosIcon fontSize="20" />
                </Button>
              </Box>
            </FormControl>

            <Toastify />
          </form>
        )}
        {!egift && (
          <form onSubmit={handleSubmit(Submit)}>
            <FormControl
              sx={{
                width: "100%",
                mt: 2,
              }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="Postdate"
                name="radio-buttons-group"
                value={value}
                onChange={handleRadioChange}
                sx={{
                  width: { xs: "100%", md: "60%" },
                  display: "flex",
                  flexDirection: { xl: "row", md: "row" },
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "60%", lg: "40%" },
                  }}>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        fontSize: "24px",
                        fontWeight: 400,
                        color: "#3D2E57",
                      },
                    }}
                    value="Postdate"
                    control={
                      <Radio
                        sx={{
                          color: "#3D2E57",
                          "&.Mui-checked": {
                            color: "#3D2E57",
                            fontSize: "24px",
                            fontWeight: 400,
                          },
                        }}
                      />
                    }
                    label="Post on date*"
                  />

                  <InputField
                    control={control}
                    placeholder={"DD/MM/YYYY"}
                    name="post_date"
                    bgcolor="#F6F6F6"
                    type={"date"}
                    min={new Date().toISOString().split("T")[0]}
                    textTransform="uppercase"
                    disabled={postDate}
                    // minHeight={50}
                    sx={{
                      mb: { xs: 13, lg: 0 },
                    }}
                  />

                  {formik.errors.post_date && formik.touched.post_date ? <p style={{ color: "red" }}>{formik.errors.post_date}</p> : null}
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "60%", lg: "40%" },
                  }}>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        fontSize: "24px",
                        fontWeight: 400,
                        color: "#3D2E57",
                      },
                    }}
                    value="Postafterdate"
                    control={
                      <Radio
                        sx={{
                          color: "#3D2E57",
                          "&.Mui-checked": {
                            color: "#3D2E57",
                            fontSize: "24px",
                            fontWeight: 400,
                          },
                        }}
                      />
                    }
                    label="Arrive no earlier than"
                  />

                  <InputField
                    control={control}
                    placeholder={"DD/MM/YYYY"}
                    name="post_on_or_after_date"
                    bgcolor="#F6F6F6"
                    type={"date"}
                    textTransform="uppercase"
                    min={new Date().toISOString().split("T")[0]}
                    disabled={postafterDate}
                  />
                </Box>
              </RadioGroup>
              <Box
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}></Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 20,
                  mt: 2,
                  pt: 3,
                  color: "#3D2E57",
                }}>
                * Please note delivery times may vary depending on delivery destination
              </Typography>
              <Box sx={{}}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    mt: 4,
                    mb: 2,
                    color: "#3D2E57",
                    borderColor: "black",
                    textTransform: "none",
                    borderRadius: "15px",
                  }}>
                  Add to Cart
                  <ArrowForwardIosIcon fontSize="20" />
                </Button>
              </Box>
            </FormControl>

            <Toastify />
          </form>
        )}
      </Box>
      {/* </Box> */}
    </>
  );
};
export default DeliveryDate;
