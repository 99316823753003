import React, { useState } from "react";
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, Skeleton, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteAddress, getAddresses, getUserAddress } from "../../redux/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Toastify from "../SnackBar/Toastify";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import { EDIT_ADDRESS } from "../routes/ConstURL";

const Addresses = ({
  newState,
  heading,
  addressBoxWidth,
  deleteAddress,
  again,
  selectable,
  selectedIndex,
  setSelectedIndex,
  getUserAddress,
  Selected,
  load,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleteAddressId, setdeleteAddressId] = React.useState();
  const [popup, setPopup] = useState(false);
  const [value, setValue] = React.useState(false);
  const handleClickOpen = (id) => {
    setPopup(true);
    setdeleteAddressId(id);
  };
  const handleClose = () => {
    setPopup(false);
    setLoading(true);
    let api_data = {
      address_id: deleteAddressId,
    };
    deleteAddress(api_data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        again("aa");
      }
    });
    again("aa");
  };

  const selectColor = (val, index) => {
    selected(val.address_id);
    const newItem = selectedIndex.map((newVal, ind) => {
      if (ind === index) {
        if (newVal.selected == true) {
          newVal.selected = false;
          return newVal;
        } else {
          newVal.selected = true;
          return newVal;
        }
      } else {
        newVal.selected = false;
        return newVal;
      }
      // comparing category for displaying data
    });
    setSelectedIndex(newItem);
    setValue(true);
  };
  const selected = (id) => {
    setLoading(true);
    let address_id = { address_id: id };
    Selected(true);
    getUserAddress(address_id).then((res) => {
      setLoading(false);
      if (res.data.status) {
        delete res.data.data.user_address.address_id;
        storage.set.recipientDetails(res.data.data.user_address);
      }
    });
  };

  return (
    <>
      <Toastify />
      <Typography sx={Style.typographyStyle}>{heading} </Typography>
      <Dialog
        disableScrollLock
        open={popup}
        onClose={() => {
          setPopup(false);
        }}
        sx={{ ml: 8, mt: 5 }}>
        <DialogContent
          sx={{
            width: 500,
          }}>
          <DialogContentText
            sx={{
              fontSize: "24px",
            }}>
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPopup(false);
            }}>
            No
          </Button>
          <Button onClick={() => handleClose()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        sx={{
          mt: 1,
        }}>
        {load &&
          (selectable ? [1, 2, 3, 4] : [1, 2, 3]).map((val, index) => (
            <Skeleton
              sx={{
                p: 2,
                ml: { xs: 3, sm: 4, md: 6 },
                mr: { xs: 3, sm: 0, md: 0 },
                height: 300,
                width: addressBoxWidth,
              }}
            />
          ))}
        {newState &&
          newState.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={selectable ? 4 : 6} lg={selectable ? 3 : 4}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "#F9F7F6",
                  border: "1px solid #3D2E57",
                  borderRadius: "4px",
                  "&.MuiButtonBase-root:hover": selectable
                    ? {
                        border: "1px solid #BDBDBD",
                        bgcolor: "#BDBDBD",
                      }
                    : "",
                  cursor: selectable ? "pointer" : "",
                }}
                onClick={selectable ? () => selectColor(item, index) : ""}
                style={
                  selectable
                    ? {
                        border: item.selected ? "2px solid rgba(255, 141, 42, 1)" : "",
                      }
                    : {}
                }>
                <Typography sx={Style.user}> {item.recipient_name}</Typography>
                <Box sx={Style.addressLine}>
                  <FontAwesomeIcon icon={faUser} style={Style.awesomeIcon} />
                  <Typography sx={Style.Address}>{item.recipient_name}</Typography>
                </Box>

                <Box sx={Style.addressLine}>
                  <FontAwesomeIcon icon={faEnvelope} style={Style.awesomeIcon} />
                  <Typography sx={Style.Address}>{item.recipient_email}</Typography>
                </Box>
                <Box sx={Style.addressLine}>
                  <img alt="logo" style={Style.awesomeIcon} src={require("../../assets/phone.png")} />
                  <Typography sx={Style.Address}>{item.recipient_contact}</Typography>
                </Box>
                <Box sx={Style.addressLine}>
                  <img alt="logo" style={Style.awesomeIcon} src={require("../../assets/house.png")} />
                  <Typography sx={Style.Address}>{item.recipient_address1}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    pt: 2,
                  }}>
                  {!selectable && (
                    <>
                      <Link
                        to={EDIT_ADDRESS}
                        state={{
                          id: item.address_id,
                        }}
                        style={Style.edit}>
                        <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: "10px" }} />
                        Edit
                      </Link>
                      <Link style={Style.remove} onClick={() => handleClickOpen(item.address_id)}>
                        <FontAwesomeIcon icon={faTrashCan} style={{ marginRight: "10px" }} />
                        Remove
                      </Link>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    deleteAddress: (api_data) => dispatch(deleteAddress(api_data)),
    getAddresses: (data) => dispatch(getAddresses(data)),
    getUserAddress: (data) => dispatch(getUserAddress(data)),
  };
}
export default connect(null, mapDispatchToProps)(Addresses);
const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "35px",
    fontWeight: "500",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
  },
  user: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#3D2E57",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "200px",
  },
  addressBox: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "200px",
  },
  addressLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    pt: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "200px",
  },
  addressLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    pt: 1,
  },
  Address: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#3D2E57",
    pl: 2,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "200px",
  },
  edit: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3D2E57",
    textDecoration: "none",
  },
  remove: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#EB5757",
    textDecoration: "none",
  },
  linkButtonBox: {
    color: "#3D2E57",
    pt: "8px",
    pb: "8px",
    pr: "25px",
    pl: "25px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px",
    fontWeight: "400",
    border: "1px solid #3D2E57",
    fontSize: "16px",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
  },
  awesomeIcon: {
    marginTop: "3px",
  },
};
