/* eslint-disable no-useless-concat */
const LS_KEY = {
  auth_token: "jwt_access_token_Gopher",
  forgot_token: "jwt_forgot_token_Gopher",
  userId_token: "jwt_userId_Gopher",
  recipients_Data: "jwt_recipients_Gopher",
  message: "message_Gopher",
  phone_number: "phone",
  item_id: "item_id",
  carts_id: "carts_id",
  guest_user: "guest_user_api_key",
  prev_id: "prev_id",
  amountID: "amountID",
  category_id: "category_id",
  card_detail_id: "card_detail_id",
  order_id: "order_id",
  order_item_id: "order_item_id",
  gift_amount: "Gift_amount",
  additional_card: "additional_card",
};

const set = {
  authToken: (data) => {
    localStorage.setItem(LS_KEY.auth_token, "Bearer" + " " + data);
  },
  forgotToken: (data) => {
    localStorage.setItem(LS_KEY.forgot_token, data);
  },
  userId: (data) => {
    localStorage.setItem(LS_KEY.userId_token, data);
  },
  recipientDetails: (data) => {
    localStorage.setItem(LS_KEY.recipients_Data, JSON.stringify(data));
  },
  message: (data) => {
    localStorage.setItem(LS_KEY.message, JSON.stringify(data));
  },
  phoneNumber: (data) => {
    localStorage.setItem(LS_KEY.phone_number, data);
  },
  item_id: (data) => {
    localStorage.setItem(LS_KEY.item_id, data);
  },
  carts_id: (data) => {
    localStorage.setItem(LS_KEY.carts_id, data);
  },
  guest_user_api_key: (data) => {
    localStorage.setItem(LS_KEY.guest_user, data);
  },
  prev_id: (data) => {
    localStorage.setItem(LS_KEY.prev_id, data);
  },
  amountID: (data) => {
    localStorage.setItem(LS_KEY.amountID, data);
  },
  giftamountID: (data) => {
    localStorage.setItem(LS_KEY.gift_amount, data);
  },
  category_id: (data) => {
    localStorage.setItem(LS_KEY.category_id, data);
  },
  card_detail_id: (data) => {
    localStorage.setItem(LS_KEY.card_detail_id, data);
  },
  order_id: (data) => {
    localStorage.setItem(LS_KEY.order_id, data);
  },
  order_item_id: (data) => {
    localStorage.setItem(LS_KEY.order_item_id, data);
  },
  additional_card: (data) => {
    localStorage.setItem(LS_KEY.additional_card, data);
  },
};

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(LS_KEY.auth_token);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  message: () => {
    const data = JSON.parse(localStorage.getItem(LS_KEY.message));
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  recipientDetails: () => {
    const data = JSON.parse(localStorage.getItem(LS_KEY.recipients_Data));
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  item_id: () => {
    const data = localStorage.getItem(LS_KEY.item_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  amountID: () => {
    const data = localStorage.getItem(LS_KEY.amountID);
    if (data) {
      try {
        return data;
      } catch (err) {}
    }
  },
  giftamountID: () => {
    const data = localStorage.getItem(LS_KEY.gift_amount);
    if (data) {
      try {
        return data;
      } catch (err) {}
    }
  },
  carts_id: () => {
    const data = localStorage.getItem(LS_KEY.carts_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  guest_user_api_key: () => {
    const data = localStorage.getItem(LS_KEY.guest_user);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  prev_id: () => {
    const data = localStorage.getItem(LS_KEY.prev_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  category_id: () => {
    const data = localStorage.getItem(LS_KEY.category_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  card_detail_id: () => {
    const data = localStorage.getItem(LS_KEY.card_detail_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  order_id: () => {
    const data = localStorage.getItem(LS_KEY.order_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  order_item_id: () => {
    const data = localStorage.getItem(LS_KEY.order_item_id);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
  additional_card: () => {
    const data = localStorage.getItem(LS_KEY.additional_card);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
};
const fetchPhoneNumber = {
  phoneNumber: () => {
    const data = localStorage.getItem(LS_KEY.phone_number);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
};
const fetchForForgot = {
  forgotToken: () => {
    const data = localStorage.getItem(LS_KEY.forgot_token);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
};

const fetchForUserId = {
  userId: () => {
    const data = localStorage.getItem(LS_KEY.userId_token);
    if (data) {
      try {
        const decoded = data;
        return decoded;
      } catch (err) {}
    }
  },
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token);
  },
  phoneNumber: () => {
    localStorage.removeItem(LS_KEY.phone_number);
  },
  message: () => {
    localStorage.removeItem(LS_KEY.message);
  },
  recipientDetails: () => {
    localStorage.removeItem(LS_KEY.recipients_Data);
  },
  item_id: () => {
    localStorage.removeItem(LS_KEY.item_id);
  },
  amountID: () => {
    localStorage.removeItem(LS_KEY.amountID);
  },
  giftamountID: () => {
    localStorage.removeItem(LS_KEY.gift_amount);
  },
  media_file_id: () => {
    localStorage.removeItem(LS_KEY.media_file_id);
  },
  carts_id: () => {
    localStorage.removeItem(LS_KEY.carts_id);
  },
};

export const storage = {
  set,
  fetch,
  destroy,
  fetchForForgot,
  fetchForUserId,
  fetchPhoneNumber,
};
