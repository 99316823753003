export const HOMEPAGE = "/";
export const REGISTER = "/register";
export const LOGIN = "/login";
export const MYACCOUNT = "/myaccount";
export const MYPROFILE = "/myaccount/myprofile";
export const MYORDER = "/myaccount/myorder";
export const MYREMINDERS = "/myaccount/myreminders";
export const MYADDRESS = "/myaccount/myaddresses";
export const MYORDER_DETAIL = "/myaccount/myorder/myorderdetail";
export const MYORDER_ITEMDETAIL = "/myaccount/myorder/myorderdetail/itemdetail";
export const ADDNEW_ADDRESS = "/myaccount/addnewaddress";
export const EDIT_ADDRESS = "/myaccount/editaddress";
export const LOGIN_OTP = "/loginwithOTP";
//URL FOR GREETING CARD PANEL
export const CATEGORIES = "/categories";
export const CARDLISTING = "/categories/cardlisting";
export const CARDDETAIL = "/categories/cardlisting/Carddetail";
export const CARDMESSAGE = "/categories/cardlisting/Carddetail/addmessage";
export const GREETING_ADDRESS = "/categories/cardlisting/Carddetail/addmessage/addresses";
export const GREET_RECIPIENT_AFTER_GIFT_LOGOUT = "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount/addnew";
export const GREET_RECIPIENT_AFTER_GIFT_LOGIN =
  "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount/addresses/addnew";
export const GREET_DELIVERY_AFTER_GIFT_LOGIN =
  "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount/addresses/delivery";
export const GREET_ADDRESS_AFTER_GIFT = "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount/addresses";

export const GREET_DELIVERY_AFTER_GIFT_LOGOUT =
  "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount/addnew/delivery";
export const GREET_RECIPIENT_LOGIN = "/categories/cardlisting/Carddetail/addmessage/addresses/addnew";
export const GREET_RECIPIENT_LOGOUT = "/categories/cardlisting/Carddetail/addmessage/addnew";
export const GREET_DELIVERY_LOGOUT = "/categories/cardlisting/Carddetail/addmessage/addnew/delivery";
export const GREET_DELIVERY_LOGIN = "/categories/cardlisting/Carddetail/addmessage/addresses/delivery";
export const GREET_ADD_CARD = "/categories/cardlisting/Carddetail/addmessage/addgiftcard";
export const GREET_ADD_AMOUNT = "/categories/cardlisting/Carddetail/addmessage/addgiftcard/addgiftcardamount";
// URL for GIFT CARD PANEL
export const GIFT_AMOUNT = "/giftcardamount";
export const GIFT_SELECT = "/giftcardamount/addgreetingcard";
export const GIFT_ONLY_RECIPIENT_LOGIN = "/giftcardamount/addgreetingcard/addresses/addnew";
export const GIFT_ONLY_ADDRESS_LOGIN = "/giftcardamount/addgreetingcard/addresses";
export const GIFT_ONLY_DELIVERY_LOGIN = "/giftcardamount/addgreetingcard/addresses/delivery";
export const GIFT_ONLY_DELIVERY_LOGOUT = "/giftcardamount/addgreetingcard/addnew/delivery";
export const GIFT_ONLY_RECIPIENT_LOGOUT = "/giftcardamount/addgreetingcard/addnew";
export const GIFT_CATEGORIES = "/giftcardamount/addgreetingcard/categories";
export const GIFT_CARDLISTING = "/giftcardamount/addgreetingcard/categories/cardlisting";
export const GIFT_CARDDETAIL = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail";
export const GIFT_CARDMESSAGE = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage";
export const GIFT_ADDRESS = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage/addresses";
export const GIFT_RECIPIENTS_LOGIN = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage/addresses/addnew";
export const GIFT_RECIPIENTS_LOGOUT = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage/addnew";
export const GIFT_DELIVERY_LOGIN = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage/addresses/delivery";
export const GIFT_DELIVERY_LOGOUT = "/giftcardamount/addgreetingcard/categories/cardlisting/Carddetail/addmessage/addnew/delivery";
//URL for E_GIFT CARD PANEL
export const EGIFT_AMOUNT = "/selectamount";
export const EGIFT_SELECT = "/selectamount/e-gifttype";
export const EGIFT_VIDEO = "/selectamount/e-gifttype/recordvideo";
export const EGIFT_GIF = "/selectamount/e-gifttype/selectgif";
export const EGIFT_GIF_RECIPIENT = "/selectamount/e-gifttype/selectgif/recipient";
export const EGIFT_VIDEO_RECIPIENT = "/selectamount/e-gifttype/recordvideo/recipient";
export const EGIFT_VIDEO_DELIVERY = "/selectamount/e-gifttype/recordvideo/recipient/egiftdelivery";
export const EGIFT_GIF_DELIVERY = "/selectamount/e-gifttype/selectgif/recipient/egiftdelivery";
export const EGIFT_VIDEOLINK = "/video/message/:id";
//common pages
export const CART = "/mycart";
