import React from 'react'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify';
import { css } from 'glamor';
const Toastify = () => {
  return (
    <div>
        <ToastContainer
            position="bottom-right"
            autoClose={3000}
            toastStyle={customToast}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </div>
  )
}

export default Toastify

const customToast = {
    success(msg, options = {}) {
      return toast.success(msg, {
        ...options,
        className: 'toast-success-container toast-success-container-after',
        progressClassName: css({
          background: '#34A853',
        }),
      });
    },
    error(msg, options = {}) {
      return toast.error(msg, {
        ...options,
        className: 'toast-error-container toast-error-container-after',
        progressClassName: css({
          background: '#EE0022',
        }),
      });
    },
    info(msg, options = {}) {
      return toast.info(msg, {
        ...options,
        className: 'toast-info-container toast-info-container-after',
        progressClassName: css({
          background: '#07F',
        }),
      });
    },
  };