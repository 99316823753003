import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  item,
  href,
  default_bgcolor,
  onclick_bgcolor,
  borderRadius,
  size,
  fontSize,
  fontWeight,
  onClickButton,
  variant,
  textColor,
  pl,
  pr,
  pb,
  pt,
  ml,
  height,
  border,
  lineHeight,
  onclick_border,
  textTransform,
}) => {
  return (
    <Button
      disableRipple
      disableElevation
      href={href}
      variant={variant}
      onClick={onClickButton}
      size={size}
      sx={{
        fontWeight: fontWeight,
        fontSize: fontSize,
        borderRadius: borderRadius,
        color: textColor,
        bgcolor: default_bgcolor,
        "&.MuiButtonBase-root:hover": {
          bgcolor: onclick_bgcolor,
          border: onclick_border,
        },
        pl: pl,
        pr: pr,
        pt: pt,
        pb: pb,
        mr: "5px",
        ml: ml,
        height: height,
        border: border,
        lineHeight: lineHeight,
        textTransform: textTransform,
      }}
    >
      {item}
    </Button>
  );
};

export default CustomButton;
