/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import { getCarddetail } from "../../redux/action";
import CardDetailPage from "../Custom/CardDetailPage";
import { UserContext } from "../home/main";
import LoaderComponent from "../Loader/LoaderComponent";
import { CARDDETAIL, CATEGORIES, GIFT_CARDLISTING, GIFT_CARDMESSAGE } from "../routes/ConstURL";
import Toastify from "../SnackBar/Toastify";

const GiftCardDetail = ({ getCarddetail }) => {
  const aboveBreadcrumb = useLocation();
  const [load, setLoad] = useState(true);
  const header = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  let id = location?.state?.id;
  if (id) {
    storage.set.card_detail_id(id);
  }
  let id_storage = storage.fetch.card_detail_id();
  if (id_storage) {
    id = id_storage;
  } else {
    setTimeout(() => {
      navigate(CATEGORIES);
    }, 3000);
  }
  const newState = useSelector((state) => state?.User?.carddetail);
  useEffect(() => {
    let card_id = {
      greeting_card_id: id,
    };
    if (!id_storage) {
      toast.error("please first select a card category");
    }
    getCarddetail(card_id).then((res) => {
      if (res.data) {
        setLoad(false);
      } else {
        setLoad(false);
      }
    });
    window.scrollTo(0, 0);
  }, []);
  const handleClick = (item) => {
    let card_id = {
      greeting_card_id: item,
    };
    getCarddetail(card_id);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <LoaderComponent open={load} />
      <Toastify />
      <CardDetailPage
        headerParentText={"Greeting Cards /"}
        headeChild1={`${aboveBreadcrumb?.state?.prevPageName} /`}
        headerChildText={aboveBreadcrumb?.state?.crumbValue ? aboveBreadcrumb?.state?.crumbValue : " CARD DETAILS"}
        viewDetail={CARDDETAIL}
        handleClick={handleClick}
        newState={newState}
        goForward={GIFT_CARDMESSAGE}
        goBack={GIFT_CARDLISTING}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getCarddetail: (card_id) => dispatch(getCarddetail(card_id)),
  };
}
export default connect(null, mapDispatchToProps)(GiftCardDetail);
