import React, { useContext, useEffect, useState } from "react";
import Addresses from "../Custom/Addresses";
import { connect, useSelector } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import { getAddresses } from "../../redux/action";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from "@mui/material/Pagination";
import Toastify from "../SnackBar/Toastify";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../home/main";
import LoaderComponent from "../Loader/LoaderComponent";
import { ADDNEW_ADDRESS } from "../routes/ConstURL";
const MyAddresses = ({ getAddresses, setAccountData }) => {
  const [Empty, setEmpty] = useState(false);
  const [load, setLoad] = useState(true);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const navigate = useNavigate();
  const newState = useSelector((state) => state?.User?.addressList);
  let length = 6;
  let data = {
    start: start,
    length: length,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(true);
    getAddresses(data).then((res) => {
      setTimeout(() => {
        setLoad(false);
      }, 2000);
      if (res?.data?.data?.user_addresses.length == 0) {
        setEmpty(true);
      } else {
        if (res?.data?.status) {
          setEmpty(false);
          setPages(res.data.data.pages);
        }
      }
    });
  }, []);
  const againA = (a) => {
    handleChange("", 1);
  };
  const handleChange = (event, value) => {
    setLoad(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    getAddresses(data).then((res) => {
      setTimeout(() => {
        setLoad(false);
      }, 2000);
      if (res?.data?.data?.user_addresses.length == 0) {
        setPages(1);
        setEmpty(true);
      } else {
        if (res?.data?.status) {
          setEmpty(false);
          setPages(res.data.data.pages);
        }
      }
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: { xs: "center", md: "flex-end" },
        alignContent: "center",
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
        pb: 5,
      }}>
      <Toastify />
      <LoaderComponent open={load} />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          height: "100%",
          borderRadius: 4,
          justifyContent: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            pb: 1,
          }}>
          <Typography sx={Style.typographyStyle}> My addresses </Typography>
          <Box onClick={() => navigate(ADDNEW_ADDRESS)} sx={Style.linkButton}>
            Add new address
            <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
          </Box>
        </Box>
        {load && (
          <Addresses
            load={true}
            addressBoxWidth={{
              xs: "100%",
              sm: "39%",
              md: "38%",
              lg: "25%",
              xl: "26%",
            }}
          />
        )}
        {!load && Empty && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 20,
              pb: 20,
              fontSize: "35px",
              color: "#3D2E57",
              fontWeight: "700",
            }}>
            No Record Found
          </Typography>
        )}
        {!load && !Empty && (
          <>
            <Box
              sx={{
                pl: 3,
                pr: 3,
              }}>
              <Addresses newState={newState} bottomButton={false} setAccountData={setAccountData} again={() => againA("a")} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                p: 3,
              }}>
              {pages > 1 && <Pagination count={pages} page={page} onChange={handleChange} />}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getAddresses: (data) => dispatch(getAddresses(data)),
  };
}
export default connect(null, mapDispatchToProps)(MyAddresses);
const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: { xs: "24px", sm: "35px" },
    fontWeight: { xs: "400", sm: "700" },
    lineHeight: { xs: "29px", sm: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
  },
  user: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#3D2E57",
  },
  addressLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    pt: 1,
  },
  Address: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#3D2E57",
  },
  edit: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3D2E57",
    textDecoration: "none",
  },
  remove: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#EB5757",
    textDecoration: "none",
  },
  linkButton: {
    pr: { xs: "5px", sm: "10px", md: "23px" },
    pl: { xs: "5px", sm: "10px", md: "23px" },
    borderRadius: "15px",
    border: "1px solid #3D2E57",
    color: "#3D2E57",
    fontSize: "16px",
    lineHeight: "19px",
    height: { xs: "30px", md: "33px" },
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    cursor: "pointer",
  },
  awesomeIcon: {
    marginTop: "3px",
  },
};
