/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import { addtoCartGreetingCard } from "../../redux/action";
import DeliveryDate from "../Custom/DeliveryDate";
import { CART } from "../routes/ConstURL";
import Toastify from "../SnackBar/Toastify";

const GreetingCardDelivery = ({ addtoCartGreetingCard }) => {
  const [loading, setLoading] = useState(false);
  const cartDetails = storage.fetch.recipientDetails();
  let cart_id = storage.fetch.carts_id();
  let id = {};
  if (cart_id !== undefined || cart_id != "undefined") {
    id = { carts_id: cart_id };
  } else {
    id = { carts_id: "" };
  }
  const navigate = useNavigate();
  const onSubmit = async (model) => {
    setLoading(true);
    let item_type = { item_type: storage.fetch.additional_card() === "GIFT_CARD_STATE" ? "3" : "1", item_qty: "1" };
    let greet_msg = { greet_msg: storage.fetch.message()?.greet_msg };
    let item_id = { item_id: storage.fetch.item_id() };
    let child = [
      {
        post_on_or_after_date: model.post_on_or_after_date,
        post_date: model.post_date,
        recipient_name: cartDetails.recipient_name,
        recipient_email: cartDetails.recipient_email,
        recipient_contact: cartDetails.recipient_contact,
        recipient_dob: cartDetails.recipient_dob,
        phonecode: cartDetails.phonecode,
        item_type: "3",
        item_id: storage.fetch.giftamountID(),
        item_qty: "1",
      },
    ];
    let item = model;
    // console.log(item);
    // if (
    //   storage.fetch.additional_card() === "GREETING_CARD_STATE_WITH_GIFT" ||
    //   storage.fetch.additional_card() === "GIFT_CARD_STATE_WITH_GREETING"
    // ) {
    //   Object.assign(item, cartDetails, id, item_id, item_type, item_id, greet_msg, child);

    Object.assign(item, cartDetails, id, item_id, item_type, item_id, greet_msg);

    if (
      storage.fetch.additional_card() === "GREETING_CARD_STATE_WITH_GIFT" ||
      storage.fetch.additional_card() === "GIFT_CARD_STATE_WITH_GREETING"
    ) {
      item.child_items = child;
    } else if (storage.fetch.additional_card() === "GIFT_CARD_STATE") {
      item.item_id = storage.fetch.giftamountID();
    } else {
      delete item.child;
    }
    addtoCartGreetingCard(item).then((res) => {
      if (res?.data?.status) {
        setTimeout(() => {
          toast.success(res?.data?.message);
          setLoading(false);
          storage.destroy.item_id();
          storage.destroy.recipientDetails();
          storage.destroy.message();
          navigate(CART);
        }, 2000);
      } else {
        res?.data?.errors?.map((item) => {
          toast.error(item);
        });
        setTimeout(() => {
          setLoading(false);
          // navigate('/greetingcategories')
        }, 1000);
      }
    });
  };

  return (
    <>
      <DeliveryDate loading={loading} headerText={"Greeting cards/ select delivery date"} setLoading={setLoading} onSubmit={onSubmit} />
      <Toastify />
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    addtoCartGreetingCard: (category_id) => dispatch(addtoCartGreetingCard(category_id)),
  };
}
export default connect(null, mapDispatchToProps)(GreetingCardDelivery);
