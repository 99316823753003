import React from "react";
import { connect, useSelector } from "react-redux";
import { storage } from "../../config/storage";
// import { useNavigate } from 'react-router-dom';
import { get_e_gift_card_amount } from "../../redux/action";
import CardAmount from "../Custom/CardAmount";
import LoaderComponent from "../Loader/LoaderComponent";
import { EGIFT_SELECT } from "../routes/ConstURL";
const img = require("../../assets/image 12.png");

const SelectAmount = ({ get_e_gift_card_amount }) => {
  // const newState = useSelector((state) => state?.User?.e_Gift_card_data);
  const [selectedIndex, setSelectedIndex] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  // const navigate = useNavigate();
  React.useEffect(() => {
    let arrayMap = [];
    get_e_gift_card_amount().then((res) => {
      setOpen(false);
      if (res.data.status) {
        res.data.data.egift_cards.map((val) => {
          val["selected"] = false;
          arrayMap.push(val);
        });
        setSelectedIndex(arrayMap);
      }
    });
  }, []);

  return (
    <>
      <LoaderComponent open={open} />
      <CardAmount
        headerText={"E-GIFT cards / Select Amount"}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        image={img}
        pt={"100px"}
        width={"300px"}
        heading={"Select Amount"}
        bgcolor={"#FF8D2A"}
        onClick={EGIFT_SELECT}
        grid={true}
      />
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    get_e_gift_card_amount: () => dispatch(get_e_gift_card_amount()),
  };
}
export default connect(null, mapDispatchToProps)(SelectAmount);
