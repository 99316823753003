import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { storage as LocalStorage } from "../../config/storage";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOGIN } from "./ConstURL";

const ProtectedRoute = ({ children }) => {
  // const [state, setstate] = useState(false);
  const acc_Token = LocalStorage.fetch.authToken();
  if (acc_Token) {
    // toast.error('You are Logged In')
  } else {
    setTimeout(() => {
      toast.error("Please Log in first ");
    }, 500);

    return <Navigate to={LOGIN} replace />;
  }
  return children;
};

export default ProtectedRoute;
