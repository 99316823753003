import * as React from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputField from "../Input/InputField";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoginwithPhone from "../../Login/loginwithPhone";
import LoginwithEmail from "../../Login/loginwithEmail";
import Toastify from "../../SnackBar/Toastify";



export default function LoginWithOTPModal({ onCloseModal, openModal,onClickModal2,onClickModal1,height,width }) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: width,
        height: height,
        bgcolor: "background.paper",
        border: "0px solid #000",
        boxShadow: 24,
        borderRadius: "5px",
        p: 4,
      };
  const navigate = useNavigate();
  const [sendedOTP, setSendedOTP] = React.useState(false);
  const [emailOTP, setEmailOTP] = React.useState(false);
  const [phoneOTP, setPhoneOTP] = React.useState(true);
  const [loader, setloader] = React.useState(false);
  const [otpverify, setOtpverify] = React.useState(false);

  return (
    <div>
      {/* <Button onClick={onClickModal}>Open modal</Button> */}
      <Modal
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "35px" },
              lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
              fontWeight: "700",
              textAlign: "center",
              color: "#3D2E57",
              mt: 5,
              mb: 4,
            }}
          >
            Login
          </Typography>

          <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
            Send otp to
            <Button
              disableRipple
              sx={{
                ml: "10px",
                bgcolor: emailOTP ? "#FFFFFF" : "#FF8D2A",
                color: emailOTP ? "#3D2E57" : "",
                textTransform: "none",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: emailOTP ? "#FFFFFF" : "#FF8D2A",
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              variant="contained"
              onClick={() => {
                setEmailOTP(false);
                setPhoneOTP(true);
              }}
            >
              Email
            </Button>
            <Button
              onClick={() => {
                setEmailOTP(true);
                // setPhoneOTP(false)
                setSendedOTP(false);
                setOtpverify(false);
              }}
              variant="contained"
              disableRipple
              sx={{
                color: emailOTP ? "" : "#3D2E57",
                textTransform: "none",
                bgcolor: emailOTP ? "#FF8D2A" : "#FFFFFF",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: emailOTP ? "#FF8D2A" : "#FFFFFF",
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
            >
              Phone
            </Button>
          </Typography>
          {emailOTP ? <LoginwithPhone /> : <LoginwithEmail />}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "center" },
            }}
          >
            <Link
            //   to="/register"
            onClick={onClickModal1}
              className="fontlink2"
              style={{
                fontWeight: 400,
                color: "#FF8D2A",
                textDecoration: "none",
                marginBottom: "10px",
              }}
            >
              Register as a guest User
            </Link>

            <Button
              disableRipple
              onClick={onClickModal2}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                },
                textTransform: "none",
                fontWeight: 400,
                color: "#3D2E57",
                textDecoration: "none",
                maxHeight: "37px",
                mb: "10px",
                fontSize: { xs: "13px", md: "20px" },
                pt: 0,
                pb: 1,
              }}
              variant="text"
            >
              Login With Password
            </Button>
          </Box>
          <Toastify />
        </Box>
      </Modal>
    </div>
  );
}
