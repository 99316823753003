import { Box, Button, Typography, Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import ViewDetails from "./viewDetails";
import Toastify from "../SnackBar/Toastify";
import { getReminders } from "../../redux/action";
import Pagination from "@mui/material/Pagination";

const MyReminders = ({ getReminders }) => {
  let length = 6;
  const [Empty, setEmpty] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [item, setItem] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [load, setLoad] = useState(false);
  let data = {
    start: start,
    length: length,
  };
  useEffect(() => {
    setLoad(true);
    getReminders(data).then((res) => {
      setLoad(false);
      if (res?.data?.data?.user_reminders.length == 0) {
        setEmpty(true);
      } else {
        setItem(res.data.data.user_reminders);
        setEmpty(false);
        setPages(res.data.data.pages);
      }
    });
    window.scrollTo(0, 0);
  }, []);
  const againA = (a) => {
    handleChange("", 1);
  };

  const handleChange = (event, value) => {
    setLoad(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    getReminders(data).then((res) => {
      setLoad(false);
      if (res?.data?.data?.user_reminders?.length === 0) {
        setEmpty(true);
        setItem([]);
      } else {
        setItem(res?.data?.data?.user_reminders);
        setEmpty(false);
        setPages(res.data.data.pages);
        setDisabled(false);
      }
    });
  };
  const addReminder = () => {
    if (item[item.length - 1]?.reminder_id?.length !== 0) {
      setLoad(false);
      setEmpty(false);
      setDisabled(true);
      setItem([...item, {}]);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: { xs: "center", md: "flex-end" },
        alignContent: "center",
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
        pb: 5,
      }}
    >
      <Toastify />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          height: "100%",
          flexDirection: "column",
          borderRadius: 4,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            pb: 2,
          }}
        >
          <Typography sx={Style.typographyStyle}>My Reminders</Typography>
          <Button
            onClick={addReminder}
            disabled={disabled}
            sx={Style.linkButton}
          >
            Add Reminders{" "}
            <span style={{ marginLeft: "5px", fontSize: "25px" }}>+</span>
          </Button>
        </Box>
        {Empty && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 20,
              pb: 20,
              fontSize: "35px",
              color: "#A8A8A8",
              fontWeight: "700",
            }}
          >
            No Reminder Found
          </Typography>
        )}
        {!Empty && (
          <Stack
            sx={{
              pl: 3,
              pr: 3,
            }}
          >
            {load &&
              [1, 2].map((rem, index) => (
                <Box
                  sx={{
                    width: "100%",
                    mb: 2,
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      height: 62,
                    }}
                  />
                </Box>
              ))}
            {!load &&
              item.map((rem, index) => (
                <Box
                  sx={{
                    mb: 2,
                    pt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <ViewDetails
                    item={rem}
                    index={index}
                    load={false}
                    again={() => againA("a")}
                  />
                </Box>
              ))}
          </Stack>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 2,
          }}
        >
          {pages > 1 && (
            <Pagination count={pages} page={page} onChange={handleChange} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getReminders: (data) => dispatch(getReminders(data)),
  };
}
export default connect(null, mapDispatchToProps)(MyReminders);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: { xs: "24px", sm: "35px" },
    fontWeight: { xs: "400", sm: "700" },
    lineHeight: { xs: "29px", sm: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
  },
  typographyStyle1: {
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: "400",
    marginRight: "10px",
  },
  typographyStyle2: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
  },
  tableCell: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#3D2E57",
    borderRadius: 5,
  },
  linkButton: {
    pr: { xs: "5px", sm: "7px", md: "14px" },
    pl: { xs: "5px", sm: "7px", md: "14px" },
    borderRadius: "15px",
    border: "1px solid #219653",
    color: "#219653",
    fontSize: "14px",
    lineHeight: "19px",
    height: { xs: "30px", md: "31px" },
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    cursor: "pointer",
  },
};
