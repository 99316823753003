import { Api } from "../../config/request";

export const requestStripePayment = async (data) => {
  return Api.getListRequest("/stripe/payment", data);
};

const StripeApi = {
  requestStripePayment,
};
export default StripeApi;
