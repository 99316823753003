/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import Sidecomponent from "./SideComponent";
import { AccountData } from "../../../config/mockData";
import { useContext } from "react";
import { UserContext } from "../../home/main";
import { Outlet, useLocation } from "react-router-dom";

const MainAccount = () => {
  const [AccountDat, setAccountData] = useState([...AccountData]);
  const header = useContext(UserContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "50%",
          minHeight: "500px",
          maxWidth: "1360px",
          width: "100%",
          ml: "auto",
          bgcolor: "#F3F0EE",
          mr: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: {xs:'10px',md:"40px"},
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 0, md: 4 }}
              sx={{
                width: {
                  xs: "95%",
                  sm: "95%",
                  md: "96%",
                  lg: "97%",
                  xl: "100%",
                },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "21%", lg:"16%",xl: "15%" },
                }}
              >
                <Sidecomponent
                  setAccountData={setAccountData}
                />
              </Box>

              <Outlet setAccountData={setAccountData} />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainAccount;
