import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate, Link } from "react-router-dom";
import { TextField, FormLabel, Button, Typography, Backdrop, CircularProgress, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import InputField from "../Custom/Input/InputField";
import { connect, useSelector } from "react-redux";
import { getPhoneVerify, country_dropdown_data } from "../../redux/action";
import { storage } from "../../config/storage";
import Toastify from "../SnackBar/Toastify";
import "../Login/loginpage.css";
import { LOGIN } from "../routes/ConstURL";

const Style = {
  typographyStyle: {
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    pb: 3,
  },
  rowBoxStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  inputStyle: {
    width: "95%",
  },
};
const defaultValues = {
  phone: "",
};
const PhoneVerify = ({ getPhoneVerify, country_dropdown_data }) => {
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("61");
  const [countryNumberRange, setCountryNumberRange] = React.useState({
    max: 9,
    min: 9,
    id: "",
  });
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("Please enter your phone number")
      .min(countryNumberRange.min, `Enter minimum ${countryNumberRange.min} numbers `)
      .max(countryNumberRange.max, `Enter maximum ${countryNumberRange.max} numbers`),
  });
  const newState = useSelector((state) => state?.User?.country_data);
  const country_names = newState?.country_list;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // const [item,setItem] = useState()
  const { control, formState, handleSubmit } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  function onSubmit(model) {
    setLoading(true);
    let phone = model.phone;
    storage.set.phoneNumber(phone);
    let user_id = storage.fetchForUserId.userId();
    let item = {
      phone,
      user_id: user_id,
      country_id: countryNumberRange.id,
      phonecode: countryPhoneCode,
    };
    getPhoneVerify(item).then((res) => {
      setLoading(false);
      if (res) {
        setTimeout(() => {
          navigate("/otpverify");
        }, 1000);
      }
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    country_dropdown_data();
  }, []);
  const handleChange = (e) => {
    setCountryPhoneCode(e.target.value.phone_code);
    setCountryPhoneCode("+61");
    setCountryNumberRange({
      max: e.target.value.sizeof_nn.max,
      min: e.target.value.sizeof_nn.min,
      id: e.target.value.country_id,
    });
  };
  return (
    <Box
      sx={{
        bgcolor: "#F3F0EE",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          maxWidth: "750px",
          width: { xs: "90%", sm: "80%", md: "65%", lg: "60%", xl: "50%" },
          mt: { sm: 14, md: 16, lg: 19, xl: 20, xs: 13 },

          borderRadius: {
            xs: "10px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "20px",
          },
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Toastify />
        <form
          onSubmit={handleSubmit(onSubmit)}
          schema={schema}
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "26px", md: "30px", lg: "35px" },
              lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
              fontWeight: 700,
              textAlign: "center",
              color: "#3D2E57",
              mt: 5,
              mb: 3,
            }}>
            Verify via phone
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                width: {
                  xs: "100%",
                  sm: "74%",
                  md: "68%",
                  lg: "65%",
                  xl: "65%",
                },
                fontSize: "18px",
                lineHeight: "21px",
                fontWeight: "400",
                textAlign: "center",
                color: "#3D2E57",
                mb: 4,
              }}>
              Please verify your account by entering mobile number and we will send you <b>One Time Password</b> on this number
            </Typography>
          </Box>

          <InputField
            control={control}
            helperText={errors?.phone?.message}
            errors={!!errors.phone}
            placeholder={"Enter your Phone Number"}
            type={"text"}
            variant="filled"
            formlabel="Phone number"
            size={{ xs: "20px", md: "22px", lg: "24px" }}
            color={"#333333"}
            name="phone"
            required={"*"}
            onChangeSelect={handleChange}
            phoneCode={true}
            countryPhoneCode={countryPhoneCode}
            country_names={country_names}
            InputProps={{
              disableUnderline: true,
              pt: "10px",
            }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <Link
              to={LOGIN}
              className="fontlink1"
              style={{
                fontWeight: 400,
                color: "#3D2E57",
                textDecoration: "none",
                marginTop: "10px",
              }}>
              Skip Now
            </Link>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <Button
              sx={{
                width: {
                  xs: "100%",
                  sm: "90%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%",
                },
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                  border: "1.5px solid #3D2E57",
                },
                color: "#3D2E57",
                border: "1.5px solid #3D2E57",
                // p: 1,
                mt: 3,
                mb: 5,
                borderRadius: "15px",
                fontSize: { xs: "20px", md: "22px", lg: "24px" },
                fontWeight: 500,
                textTransform: "none",
              }}
              // color="warning"
              variant="outlined"
              type="submit">
              Send OTP
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getPhoneVerify: (item) => dispatch(getPhoneVerify(item)),
    country_dropdown_data: () => dispatch(country_dropdown_data()),
  };
}
export default connect(null, mapDispatchToProps)(PhoneVerify);
