// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storage } from "../../config/storage";
import CategoryApi from "../../services/GreetingCard";
import RegisterApi from "../../services/Register";
import ProfileApi from "../../services/MyProfile";
import CartApi from "../../services/Cart";
import StripeApi from "../../services/Stripe";
import GiftCardApi from "../../services/E-Gift-Card";
import {
  REGISTER_USER,
  LOGIN_USER,
  CATEGORY_LIST,
  CARD_LISTING,
  FORGOT_GEN_OTP,
  RESET_PASSWORD,
  FORGOT_MATCH_OTP,
  PHONE_VERIFY,
  EMAIL_RESEND_OTP,
  OTP_VERIFY,
  EMAIL_OTP,
  CARD_DETAIL,
  CARD_IN_CARTS,
  USER_DATA,
  ADD_GREETING_CARD_TO_CARD,
  UPDATE_USER,
  ADD_MESSAGE,
  CHECK_MESSAGE,
  CHECK_RECIPENTS,
  REMOVE_ITEM,
  CHECKOUT,
  SHOW_COUNTRY_DATA,
  UPDATE_MSG,
  HANDLE_QTY,
  ADDRESS_LIST,
  E_GIFT_CARD_AMOUNT,
  E_GIFT_CARD_GIF,
} from "./actionCreator";
import Gift_CardAPi from "../../services/GiftCard";
// const navigate=useNavigate()
export const RegisterUser = (payload) => {
  return {
    type: REGISTER_USER,
    payload,
  };
};
export const LogInUser = () => {
  return {
    type: LOGIN_USER,
  };
};
export const userdata = () => {
  return {
    type: USER_DATA,
  };
};
export const updateuser = (payload) => {
  return {
    type: UPDATE_USER,
    payload,
  };
};
export const CategoryList = (payload) => {
  return {
    type: CATEGORY_LIST,
    payload,
  };
};
export const Card_detail = (payload) => {
  return {
    type: CARD_DETAIL,
    payload,
  };
};
export const Card_message = (payload) => {
  return {
    type: ADD_MESSAGE,
    payload,
  };
};

export const remove_Item = (payload) => {
  return {
    type: REMOVE_ITEM,
    payload,
  };
};
export const Check_Message = (payload) => {
  return {
    type: CHECK_MESSAGE,
    payload,
  };
};
export const Check_Recipents = (payload) => {
  return {
    type: CHECK_RECIPENTS,
    payload,
  };
};
export const ShowCardInCard = (payload) => {
  return {
    type: CARD_IN_CARTS,
    payload,
  };
};
export const ForgotPassGenOtp = (payload) => {
  return {
    type: FORGOT_GEN_OTP,
    payload,
  };
};
export const MatchPassOtp = (payload) => {
  return {
    type: FORGOT_MATCH_OTP,
    payload,
  };
};
export const ResetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const CardList = (payload) => {
  return {
    type: CARD_LISTING,
    payload,
  };
};
export const AddressList = (payload) => {
  return {
    type: ADDRESS_LIST,
    payload,
  };
};
export const Phoneverify = (payload) => {
  return {
    type: PHONE_VERIFY,
    payload,
  };
};
export const VerifyOtp = (payload) => {
  return {
    type: OTP_VERIFY,
    payload,
  };
};

export const RegisterOtp = (payload) => {
  return {
    type: EMAIL_OTP,
    payload,
  };
};
export const EmailResendOtp = (payload) => {
  return {
    type: EMAIL_RESEND_OTP,
    payload,
  };
};
export const addGreetingCardtoCart = (payload) => {
  return {
    type: ADD_GREETING_CARD_TO_CARD,
    payload,
  };
};
export const checkout_Item = (payload) => {
  return {
    type: CHECKOUT,
    payload,
  };
};
export const Update_Msg = (payload) => {
  return {
    type: UPDATE_MSG,
    payload,
  };
};
export const handle_Qty = (payload) => {
  return {
    type: HANDLE_QTY,
    payload,
  };
};
export const country_data = (payload) => {
  return {
    type: SHOW_COUNTRY_DATA,
    payload,
  };
};
export const e_gift_card_amount = (payload) => {
  return {
    type: E_GIFT_CARD_AMOUNT,
    payload,
  };
};
export const e_gift_card_gif = (payload) => {
  return {
    type: E_GIFT_CARD_GIF,
    payload,
  };
};

export const getCategoryName = (categoryData) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCategoryList(categoryData);

    if (response.status) {
      dispatch(CategoryList(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};
export const getCardListing = (categoryData) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCardListing(categoryData);
    if (response.status) {
      dispatch(CardList(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};

export const getCarddetail = (carddetail) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCarddetail(carddetail);

    if (response.status) {
      dispatch(Card_detail(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};

export const getOrderDetail = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestOrderdetail(data);

    if (response.status) {
      // dispatch(Order_detail(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};
export const getItemDetail = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestItemdetail(data);

    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};

export const getAddresses = (apiKey) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestAddressList(apiKey);
    if (response.status) {
      dispatch(AddressList(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};
export const getUserAddress = (apiKey) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestUserAddress(apiKey);
    if (response.status) {
      dispatch(AddressList(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};
export const SaveAddressList = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestSaveAddress(data);
    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};
export const getmessage = (carddetail) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestMessageCardDetails(carddetail);

    if (response.status) {
      dispatch(Card_message(response.data));
      return response;
    } else {
    }
  } catch (err) {}
};
export const check_message = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCheckMsg(data);

    if (response.status) {
      dispatch(Check_Message(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const check_recipent = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCheckrecipent(data);
    if (response.data.status) {
      dispatch(Check_Recipents(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const getRegisterUser = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestUserRegister(data);

    if (response?.data?.status) {
      dispatch(RegisterUser(response?.data));
      toast.success(response?.data?.message);
      storage.set.userId(response?.data?.user);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getLoginWithPhone = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestLoginWithPhone(data);

    if (response?.data?.status) {
      dispatch(RegisterUser(response?.data));
      toast.success(response?.data?.message);
      storage.set.userId(response?.data?.user);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getLoginWithEmailOTP = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestLoginWithEmailOTP(data);

    if (response?.data?.status) {
      dispatch(RegisterUser(response?.data));
      toast.success(response?.data?.message);
      storage.set.userId(response?.data?.user);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const verifyEmailOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestverifyEmailOtp(data);

    if (response?.data?.status) {
      dispatch(RegisterOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const verifyPhoneOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestverifyPhoneOtp(data);

    if (response?.data?.status) {
      dispatch(RegisterOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getForgotOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestForgotPassword(data);

    if (response?.data?.status) {
      dispatch(ForgotPassGenOtp(response?.data));
      storage.set.forgotToken(response?.data?.user_id);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};

export const getPhoneVerify = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestPhoneVerify(data);
    if (response?.data?.status) {
      dispatch(Phoneverify(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getMatchOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestMatchOtp(data);

    if (response?.data?.status) {
      dispatch(MatchPassOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getOtpVerify = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestOtpVerify(data);
    if (response?.data?.status) {
      dispatch(VerifyOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};

export const getResetPassword = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestResetPassword(data);

    if (response?.data?.status) {
      dispatch(ResetPassword(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};

export const getEmailOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestEmailOtp(data);

    if (response?.data?.status) {
      dispatch(RegisterOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getLogin = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestLogin(data);

    if (response?.data) {
      dispatch(LogInUser(response?.data));
      // toast.success(response?.data?.message)
      storage.set.carts_id(response?.data?.carts_id)
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getGuestRegister = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestGuestRegister(data);

    if (response?.data) {
      dispatch(LogInUser(response?.data));
      // toast.success(response?.data?.message)
      // storage.set.authToken(response?.data?.token)
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getEmailResendOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestEmailResendOtp(data);

    if (response?.data?.status) {
      dispatch(EmailResendOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getForgotResendOtp = (data) => async (dispatch) => {
  try {
    let response = await RegisterApi.requestForgotResendOtp(data);

    if (response?.data?.status) {
      dispatch(EmailResendOtp(response?.data));
      toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getuserdata = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestuserdata(data);

    if (response.status) {
      dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const updateUser = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestupdateuser(data);

    if (response.status) {
      dispatch(updateuser(response.data.user_details));
      return response;
    } else {
      return response;
    }
  } catch (err) {}
};
export const addtoCartGreetingCard = (data) => async (dispatch) => {
  try {
    let response = await CartApi.requestAddtocart(data);
    //  console.log('ram',storage.fetch.carts_id(),response.status,response);
    if (response.data.status) {
      dispatch(addGreetingCardtoCart(response.data));
      toast.success(response?.data?.message);
      // console.log("carts_id",response?.data?.data?.carts_id)
      storage.set.carts_id(response?.data?.data?.carts_id);
      return response;
    } else {
      // console.log('shiv');
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
    // console.log('hanuman',storage.fetch.carts_id());
  } catch (err) {}
};
export const showCardinCarts = (data) => async (dispatch) => {
  try {
    let response = await CartApi.requestCartItem(data);

    if (response.data.status) {
      dispatch(ShowCardInCard(response?.data));
      // storage.set.carts_id(response?.data?.data?.carts_id)
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const removeItem = (data) => async (dispatch) => {
  try {
    let response = await CartApi.requestremoveItem(data);

    if (response.data.status) {
      dispatch(remove_Item(response?.data));
      if (response?.data?.data?.is_empty) {
        dispatch(ShowCardInCard([]));
      }
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const checkout = (data) => async (dispatch) => {
  let response = await CartApi.requestCheckout(data);
  dispatch(checkout_Item(response?.data));
};
export const updateMsg = (data) => async (dispatch) => {
  try {
    let response = await CartApi.requestupdateMsg(data);

    if (response?.data?.status) {
      dispatch(Update_Msg(response?.data));
      return response;
    } else {
      if (response.data.errors.length) {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
      } else {
        return toast.error(response?.data?.message);
      }
    }
  } catch (err) {}
};
export const handleQty = (data) => async (dispatch) => {
  try {
    let response = await CartApi.requesthandleQty(data);

    if (response.status) {
      dispatch(handle_Qty(response?.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const country_dropdown_data = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestCountryData(data);

    if (response.status) {
      dispatch(country_data(response?.data));
      // toast.success(response?.data?.message);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const stripePayment = (data) => async (dispatch) => {
  try {
    let response = await StripeApi.requestStripePayment(data);

    if (response.data.status) {
      return response;
    } else {
      return toast.error(response.data.message);
    }
  } catch (err) {}
};
export const get_e_gift_card_amount = (data) => async (dispatch) => {
  try {
    let response = await GiftCardApi.request_e_gift_card_amount(data);
    if (response.data.status) {
      dispatch(e_gift_card_amount(response?.data));
      return response;
    } else {
      return toast.error(response.data.message);
    }
  } catch (err) {}
};
export const get_e_gift_card_gif = (data) => async (dispatch) => {
  try {
    let response = await GiftCardApi.request_e_gift_card_gif(data);
    if (response.data.status) {
      dispatch(e_gift_card_gif(response?.data));
      return response;
    } else {
      return toast.error(response.data.message);
    }
  } catch (err) {}
};
export const get_gift_card_amount = (data) => async (dispatch) => {
  try {
    let response = await Gift_CardAPi.requestGiftCardAmount(data);
    if (response.data.status) {
      dispatch(e_gift_card_amount(response?.data));
      return response;
    } else {
      return toast.error(response.data.message);
    }
  } catch (err) {}
};

export const saveVideo = (data) => async (dispatch) => {
  try {
    let response = await GiftCardApi.saveVideoApi(data);
    if (response.data.status) {
      storage.set.item_id(response?.data?.data?.media_file_id);
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
    }
  } catch (err) {}
};
export const getReminders = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestReminders(data);

    //   if (response.status) {
    //     dispatch(ShowCardInCard(response?.data));
    //     storage.set.carts_id(response?.data?.data?.carts_id)

    return response;
    //   } else {
    //     response?.data?.errors?.map((item) => {
    //       return toast.error(item);
    //     });
    //   }
  } catch (err) {}
};
export const removeReminder = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestremoveReminder(data);

    // if (response.status) {
    //   dispatch(ShowCardInCard(response?.data));
    //   storage.set.carts_id(response?.data?.data?.carts_id)

    return response;
    // } else {
    //   response?.data?.errors?.map((item) => {
    //     return toast.error(item);
    //   });
    // }
  } catch (err) {}
};
export const updateReminders = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestupdateReminders(data);

    // if (response.status) {
    //   dispatch(ShowCardInCard(response?.data));
    //   storage.set.carts_id(response?.data?.data?.carts_id)

    return response;
    // } else {
    //   response?.data?.errors?.map((item) => {
    //     return toast.error(item);
    //   });
    // }
  } catch (err) {}
};
// export const getAddresses = (apiKey) => async (dispatch) => {

//   try {

//     let response = await CategoryApi.requestAddressList(apiKey);

//     if (response.status) {
//       dispatch(AddressList(response.data));
//       return response;
//     } else {
//     }
//   } catch (err) {
//   }
// };
export const deleteAddress = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestDeleteAddress(data);

    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};
export const AddAddressList = (data) => async (dispatch) => {
  try {
    let response = await CategoryApi.requestAddAddress(data);

    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};
export const VideoPreview = (data) => async (dispatch) => {
  try {
    let response = await GiftCardApi.requestVideoPreview(data);
    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};
export const getOrders = (data) => async (dispatch) => {
  try {
    let response = await ProfileApi.requestgetOrders(data);
    if (response.status) {
      return response;
    } else {
    }
  } catch (err) {}
};
