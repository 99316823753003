/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { connect, useSelector } from "react-redux";
import { showCardinCarts } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ViewCartDetailsAccordian from "./ViewCartDetailsAccordian";
import { storage } from "../../config/storage";
import GuestUserModal from "../Custom/CustomPopup/GuestUserModal";
import LoginWithOTPModal from "../Custom/CustomPopup/LoginWithOTPModal";
import LoginWithPassModal from "../Custom/CustomPopup/LoginWithPassModal";
import Toastify from "../SnackBar/Toastify";
import { CATEGORIES } from "../routes/ConstURL";
const FirstCheckout = ({ showCardinCarts }) => {
  const [openPopup, setOpenPopup] = useState("");
  // const [newState, setNewState] = useState("loading");
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const newState = useSelector((state) => state.User?.cart_item);
  const carts_id = storage.fetch.carts_id();
  let id = {};
  if (carts_id) {
    id = { carts_id: carts_id };
    // console.log("jai shri ram")
  }
  useEffect(() => {
    setOpen(true);
    if (carts_id) {
      showCardinCarts(id).then((res) => {
        if (res.data.status) {
          setOpen(false);
          // setNewState(res.data.data);
        } else {
        }
      });
    } else {
      setOpen(false);
      // toast.error("Cart is empty");
    }
    window.scrollTo(0, 0);
  }, [carts_id]);

  const onCheckout = () => {
    if (storage.fetch.authToken()) {
      navigate("/mycart/payment", { state: newState?.net_amount });
    } else {
      setOpenPopup("GuestModalActivate");
    }
  };
  // console.log(open);

  return (
    <>
      {!carts_id && (
        <Box
          sx={{
            minHeight: 100,
          }}>
          <Box
            sx={{
              pt: 15,
              pb: 15,
              bgcolor: "#F3F0EE",

              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}>
            <StorefrontIcon
              sx={{
                fontSize: { xs: "70px", md: "100px" },
                width: "100%",
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: "15px", md: "20px" },
                textAlign: "center",
                width: "100%",
              }}>
              Cart is Empty
            </Typography>
            <Button
              disableRipple
              onClick={() => navigate(CATEGORIES)}
              sx={{
                width: { xs: "35%", md: "15%" },
                border: "1px solid #3D2E57",
                color: "#3D2E57",
                fontWeight: "500",
                borderRadius: "15px",
                fontSize: { xs: 14, md: 16 },
                textTransform: "none",
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                  border: "1px solid #3D2E57",
                },
              }}
              variant="outlined">
              Shop Now
              <ArrowForwardIos sx={{ ml: 2, fontSize: { xs: 14, md: 16 } }} />
            </Button>
          </Box>
        </Box>
      )}
      {newState && carts_id && (
        <Box sx={style.mainBox}>
          <Box
            sx={{
              display: "flex",
              pt: "40px",
              pb: "40px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: 3,
                minHeight: 450,
                width: "100%",
                flexDirection: "column",
              }}>
              <Typography sx={style.heading}>My Cart</Typography>
              <Typography sx={style.subheading}>Cart items</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                {open &&
                  [1, 2, 3, 4].map((item, index) => (
                    <Box
                      sx={{
                        minHeight: { xs: 100, md: 140 },
                        width: "90%",
                        borderTop: 0.5,
                        borderBottom: 0.5,
                        borderColor: "grey.300",
                      }}
                      key={index}>
                      <ViewCartDetailsAccordian loading={true} item={item} newState={newState} />
                    </Box>
                  ))}
                {!open &&
                  newState?.cart_items?.map((item, index) => (
                    <Box
                      sx={{
                        minHeight: { xs: 100, md: 140 },
                        width: { xs: "100%", sm: "90%" },
                        borderTop: 0.5,
                        borderBottom: 0.5,

                        borderColor: "grey.300",
                      }}
                      key={index}>
                      <ViewCartDetailsAccordian item={item} newState={newState} />
                    </Box>
                  ))}
                <Box
                  sx={{
                    width: "87%",
                    height: 60,
                    display: "flex",
                    justifyContent: "space-between",
                    pl: { lg: 3, md: 0, sm: 10, xs: 0 },
                    pr: { xl: 15, lg: 13, md: 5, sm: 15, xs: 0 },
                  }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 22 },
                      width: "50%",
                      pl: { xl: 5, lg: 5, md: 2, sm: 4, xs: 0 },
                      mt: "15px",
                    }}>
                    Service Charges
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 20 },
                      pr: { xl: 0, lg: 0, sm: 0, md: 4 },
                      mt: "15px",
                    }}>
                    $ {newState && newState?.service_charge}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "87%",
                    height: 60,
                    display: "flex",
                    justifyContent: "space-between",
                    pl: { xl: 3, lg: 0, md: 0, sm: 0 },
                    pr: { xl: 15, lg: 0, md: 5, sm: 0, xs: 0 },
                    borderBottom: "1px solid lightgray",
                  }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 22 },
                      width: "50%",
                      pl: { xl: 5, lg: 0, md: 2, sm: 1.5, xs: 0 },
                    }}>
                    Sub-Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 20 },
                      pr: { xl: 0, lg: 5, sm: 0, md: 4 },
                    }}>
                    $ {newState && newState?.total_amount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "87%",
                    height: 60,
                    display: "flex",
                    justifyContent: "space-between",
                    pl: { lg: 3, md: 0, sm: 0 },
                    pr: { xl: 15, lg: 13, md: 5, sm: 0, xs: 0 },
                    pb: 2,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 22 },
                      width: "50%",
                      pl: { xl: 5, lg: 5, md: 2, sm: 4, xs: 0 },
                    }}>
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: 18, md: 20 },
                      pr: { xl: 0, lg: 0, sm: 0, md: 4 },
                    }}>
                    $ {newState && newState?.net_amount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    justifyContent: { sm: "flex-end", xs: "center" },
                    pb: 5,
                  }}>
                  <Button
                    onClick={onCheckout}
                    sx={{
                      width: { md: "25%", xs: "90%" },
                      border: "1px solid #3D2E57",
                      color: "#3D2E57",
                      fontSize: "16px",
                      fontWeight: "700",
                      borderRadius: "15px",
                      textTransform: "none",
                    }}
                    variant="outlined">
                    checkout
                    <ArrowForwardIos sx={{ ml: 2, fontSize: 20 }} />
                  </Button>
                  <Toastify />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <GuestUserModal
        openModal={openPopup === "GuestModalActivate"}
        net_amount={newState?.net_amount}
        width={"40%"}
        height={"80%"}
        onCloseModal={() => setOpenPopup("")}
        onClickModal2={() => setOpenPopup("LoginWithOTP")}
        onClickModal1={() => setOpenPopup("LoginWithPass")}
      />
      <LoginWithOTPModal
        openModal={openPopup === "LoginWithOTP"}
        net_amount={newState?.net_amount}
        width={"40%"}
        height={"50%"}
        onCloseModal={() => setOpenPopup("")}
        onClickModal2={() => setOpenPopup("LoginWithPass")}
        onClickModal1={() => setOpenPopup("GuestModalActivate")}
      />
      <LoginWithPassModal
        net_amount={newState?.net_amount}
        width={"40%"}
        height={"60%"}
        onCloseModal={() => setOpenPopup("")}
        openModal={openPopup === "LoginWithPass"}
        onClickModal2={() => setOpenPopup("LoginWithOTP")}
        onClickModal1={() => setOpenPopup("GuestModalActivate")}
      />
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    showCardinCarts: (id) => dispatch(showCardinCarts(id)),
  };
}
export default connect(null, mapDispatchToProps)(FirstCheckout);
const style = {
  outerBox: {
    height: "100%",
    bgcolor: "#F3F0EE",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    pt: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
  },
  mainBox: {
    height: "50%",
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    minHeight: "500px",
    bgcolor: "#F3F0EE",
    maxWidth: "1360px",
    ml: "auto",
    mr: "auto",
  },
  headerTxt: {
    mt: { xs: 1, md: 3 },
    mb: 2,
    textTransform: "uppercase",
    pl: { xs: 1, sm: 6, md: 8, lg: 8 },
    pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
  header: {
    width: "100%",
    fontWeight: "700",
    fontSize: "14px",
    alignItems: "center",
    height: {
      xs: "45px",
      sm: "40px",
      md: "40px",
      lg: "60px",
      xl: "60px",
    },
    color: "#3D2E57",
    bgcolor: "#F9F7F6",
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    color: "#3D2E57",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: { xs: "24px", md: "35px" },
    lineHeight: "42px",
    pb: 2,
    pt: 3,
    pl: { xs: 1, sm: 6, md: 8, lg: 7 },
    pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
  subheading: {
    color: "#3D2E57",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    display: {
      xs: "none",
      sm: "flex",
    },
    pb: 2,
    pt: 3,
    pl: { xs: 1, sm: 6, md: 8, lg: 7 },
    pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
};
