import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../config/storage";
import Recipients from "../Custom/Recipients";
import { EGIFT_GIF_DELIVERY, EGIFT_VIDEO_DELIVERY } from "../routes/ConstURL";
const EgiftRecipients = () => {
  const location = useLocation();
  if (location?.state?.id) {
    storage.set.item_id(location?.state?.id);
  }
  return (
    <Recipients
      egift={true}
      route={location?.state?.id ? EGIFT_GIF_DELIVERY : EGIFT_VIDEO_DELIVERY}
      headerText={"E-Gift card / select amount / Add Video Message / Enter Details "}
      heading={"Recipient Details"}
      recipient={true}
      buttonText={"Continue"}
      bgcolor={"#F3F0EE"}
    />
  );
};

export default EgiftRecipients;
