import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Typography, Table, TableRow, TableCell, TableHead, TableBody, Pagination, Skeleton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoaderComponent from "../Loader/LoaderComponent";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import { getOrderDetail } from "../../redux/action/index";
import { storage } from "../../config/storage";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import { LOGIN, MYORDER, MYORDER_ITEMDETAIL } from "../routes/ConstURL";

const MyOrderDetail = ({ getOrderDetail }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  let id = location?.state?.id;
  if (id) {
    storage.set.order_id(id);
  }
  let id_storage = storage.fetch.order_id();
  if (id_storage) {
    id = id_storage;
  } else {
    setTimeout(() => {
      if (storage.fetch.authToken()) {
        navigate(MYORDER);
      } else {
        navigate(LOGIN);
      }
    }, 3000);
  }
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [newState, setnewState] = useState({
    order_details: {
      order_number: "",
      total_amount: "",
      created_at: "",
      status: "",
    },
    order_items: [
      {
        item_total_price: "",
        item_qty: "",
        item_title: "",
        item_type_formatted: "",
        img: "",
        file_type: 0,
        status: "",
      },
    ],
    filtered_records: 1,
    total_records: 1,
    pages: 1,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = { order_id: id };
    if (!id_storage) {
      toast.error("please select a order first");
    }
    getOrderDetail(data).then((res) => {
      setLoading(false);
      if (res.data.data?.order_items?.length === 0) {
        setPages(1);
      }
      if (res?.data?.status) {
        setPages(res.data.data.pages);
        setnewState(res?.data?.data);
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
      }}>
      <LoaderComponent open={loading} />
      <Toastify />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          borderRadius: 4,
          justifyContent: "center",
          mb: 5,
        }}>
        <Typography sx={Style.typographyStyle}>Order Detail</Typography>
        {loading && (
          <>
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} animation="wave" />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} animation="wave" />
            <Skeleton width={"35%"} height={40} sx={{ ml: 3, mb: 2 }} animation={false} />
          </>
        )}
        {!loading && (
          <Box
            sx={{
              mb: 2,
              pl: { xs: 3, sm: 5 },
              pr: 5,
              mt: -1,
              mb: -2,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mr: { sm: 3, md: 5 },
                ml: { xs: 0, sm: 3 },
              }}>
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                {newState?.order_details?.order_number && <Typography style={Style.typographyStyle1}>Order Number:</Typography>}
                {newState?.order_details?.total_amount && <Typography style={Style.typographyStyle1}>Order Total:</Typography>}
                {newState?.order_details?.created_at && <Typography style={Style.typographyStyle1}>Order Date:</Typography>}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {newState?.order_details?.order_number && (
                  <Typography style={Style.typographyStyle1}>{newState?.order_details?.order_number}</Typography>
                )}
                {newState?.order_details?.total_amount && (
                  <Typography style={Style.typographyStyle1}>${newState?.order_details?.total_amount}</Typography>
                )}
                {newState?.order_details?.created_at && (
                  <Typography style={Style.typographyStyle1}>{newState?.order_details?.created_at}</Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mr: { sm: 3, md: 5 },
              }}>
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                {newState?.order_details?.status && <Typography style={Style.typographyStyle1}>Status:</Typography>}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {newState?.order_details?.status && (
                  <Typography
                    style={Style.typographyStyle1}
                    sx={{
                      color: newState?.order_details?.status.toLowerCase() === "completed" ? "#219653" : "#FF8D2A",
                    }}>
                    {newState?.order_details?.status}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Typography sx={Style.typographyStyle}>Item information</Typography>
        <Box sx={{ overflow: "auto", ml: { xs: 1, sm: 6 }, mr: 3, mt: -4 }}>
          <Table sx={{ width: { lg: "100%", xs: "1000px" } }}>
            <TableHead>
              <TableRow>
                <TableCell style={Style.tableCell}>Item</TableCell>
                <TableCell> </TableCell>
                <TableCell style={Style.tableCell}>Price</TableCell>
                <TableCell style={Style.tableCell}>QTY</TableCell>
                <TableCell style={Style.tableCell}>Type</TableCell>
                <TableCell style={Style.tableCell}>Status</TableCell>
                <TableCell style={Style.tableCell}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newState?.order_items?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {loading && <Skeleton variant="rectangular" sx={{ height: { xs: 100, md: 65 } }} width={60} />}
                    {!loading && (
                      <Box
                        sx={{
                          height: { xs: 100, md: 65 },
                          // width: '70%',
                          border: "1px solid rgba(61, 46, 87, 0.4)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "7px",
                        }}>
                        {(item?.file_type === FILE_TYPE.IMAGE || item?.file_type === FILE_TYPE.GIF) && (
                          <img alt="logo1" style={{ height: 50, width: 40 }} src={item?.img} />
                        )}
                        {item?.file_type === FILE_TYPE.VIDEO && (
                          <video width="60" height="65" controls>
                            <source src={item?.img} />
                          </video>
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && ""}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && "$ " + item?.item_total_price}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && (item?.item_qty === "" ? "__" : item?.item_qty)}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && item?.item_type_formatted}
                  </TableCell>
                  <TableCell
                    style={Style.tableCell1}
                    sx={{
                      color: item?.status.toLowerCase() === "completed" ? "#219653" : "#FF8D2A",
                    }}>
                    {!loading && item?.status}
                  </TableCell>
                  <TableCell>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && (
                      <Link
                        to={MYORDER_ITEMDETAIL}
                        // to={`/myaccount/myorder/myorderdetail/itemdetail/${item.order_items_id}`}
                        state={{
                          id: item.order_items_id,
                        }}
                        style={Style.linkButton}>
                        View detail
                        <ArrowForwardIosIcon sx={{ ml: "5px", fontSize: 15 }} />
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 3,
          }}>
          {pages > 1 && <Pagination count={pages} page={page} boundaryCount={2} onChange={handlePageChange} />}
        </Box>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetail: (data) => dispatch(getOrderDetail(data)),
  };
}
export default connect(null, mapDispatchToProps)(MyOrderDetail);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#FFE7CA",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    pl: { xs: 2, sm: 5 },
    mb: 3,
    mt: 3,
    ml: { xs: 1, sm: 3 },
    mr: { xs: 1, sm: 3 },
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    minWidth: "117.93px",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
  },
  linkButton: {
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "15px",
    border: "1px solid #3D2E57",
    color: "#3D2E57",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    cursor: "pointer",
    height: "21px",
    maxWidth: "126px",
  },
};
