/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@mui/material";
import Recipients from "../Custom/Recipients";
import { useLocation } from "react-router-dom";

const AddNewAddress = ({setAccountData}) => {
const params=useLocation()
React.useEffect(() => {
  
}, [])

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        mb:2,
        width: {xs:"99%",md:"75%",lg:"81%",xl:"83%"},
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          alignContent: "center",
          borderRadius: 4,
          justifyContent: "center",
        }}
      >
        <Recipients
          heading={"Add new address"}
          buttonText={"Add address"}
          validate={true}
          editAddressId={params?.state?.id}
          setAccountData={setAccountData}
        />
      </Box>
    </Box>
  );
};

export default AddNewAddress;

