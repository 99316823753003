/* eslint-disable eqeqeq */
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { getuserdata, updateUser, country_dropdown_data } from "../../redux/action";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import LoaderComponent from "../Loader/LoaderComponent";
import { Button, Skeleton, Typography, TextField, FormLabel, Box, Radio, Select, MenuItem } from "@mui/material";

const Style = {
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#333333",
  },
  typographyStyle: {
    fontSize: { xs: "24px", md: "35px" },
    fontWeight: { xs: "400", md: "700" },
    lineHeight: { xs: "29px", md: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
    pb: 3,
  },
  inputStyle: {
    width: {
      xs: "100%",
      sm: "100%",
      md: "48%",
      lg: "49%",
      xl: "49%",
    },
    mb: 2,
  },
  star: {
    color: "red",
  },
  rowBoxStyle: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
  },
  validationStyle: {
    color: "red",
    margin: "10px",
  },
};

const MyProfile = ({ getuserdata, updateUser, country_dropdown_data }) => {
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("61");
  const [countryNumberRange, setCountryNumberRange] = React.useState({
    max: 9,
    min: 9,
  });

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    email: "",
    phone: "",
    wedding_date: "",
    password: "",
    confirm_password: "",
    phonecode: "",
    validate_Password: false,
  });
  const [radioButton, setRadioButton] = useState("");
  const [country_names, setCountry_names] = useState([]);
  const schema = yup.object().shape({
    first_name: yup.string().required("Please enter your first name"),
    last_name: yup.string().required("Please enter your last name"),
    email: yup.string().required("Please enter your email").email("Please enter valid email"),
    phone: yup
      .string()
      // .matches(/^[0-9\s]*$/, "Please enter valid phone number"),
      .required("Please enter your phone number")
      .min(countryNumberRange.min, `Enter minimum ${countryNumberRange.min} numbers `)
      .max(countryNumberRange.max, `Enter maximum ${countryNumberRange.max} numbers`),

    dob: yup.string().required("Please enter your Date of birth"),
    validate_Password: yup.boolean(),
    password: yup.string().when("validate_Password", {
      is: true,
      then: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
    }),
    confirm_password: yup.string().when("validate_Password", {
      is: true,
      then: yup
        .string()
        .required("Confirm your password.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    }),
    // notify_reminder_via:yup.number()
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    country_dropdown_data().then((res) => {
      if (res.data.status) {
        setCountry_names([...res.data.data.country_list]);
      }
    });
    setLoading(true);
    getuserdata().then((res) => {
      setLoading(false);
      if (res) {
        const result = res?.data?.data;
        setUserData({
          first_name: result.user_details.first_name,
          last_name: result.user_details.last_name,
          dob: result.user_details.dob,
          email: result.user_details.email,
          phone: result.user_details.phone,
          wedding_date: result.user_details.wedding_date,
          notify_reminder_via: result.user_details.notify_reminder_via,
        });
        setRadioButton(result.user_details.notify_reminder_via);
        setCountryPhoneCode(result.user_details.phonecode);
        setCountryNumberRange({
          max: result?.user_details?.sizeof_nn?.max,
          min: result?.user_details?.sizeof_nn?.min,
          id: result?.user_details?.country_id,
        });
      } else {
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: userData,
    validationSchema: schema,
    onSubmit: (value) => {
      setUserData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = (value) => {
    value.notify_reminder_via = radioButton;
    value.phonecode = countryPhoneCode;
    value.country_id = countryNumberRange.id;
    if (!value.validate_Password) {
      delete value.password;
      delete value.confirm_password;
      delete value.validate_Password;
    } else {
      delete value.validate_Password;
    }
    setLoading(true);
    updateUser(value).then((res) => {
      if (res.data.status) {
        toast.success("Updated Successfully!");
        country_dropdown_data();
        getuserdata().then((res) => {
          setLoading(false);
          if (res.data.status) {
            const result = res.data.data.user_details;
            setUserData(result);
          } else {
            res?.data?.errors?.map((item) => {
              toast.error(item);
            });
          }
        });
      } else {
        res?.data?.errors?.map((item) => {
          toast.error(item);
        });
        setLoading(false);
      }
    });
  };
  const handleToggle = (e) => {
    setRadioButton(e.target.value);
  };
  const handleChange = (e) => {
    setCountryPhoneCode(e.target.value.phone_code);
    setCountryNumberRange({
      max: e.target.value.sizeof_nn.max,
      min: e.target.value.sizeof_nn.min,
      id: e.target.value.country_id,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignContent: "center",
        width: { xs: "99%", md: "75%", lg: "81%", xl: "83%" },
        pb: 5,
      }}>
      <LoaderComponent open={loading} />
      <Box
        sx={{
          bgcolor: "white",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          alignContent: "center",
          borderRadius: 4,
          justifyContent: "center",
        }}>
        <Toastify />
        <Box
          sx={{
            width: "100%",
            p: 3,
          }}>
          <form name="RegisterForm" onSubmit={formik.handleSubmit}>
            <Typography sx={Style.typographyStyle}>My profile</Typography>
            <Box
              sx={{
                width: "100%",
                height: "30%",
                borderRadius: 2,
              }}>
              <Box sx={Style.rowBoxStyle}>
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      First Name
                      <span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter First Name here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.first_name && formik.touched.first_name ? (
                      <p style={Style.validationStyle}>{formik.errors.first_name}</p>
                    ) : null}
                  </Box>
                )}
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Last Name
                      <span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="last_name"
                      value={formik.values.last_name}
                      id="last_name"
                      onChange={formik.handleChange}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      autoComplete="false"
                      color="primary"
                      placeholder="Enter Last Name here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                    />
                    {formik.errors.last_name && formik.touched.last_name ? (
                      <p style={Style.validationStyle}>{formik.errors.last_name}</p>
                    ) : null}
                  </Box>
                )}
              </Box>
              <Box sx={Style.rowBoxStyle}>
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Date of Birth
                      <span style={Style.star}>*</span>
                    </FormLabel>
                    <TextField
                      name="dob"
                      value={formik.values.dob}
                      id="dob"
                      onChange={formik.handleChange}
                      type="date"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      autoComplete="false"
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                        display: true,
                        disableRipple: true,
                        dispatch: true,
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter username here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                    />

                    {formik.errors.dob && formik.touched.dob ? <p style={Style.validationStyle}>{formik.errors.dob}</p> : null}
                  </Box>
                )}
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Email
                      <span style={Style.star}>*</span>
                    </FormLabel>
                    <TextField
                      name="email"
                      value={formik.values.email}
                      id="email"
                      autoComplete="false"
                      onChange={formik.handleChange}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter Email here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                    />
                    {formik.errors.email && formik.touched.email ? <p style={Style.validationStyle}>{formik.errors.email}</p> : null}
                  </Box>
                )}
              </Box>

              <Box sx={Style.rowBoxStyle}>
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Phone Number
                      <span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="phone"
                      value={formik.values.phone}
                      id="phone"
                      onChange={formik.handleChange}
                      type="phone"
                      variant="filled"
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      autoComplete="false"
                      color="primary"
                      placeholder="Enter Phone Number here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { backgroundColor: "#F6F6F6" },
                        pt: "10px",
                        startAdornment: (
                          <Select
                            disableUnderline
                            SelectDisplayProps={{
                              style: {
                                padding: 0,
                                backgroundColor: "#F6F6F6",
                                color: "#A8A8A8",
                              },
                            }}
                            sx={{
                              height: "30px",
                              width: "90px",
                              // backgroundColor: "rgba(0, 0, 0, 0.06)",
                              borderBottom: "none",
                              "&.MuiOutlinedInput-notchedOutline": {
                                bgcolor: "red",
                              },
                              "&.MuiInputBase-root": {
                                backgroundColor: "transparent",
                              },
                            }}
                            MenuProps={{ disableScrollLock: true }}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            // defaultValue="select"
                            value={countryPhoneCode}
                            onChange={handleChange}
                            label="Country"
                            inputProps={{
                              style: {
                                border: "none",
                              },
                            }}>
                            {/* {country_names &&editAddressId&&
                    country_names.map((item, index) => (
                      <MenuItem value={formik.values.recipient_country}>{formik.values.recipient_country}</MenuItem>
                    ))} */}
                            <MenuItem value={countryPhoneCode}>+{countryPhoneCode}</MenuItem>
                            {/* {!country_names &&
                              [1, 2, 3, 4, 5].map((item, index) => (
                                <Skeleton height={71} />
                              ))} */}
                            {country_names &&
                              country_names.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  +{item.phone_code}
                                </MenuItem>
                              ))}
                          </Select>
                        ),
                      }}
                    />
                    {formik.errors.phone && formik.touched.phone ? <p style={Style.validationStyle}>{formik.errors.phone}</p> : null}
                  </Box>
                )}
                {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>Anniversary</FormLabel>
                    <TextField
                      onChange={formik.handleChange}
                      name="wedding_date"
                      value={formik.values.wedding_date}
                      type="date"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                          textTransform: "uppercase",
                        },
                      }}
                      autoComplete="false"
                      color="primary"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <FormLabel sx={{ color: "#000000" }} id="demo-row-radio-buttons-group-label">
                  Notify via reminder
                </FormLabel>
              </Box>
              <Box sx={{ mt: "10px", mb: "10px" }}>
                <Radio
                  checked={radioButton == 0 ? true : false}
                  onChange={handleToggle}
                  placeholder="None"
                  value={0}
                  name="radio-buttons"
                  sx={{
                    pr: { xs: "5px", sm: "12px" },
                    pl: "0px",
                    pt: { xs: "5px", sm: "12px" },
                    pb: { xs: "5px", sm: "12px" },
                  }}
                  inputProps={{ "aria-label": "None" }}
                />
                <label>None</label>
                <Radio
                  placeholder="Email"
                  checked={radioButton == 1 ? true : false}
                  onChange={handleToggle}
                  sx={{ p: { xs: "5px", sm: "12px" } }}
                  value={1}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Email" }}
                />
                <label>Email</label>

                <Radio
                  checked={radioButton == 2 ? true : false}
                  onChange={handleToggle}
                  value={2}
                  sx={{ p: { xs: "5px", sm: "12px" } }}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Phone" }}
                />
                <label>Phone</label>

                <Radio
                  checked={radioButton == 3 ? true : false}
                  onChange={handleToggle}
                  value={3}
                  name="radio-buttons"
                  sx={{ p: { xs: "5px", sm: "12px" } }}
                  inputProps={{ "aria-label": "Both" }}
                />
                <label>Both</label>
              </Box>
              <Typography sx={{ mb: 2, color: "#333333" }}>
                <input
                  type="checkbox"
                  name="validate_Password"
                  id="validate_Password"
                  onChange={formik.handleChange}
                  value={formik.values.validate_Password}
                />
                Do you want to change the password?
              </Typography>
              {formik.values.validate_Password && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", md: "26px" },
                      fontWeight: { xs: "500", md: "400" },
                      color: "#3D2E57",
                      mb: 2,
                    }}>
                    Create Password
                  </Typography>

                  <Box sx={Style.rowBoxStyle}>
                    {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                    {!loading && (
                      <Box sx={Style.inputStyle}>
                        <FormLabel sx={Style.label}>
                          New Password
                          <span style={Style.star}>*</span>
                        </FormLabel>
                        <TextField
                          name="password"
                          value={formik.values.password}
                          id="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="password"
                          variant="filled"
                          InputProps={{ disableUnderline: true, pt: "10px" }}
                          inputProps={{
                            style: {
                              paddingTop: "16px",
                              paddingBottom: "15px",
                            },
                          }}
                          autoComplete="off"
                          color="primary"
                          placeholder="Enter Password"
                          sx={{
                            width: "100%",
                            border: "none",
                          }}
                        />
                        <p style={Style.validationStyle}>{formik.errors.password}</p>
                      </Box>
                    )}
                    {loading && <Skeleton sx={Style.inputStyle} variant="rectangular" height={50} />}
                    {!loading && (
                      <Box sx={Style.inputStyle}>
                        <FormLabel sx={Style.label}>
                          Confirm Password
                          <span style={Style.star}>*</span>
                        </FormLabel>
                        <TextField
                          name="confirm_password"
                          value={formik.values.confirm_password}
                          id="confirm_password"
                          onChange={formik.handleChange}
                          type="password"
                          variant="filled"
                          autoComplete="false"
                          InputProps={{ disableUnderline: true, pt: "10px" }}
                          inputProps={{
                            style: {
                              paddingTop: "16px",
                              paddingBottom: "15px",
                            },
                          }}
                          color="primary"
                          placeholder="Confirm password"
                          sx={{
                            width: "100%",
                            border: "none",
                          }}
                        />
                        <p style={Style.validationStyle}>{formik.errors.confirm_password}</p>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "25%", lg: "20%" },
                float: "right",
                display: "flex",
                justifyContent: "space-between",
                pt: 4,
              }}>
              <Button
                disableRipple
                sx={{
                  width: { xs: "45%", md: "45%" },
                  p: 1,
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: 700,
                  borderRadius: "15px",
                  textTransform: "none",
                  border: "1px solid #EB5757",
                  color: "#EB5757",
                  "&.MuiButtonBase-root:hover": {
                    border: "1px solid #EB5757",
                  },
                }}
                variant="outlined"
                className="btn"
                onClick={() => {
                  formik.handleReset();
                  setRadioButton(userData.notify_reminder_via);
                }}>
                Cancel
              </Button>
              <Button
                disableRipple
                sx={{
                  width: { xs: "45%", md: "45%" },
                  p: 1,
                  fontSize: "16px",
                  lineHeight: "19px",
                  fontWeight: 700,
                  borderRadius: "15px",
                  textTransform: "none",
                  color: "#3D2E57",
                  border: "1px solid #3D2E57",
                  "&.MuiButtonBase-root:hover": {
                    border: "1px solid #3D2E57",
                  },
                }}
                variant="outlined"
                className="btn"
                type="submit">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getuserdata: () => dispatch(getuserdata()),
    updateUser: (userData) => dispatch(updateUser(userData)),
    country_dropdown_data: () => dispatch(country_dropdown_data()),
  };
};

export default connect(null, mapDispatchToProps)(MyProfile);
