import React from "react";
import AddingCards from "../Custom/AddingCards";

const img = require("../../assets/Group 344.png");

const Recordvideo = () => {
  const [value, setValue] = React.useState("Record a video message");

  return (
    <AddingCards
      headerText={"E-Gift card / select type"}
      // amount={}
      value={value}
      setValue={setValue}
      grid={false}
      select={true}
      text1={"Record a video message"}
      text2={"Select an animated e-greeting card GIF"}
      heading={"Would you like to"}
      image={img}
      width={"50%"}
      pb={{ xs: 15, sm: 8, md: 10, lg: 18 }}
      pt={{ xs: 15, sm: 8, md: 10, lg: 18 }}
      bgcolor={"#F9603A"}
    />
  );
};

export default Recordvideo;
