import React, { useState, useEffect } from "react";
import Addresses from "../Custom/Addresses";
import { Box, Button, Pagination } from "@mui/material";
import { getAddresses } from "../../redux/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import { useLocation } from "react-router-dom";
import {
  GIFT_DELIVERY_LOGIN,
  GIFT_ONLY_DELIVERY_LOGIN,
  GIFT_ONLY_RECIPIENT_LOGIN,
  GIFT_ONLY_RECIPIENT_LOGOUT,
  GIFT_RECIPIENTS_LOGIN,
  GIFT_RECIPIENTS_LOGOUT,
} from "../routes/ConstURL";

const GiftCardAddresses = ({ getAddresses }) => {
  const [AddressData, setAddressData] = useState();
  const [pages, setPages] = useState(1);
  const location = useLocation();
  const [start, setStart] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [route, setRoute] = React.useState("");
  const [selected, setSelected] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState([]);
  const [load, setLoad] = React.useState(true);

  const navigate = useNavigate();
  let length = 4;
  let data = {
    start: start,
    length: length,
  };

  useEffect(() => {
    let arrayMap = [];
    getAddresses(data).then((res) => {
      setLoad(false);
      if (res.data.data.total_records == 0) {
        if (location.pathname.length > 42) {
          navigate(GIFT_RECIPIENTS_LOGOUT, {
            state: location.state,
          });
        } else {
          navigate(GIFT_ONLY_RECIPIENT_LOGOUT, {
            state: location.state,
          });
        }
      }
      if (res.data.status) {
        setAddressData(res.data.data.user_addresses);
        setPages(res.data.data.pages);
        setRoute("");
        res.data.data.user_addresses.map((val) => {
          val["selected"] = false;
          arrayMap.push(val);
        });
        setSelectedIndex(arrayMap);
      }
    });
  }, []);
  const againA = (a) => {
    handleChange("", 1);
  };
  const handleContinue = () => {
    if (selected) {
      if (location.pathname.length > 42) {
        navigate(GIFT_DELIVERY_LOGIN, {
          state: location.state,
        });
      } else {
        navigate(GIFT_ONLY_DELIVERY_LOGIN, {
          state: location.state,
        });
      }
    } else {
      toast.error("Select One Address");
    }
  };
  const selectedOne = (data) => {
    setSelected(data);
  };
  const handleChange = (event, value) => {
    setLoad(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    getAddresses(data).then((res) => {
      // if (res?.data?.data?.us.length == 0) {
      setLoad(false);
      // setEmpty(true);
      let arrayMap = [];
      // } else {
      res.data.data.user_addresses.map((val) => {
        val["selected"] = false;
        arrayMap.push(val);
      });
      setSelectedIndex(arrayMap);
      setAddressData(res.data.data.user_addresses);
      // setItem(res.data.data.user_reminders);
      // setEmpty(false);
      setPages(res.data.data.pages);
      // }
    });
  };
  return (
    <Box
      sx={{
        maxWidth: "1400px",
        // width:"100%" , //{md:,lg:"89%",xl:"100%"},
        ml: "auto",
        mr: "auto",
        bgcolor: "#F3F0EE",
        pt: 2,
        // pl: { xs: 2,sm:6, md: 8, lg: 9, xl: 8 },
        // pl:{ xs: '10px', sm: '20px', md: '20px', lg: '20px', xl: 0 },
        // pr: { xs: 1, sm: 6, md: 4, lg: 5 },
        // bgcolor:"red"
      }}>
      <Toastify />
      {/* <LoaderComponent open={load}/> */}
      {load && (
        <Addresses
          heading={"Select address"}
          load={true}
          selectable={true}
          addressBoxWidth={{
            xs: "100%",
            sm: "39%",
            md: "16%",
            lg: "18%",
            xl: "19%",
          }}
        />
      )}
      {!load && (
        <>
          <Box
            sx={{
              maxWidth: { lg: "1310px", xl: "1360px" },
              pl: { xl: 0, lg: 2, sm: 2, xs: 1 },
              pr: { xl: 0, lg: 2, sm: 2, xs: 1 },
            }}>
            <Addresses
              newState={AddressData}
              heading={"Select address"}
              // addressBoxWidth="17%"
              topButton={false}
              bottomButton={true}
              again={() => againA("a")}
              selectable={true}
              Selected={selectedOne}
              //  clickaddnew={""}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              //  clickcontinue={}
              addressBoxWidth={{
                xs: "90%",
                sm: "30%",
                md: "15%",
                lg: "17%",
                xl: "20%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
              justifyContent: { md: "space-between" },
              alignItems: { xs: "flex-end", md: "center" },
              bgcolor: "#F3F0EE",
              pt: 4,
              pb: 8,
            }}>
            <Box
              sx={{
                pl: { xl: 0, lg: 2, sm: 2, xs: 1 },
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                flexWrap: "wrap",
                width: { xl: "35%", lg: "35%", md: "47%", sm: "96%", xs: "96%" },
                justifyContent: "space-between",
                alignItems: "space-between",
                bgcolor: "#F3F0EE",
              }}>
              <Box sx={Style.linkButtonBox}>
                <Button onClick={handleContinue} style={Style.linkButton}>
                  Continue
                  <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
                </Button>
              </Box>
              <Box sx={Style.linkButtonBox}>
                <Button
                  onClick={() => {
                    location.pathname.length > 42
                      ? navigate(GIFT_RECIPIENTS_LOGIN, {
                          state: location.state,
                        })
                      : navigate(GIFT_ONLY_RECIPIENT_LOGIN, {
                          state: location.state,
                        });
                  }}
                  style={Style.linkButton}>
                  Add new address
                  <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "16px" }} />
                </Button>
              </Box>
            </Box>
            {pages > 1 && <Pagination count={pages} page={page} onChange={handleChange} style={{ textAlign: "right" }} />}
          </Box>
        </>
      )}
    </Box>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getAddresses: (data) => dispatch(getAddresses(data)),
  };
}
export default connect(null, mapDispatchToProps)(GiftCardAddresses);
const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
    // pl: { lg: "2%", xs: "4%", sm: "5%", md: "2%" },
  },
  user: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#3D2E57",
  },
  addressBox: {
    // width: `${addressBoxWidth}`,
  },
  addressLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    pt: 1,
  },
  Address: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#3D2E57",
    pl: 2,
  },
  edit: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3D2E57",
    textDecoration: "none",
  },
  remove: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#EB5757",
    textDecoration: "none",
  },
  linkButtonBox: {
    color: "#3D2E57",
    pt: "8px",
    pb: "8px",
    pr: "25px",
    pl: "25px",
    mt: 2,
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px",
    fontWeight: "400",
    // width: { md: "60%", xs: "50%", lg: "20%", xl: "20%" },
    border: "1px solid #3D2E57",
    fontSize: "16px",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    // width: { md: "20%", xs: "40%" },
  },
  awesomeIcon: {
    marginTop: "3px",
  },
};
