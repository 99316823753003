import React, { useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Button, Typography, Backdrop, CircularProgress, TextField, FormLabel } from "@mui/material";
import "./loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../Custom/Input/InputField";
import { storage } from "../../config/storage";
import Toastify from "../SnackBar/Toastify";
import { connect, useSelector } from "react-redux";
import { getLoginWithEmailOTP, verifyEmailOtp } from "../../redux/action";
import { useFormik } from "formik";
import LoaderComponent from "../Loader/LoaderComponent";
const schema = yup.object().shape({
  email: yup.string().required("Please enter your email").email("Please enter valid email"),
  login_email_otp: yup.string().when("otpSent", {
    is: true,
    then: yup.string().required("Please enter OTP"),
  }),
});
const LoginwithEmail = ({ verifyEmailOtp, getLoginWithEmailOTP }) => {
  const navigate = useNavigate();
  const [loader, setloader] = React.useState(false);
  const [sendedOTP, setSendedOTP] = React.useState(false);
  const [login_data, setLogin_data] = React.useState({
    email: "",
    login_email_otp: "",
    otpSent: sendedOTP,
  });
  let cart_id = storage.fetch.carts_id();
  let id = {};
  if (cart_id !== undefined) {
    id = { carts_id: cart_id, carts_id_check: true };
  } else {
    id = { carts_id_check: false };
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // let defaultValues = {
  //   email: email,
  //   login_email_otp: "",
  //   otpSent: sendedOTP,
  // };
  const formik = useFormik({
    initialValues: login_data,
    validationSchema: schema,
    onSubmit: (value) => {
      onSubmit(value);
    },
    enableReinitialize: true,
  });

  const onSubmit = (model) => {
    setloader(true);
    let item = model;
    if (sendedOTP) {
      Object.assign(item, id);
      delete item.otpSent;
      verifyEmailOtp(item).then((res) => {
        setloader(false);
        if (res.data.status) {
          storage.set.authToken(res?.data?.token);
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      });
    } else {
      getLoginWithEmailOTP(item).then((res) => {
        setloader(false);
        if (res.data.status) {
          setSendedOTP(true);
        } else {
          res?.data?.errors?.map((item) => {
            return toast.error(item);
          });
        }
      });
    }
  };
  return (
    <form
      name="LoginForm"
      style={{
        width: "90%",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      onSubmit={formik.handleSubmit}>
      <LoaderComponent open={loader} />
      <FormLabel
        sx={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          color: "#333333",
        }}>
        Email
        <span>*</span>
      </FormLabel>
      <TextField
        disabled={sendedOTP ? true : false}
        name="email"
        autoComplete="false"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        type="text"
        variant="filled"
        InputProps={{ disableUnderline: true, pt: "10px" }}
        inputProps={{
          style: {
            paddingTop: "16px",
            paddingBottom: "15px",
          },
        }}
        color="primary"
        placeholder="Enter your email address"
        sx={{
          width: "100%",
          border: "none",
        }}
      />
      {formik.errors.email && formik.touched.email ? <p style={{ color: "red" }}>{formik.errors.email}</p> : null}
      {sendedOTP && (
        <>
          <FormLabel
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "24px",
              color: "#333333",
            }}>
            OTP
            <span>*</span>
          </FormLabel>
          <TextField
            name="login_email_otp"
            autoComplete="false"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            variant="filled"
            InputProps={{ disableUnderline: true, pt: "10px" }}
            inputProps={{
              style: {
                paddingTop: "16px",
                paddingBottom: "15px",
              },
            }}
            color="primary"
            placeholder="Enter OTP here"
            sx={{
              width: "100%",
              border: "none",
            }}
          />
          {formik.errors.login_email_otp && formik.touched.login_email_otp ? (
            <p style={{ color: "red" }}>{formik.errors.login_email_otp}</p>
          ) : null}
        </>
      )}
      {sendedOTP && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            disableRipple
            sx={{
              width: {
                xs: "100%",
                sm: "90%",
                md: "60%",
                lg: "40%",
                xl: "40%",
              },
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#FFFFFF",
                border: "1.5px solid #3D2E57",
              },
              bgcolor: "#FFFFFF",
              color: "#3D2E57",
              border: "1.5px solid #3D2E57",
              // p: 1,
              mb: 2,
              mt: 2,
              borderRadius: "15px",
              fontSize: { xs: "20px", md: "22px", lg: "24px" },
              fontWeight: 500,
              textTransform: "none",
            }}
            variant="outlined"
            type="submit">
            Submit OTP
          </Button>
        </Box>
      )}
      {!sendedOTP && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            disableRipple
            // onClick={onSubmit}
            type="submit"
            variant="text"
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#FFFFFF",
                border: "1.5px solid #3D2E57",
              },
              border: "1.5px solid #3D2E57",

              color: "#3D2E57",
              fontWeight: "400",
              fontSize: "20px",
              textTransform: "none",
              width: {
                xs: "100%",
                sm: "90%",
                md: "60%",
                lg: "40%",
                xl: "40%",
              },
              borderRadius: "15px",
              mb: 2,
              mt: 2,
            }}>
            Send OTP
          </Button>
        </Box>
      )}
    </form>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getLoginWithEmailOTP: (item) => dispatch(getLoginWithEmailOTP(item)),
    verifyEmailOtp: (item) => dispatch(verifyEmailOtp(item)),
  };
}

export default connect(null, mapDispatchToProps)(LoginwithEmail);
