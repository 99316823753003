import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { TextField, FormLabel, Button, Typography, Backdrop, CircularProgress } from "@mui/material";
import { connect, useSelector } from "react-redux";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { getOtpVerify } from "../../redux/action";
import { getPhoneVerify } from "../../redux/action";
import Toastify from "../SnackBar/Toastify";
import InputField from "../Custom/Input/InputField";
import { storage } from "../../config/storage";
import { LOGIN } from "../routes/ConstURL";
const schema = yup.object().shape({
  otp: yup.string().required("Please enter OTP Here"),
});

const defaultValues = {
  otp: "",
};
const OtpVerify = ({ getOtpVerify, getPhoneVerify }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { control, formState, handleSubmit } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = formState;
  function onSubmit(model) {
    let otp = model.otp;
    let user_id = storage.fetchForUserId.userId();
    let item = { otp, user_id: user_id };
    getOtpVerify(item).then((res) => {
      setLoading(false);
      if (res) {
        setTimeout(() => {
          navigate(LOGIN);
          storage.destroy.phoneNumber();
        }, 1000);
      }
    });
  }
  const resendPhoneOtp = () => {
    let phoneNumber = storage.fetchPhoneNumber.phoneNumber();
    let user_id = storage.fetchForUserId.userId();
    let item = { phone: phoneNumber, user_id: user_id };
    getPhoneVerify(item).then((res) => {
      setLoading(false);
      if (res) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "#F3F0EE",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        pt: { xl: 18, md: 18, xs: 12 },
      }}>
      <Box
        sx={{
          maxWidth: "750px",
          width: { xs: "90%", sm: "80%", md: "65%", lg: "60%", xl: "50%" },
          mt: { sm: "10px", md: "20px", lg: "30px", xl: "30px", xs: "0px" },

          borderRadius: {
            xs: "10px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "20px",
          },
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Toastify />

        <form
          onSubmit={handleSubmit(onSubmit)}
          schema={schema}
          style={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography
            display="block"
            sx={{
              fontSize: { xs: "24px", sm: "26px", md: "30px", lg: "35px" },
              lineHeight: { xs: "29px", sm: "29px", md: "30px", lg: "31px" },
              fontWeight: 700,
              textAlign: "center",
              color: "#3D2E57",
              mt: 5,
              mb: 3,
            }}>
            OTP Verification
          </Typography>

          <Typography
            display="block"
            sx={{
              // fontSize: "1rem",
              // fontWeight: "400",
              // lineHeight: "18px",
              // letterSpacing: "0em",
              // textAlign: "center",
              // color: "#3D2E57",
              // width: { lg: "50%", md: "45%", sm: "45%", xs: "45%" },
              // pt: 3,
              // pl: { xl: "25%", lg: "25%", md: "28%", sm: "27%", xs: "30%" },
              fontSize: "18px",
              lineHeight: "21px",
              fontWeight: "400",
              textAlign: "center",
              color: "#3D2E57",
              mb: 4,
            }}>
            Enter the OTP sent to <b>your mobile number</b>
          </Typography>

          {/* <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
              // padding:4
            }}
          >
            <Box
              sx={{
                width: "95%",
                p: { xl: 2, lg: 2, md: 1, sm: 1, xs: 1 },
              }}
            >
              <FormLabel
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#333333",
                }}
              >
                Enter OTP number
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </FormLabel>
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    color="primary"
                    error={!!errors.otp}
                    helperText={errors?.otp?.message}
                    placeholder="Enter OTP number"
                    sx={{
                      backgroundColor: "#F6F6F6",
                      width: "100%",
                      // border: 'none'
                    }}
                    FormHelperTextProps={{
                      style: { backgroundColor: "white" },
                    }}
                  />
                )}
              />
            </Box>
          </Box> */}

          <InputField
            control={control}
            helperText={errors?.otp?.message}
            errors={!!errors.otp}
            type={"text"}
            placeholder={"Enter OTP number"}
            variant="filled"
            formlabel="Enter OTP number"
            size={{ xs: "20px", md: "22px", lg: "24px" }}
            color={"#333333"}
            name="otp"
            required={"*"}
          />

          <Typography
            sx={{
              textAlign: "right",
              fontSize: { xs: "16px", lg: "18px" },
              lineHeight: { xs: "19px", lg: "21px" },
              fontWeight: "400",
              color: "#3D2E57",
              mt: 2,
            }}>
            Don’t receive the OTP ?
            <Button
              onClick={() => resendPhoneOtp()}
              style={{
                fontSize: { xs: "16px", lg: "18px" },
                lineHeight: { xs: "19px", lg: "21px" },
                fontWeight: "400",
                color: "#FF8D2A",
                textDecoration: "none",
                textTransform: "none",
              }}>
              Resend OTP
            </Button>
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <Button
              sx={{
                width: {
                  xs: "100%",
                  sm: "90%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%",
                },
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#FFFFFF",
                  border: "1.5px solid #3D2E57",
                },
                color: "#3D2E57",
                border: "1.5px solid #3D2E57",
                mt: 4,
                mb: 5,
                borderRadius: "15px",
                fontSize: { xs: "20px", md: "22px", lg: "24px" },
                fontWeight: 500,
                textTransform: "none",
              }}
              // color="warning"
              variant="outlined"
              type="submit">
              Verify & Proceed
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOtpVerify: (item) => dispatch(getOtpVerify(item)),
    getPhoneVerify: (item) => dispatch(getPhoneVerify(item)),
  };
}
export default connect(null, mapDispatchToProps)(OtpVerify);
