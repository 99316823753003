/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import Categories from "../Custom/Categories";
import { connect, useSelector } from "react-redux";
import { get_e_gift_card_gif } from "../../redux/action";
import { storage } from "../../config/storage";
import LoaderComponent from "../Loader/LoaderComponent";
import { EGIFT_GIF_RECIPIENT } from "../routes/ConstURL";
const SelectGif = ({ get_e_gift_card_gif }) => {
  const [dataEmpty, setDataEmpty] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [start, setStart] = React.useState(1);
  const newState = useSelector((state) => state?.User?.e_gift_gifs);
  let length = 8 * start;
  let data = {
    start: 0,
    length: length,
  };

  const handleScrolling = () => {
    setStart(start + 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleDataFetch();
  }, [start]);

  const handleDataFetch = () => {
    setDataEmpty(true);
    get_e_gift_card_gif(data).then((res) => {
      setOpen(false);
      if (res?.data?.status) {
        setDataEmpty(false);
      }
    });
  };
  return (
    <>
      <LoaderComponent open={open} />
      <Categories
        headerText={"E-GIFT cards / Select Amount/select  Animated gif"}
        newState={newState}
        gif={true}
        dataEmpty={dataEmpty}
        next={handleScrolling}
        heading={"Gifs"}
        link={EGIFT_GIF_RECIPIENT}
      />
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    get_e_gift_card_gif: (data) => dispatch(get_e_gift_card_gif(data)),
  };
}
export default connect(null, mapDispatchToProps)(SelectGif);
