import * as React from "react";
import Stack from "@mui/material/Stack";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation } from "react-router-dom";
import Toastify from "../SnackBar/Toastify";

import "../header/Header.css";
import InfiniteScroll from "react-infinite-scroll-component";

const CardListing = ({ heading, next, headerChildText, newState, link, dataEmpty }) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <Box sx={style.outerBox}>
    <>
      <Box sx={style.mainBox}>
        <Typography sx={style.heading}>{heading}</Typography>
        {dataEmpty && (
          <Typography sx={style.heading1}>
            Currently we have no greeting cards available for selected category and sorry for inconvenience
          </Typography>
        )}
        {!dataEmpty && (
          <Box sx={style.box1}>
            <Box>
              <InfiniteScroll
                dataLength={newState?.total_records ? newState?.total_records : 8}
                next={next}
                style={{ overflowX: "hidden", paddingBottom: "50px" }}
                hasMore={newState?.total_records > newState?.greeting_cards?.length ? true : false}
                loader={
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                      sx={{
                        display: "flex",
                        overflowX: "hidden",
                        height: "60px",
                        width: "60px",
                      }}
                    />
                  </Box>
                }
                scrollThreshold={1}
                // height={300}
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>You've seen it all!</b>
                //   </p>
                // }
                scrollableTarget="scrollableDiv">
                <Stack direction={{ xs: "column", sm: "row" }} sx={style.stack1}>
                  {newState?.greeting_cards?.length === 0 &&
                    [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                      <Box sx={style.itemBox} key={index}>
                        <Skeleton variant="rectangular" sx={{ borderRadius: "10px", pl: "25px" }} width={"100%"} height={380} />
                        <Skeleton sx={{ borderRadius: "10px", pl: "25px" }} width={"100%"} height={170} animation="wave" />
                      </Box>
                    ))}
                  {newState &&
                    newState?.greeting_cards?.map((card, index) => (
                      <Box sx={style.itemBox} key={index}>
                        <Box sx={style.imageItem}>
                          <img
                            alt="logo"
                            className="listCardImage"
                            // style={{maxHeight:'100%',maxWidth:'100%'}}
                            src={card.file_path}
                          />
                        </Box>
                        <Box sx={style.cardContentBox}>
                          <Typography sx={style.contentText}>{card.title}</Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "#3D2E57",
                              pb: 1,
                              textAlign: {
                                xs: "center",
                                sm: "left",
                                lg: "left",
                                xl: "left",
                              },
                            }}>
                            $ {card.price}
                          </Typography>
                          <Box
                            sx={{
                              // bgcolor:'red',
                              display: "flex",
                              justifyContent: { xs: "center", sm: "left" },
                            }}>
                            <Box sx={style.linkButtonBox}>
                              <Link
                                // to={`${link}/${card.card_id}`}
                                to={link}
                                className="cardLinkButton"
                                state={{
                                  crumbValue: card.title,
                                  from: location?.state?.from,
                                  URL: location.pathname,
                                  prevPageName: headerChildText,
                                  id: card.card_id,
                                }}>
                                View detail
                                <ArrowForwardIosIcon sx={{ ml: "5px", fontSize: 15 }} />
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  <Toastify />
                </Stack>
              </InfiniteScroll>
            </Box>
          </Box>
        )}
      </Box>
      {/* </Box> */}
    </>
  );
};

export default CardListing;
const style = {
  outerBox: {
    height: "100%",
    bgcolor: "#F3F0EE",
    display: "flex",
    // width:'100%',
    flexDirection: "column",
    justifyContent: "center",
    // pt: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
  },
  mainBox: {
    // height: "50%",
    // maxWidth:{xs:'95%',xl:'1360px'},
    bgcolor: "#F3F0EE",
    // width: "100%",
    ml: "auto",
    mr: "auto",
    pl: { xs: "10px", sm: "20px", md: "", lg: 2.5, xl: 0 },
    pr: { xs: "10px", sm: "20px", md: "", lg: 4, xl: 0 },
  },
  header: {
    background: "#F9F7F6",
    width: "100%",
    alignItems: "center",
    height: {
      xs: "45px",
      sm: "40px",
      md: "40px",
      lg: "60px",
      xl: "60px",
    },
    bgcolor: "#F9F7F6",
    display: "flex",
    flexDirection: "row",
  },

  heading: {
    color: "#3D2E57",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "44px",
    lineHeight: "42px",
    pt: 3,
  },
  heading1: {
    color: "#3D2E57",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "35px",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "42px",

    pb: 20,

    mt: "100px",
    // mb:'100px',
    pl: { xs: 1, sm: 6, md: 8, lg: 8, xl: 8 },
    pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-evenly",
    // width: "100%",
  },
  stack1: {
    display: "flex",
    flexWrap: "wrap",
    // height: "100%",
    ml: { xs: 0, sm: -2, md: -4, lg: -3, xl: -3.5 },
    mr: { xs: 0, sm: -2, md: -2, lg: -4, xl: -3.5 },
    // pl: { xs: 1, sm: 6, md: 8, lg: 8 ,xl:8},
    mb: 3,
  },
  itemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    mt: { xs: 5, sm: 5, md: 8, lg: 5, xl: 5 },
    pl: { xs: "1%", sm: "3%", md: "3.5%", lg: "2%", xl: "2%" },
    pr: { xs: "1%", sm: "3%", md: "3.5%", lg: "2%", xl: "2%" },
    maxWidth: { xs: "92%", sm: "18%", md: "17.5%", lg: "20.5%", xl: "20.25%" },
    width: { xs: "90%", sm: "18%", md: "17.5%", lg: "20.5%", xl: "20.25%" },
  },
  imageItem: {
    width: "100%",
    maxHeight: {
      xs: "max-content",
      sm: "150px",
      md: "200px",
      lg: "300px",
      xl: "300px",
    },

    pt: "45px",
    pb: "45px",
    pr: "13px",
    pl: "13px",
    border: "1px solid rgba(61, 46, 87, 0.4)",
    display: "flex",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "#FFFFFF",
  },
  cardContentBox: {
    display: "flex",
    flexDirection: "column",
    pl: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    pr: { xs: "3%", sm: "10%", md: "7%", lg: "5%" },
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    pb: 2,
  },
  contentText: {
    pt: 2,
    fontWeight: "400",
    color: "#3D2E57",
    fontSize: { xs: "24px", sm: "16px", md: "20px", lg: "24px", xl: "24px" },
    textAlign: "center",
    pb: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  },
  linkButtonBox: {
    border: "1px solid #3D2E57",
    borderRadius: "15px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    // width: { md: "70%", xs: "50%" },
    fontSize: "16px",
    textAlign: "center",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    paddingLeft: "35px",
    paddingRight: "35px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    width: "100%",
    height: "100%",
  },
};
