/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Cardpersonalise from "../Custom/CardPersonalise";
import { getmessage } from "../../redux/action";
import { storage } from "../../config/storage";
import { useSelector } from "react-redux";
import LoaderComponent from "../Loader/LoaderComponent";
import { toast } from "react-toastify";
import Toastify from "../SnackBar/Toastify";
import { CATEGORIES, GIFT_ADDRESS, GIFT_RECIPIENTS_LOGOUT } from "../routes/ConstURL";
const GiftCardPersonalise = ({ getmessage }) => {
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState();
  const [limit, setLimit] = useState();
  // const { id } = useParams();
  const newState = useSelector((state) => state?.User?.carddetail);
  const location = useLocation();
  const navigate = useNavigate();
  let id;
  let id_storage = storage.fetch.card_detail_id();
  if (id_storage) {
    id = id_storage;
  } else {
    setTimeout(() => {
      navigate(CATEGORIES);
    }, 3000);
  }
  useEffect(() => {
    setLoading(true);
    if (!id_storage) {
      toast.error("please first select a card category");
    }
    storage.set.item_id(id);
    window.scrollTo(0, 0);
    let card_id = {
      greeting_card_id: id,
    };
    getmessage(card_id).then((res) => {
      if (res.data) {
        setImg(res.data.data.add_msg_file.file_path_formatted);
        setLimit(res.data.data.add_msg_file.max_char);
        setLoading(false);
      }
    });
  }, []);
  return (
    <>
      <LoaderComponent open={loading} />
      <Toastify />
      {loading && (
        <Cardpersonalise
          headerParentText={"Greeting cards /"}
          headerChild1={location?.state?.headerVal1 ? location?.state?.headerVal1 : "Cards"}
          headerChild2={location?.state?.headerVal2 ? `${location?.state?.headerVal2} /` : "Card Details"}
          headerChildText={"Add a message"}
          onClick={GIFT_ADDRESS}
          onClick1={GIFT_RECIPIENTS_LOGOUT}
          img={img}
          limit={limit}
          newState={newState}
          skeleton={true}
          // id={card_id}
        />
      )}
      {!loading && (
        <Cardpersonalise
          onClick={GIFT_ADDRESS}
          onClick1={GIFT_RECIPIENTS_LOGOUT}
          headerParentText={"Greeting cards /"}
          headerChild1={location?.state?.headerVal1 ? location?.state?.headerVal1 : "Cards"}
          headerChild2={location?.state?.headerVal2 ? `${location?.state?.headerVal2} /` : "Card Details"}
          headerChildText={" Add a message"}
          img={img}
          limit={limit}
          newState={newState}
          skeleton={false}
          // id={card_id}
        />
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getmessage: (card_id) => dispatch(getmessage(card_id)),
  };
}
export default connect(null, mapDispatchToProps)(GiftCardPersonalise);
