// import { Typography } from '@mui/material'
// import { Card } from "@mui/material";
import {} from "@mui/material";
import { Box } from "@mui/system";
import BoxContainer from "./BoxContainer";
import BoxContainer1 from "./BoxContainer1";
import BoxContainer2 from "./BoxContainer2";
import BoxContainer3 from "./BoxContainer3";

import GridCards from "./GridCards";
// import { RegisterUser } from "../../redux/action";

const HomePage = () => {
  return (
    // <Box
    //   sx={{
    //     // maxWidth:{xs: "94%",xl:'50%'},
    //     maxWidth:{xl:'1500px'},
    //     pt: { xs: 14, md: 15, sm: 16, xl: 20, lg: 20 },
    //     overflowX: "hidden",
    //     mr: "auto",
    //     ml: "auto",
    //   }}
    // >
    <>
      <Box
        sx={{
          pt: { xs: 9, md: 14, sm: 11, xl: 17, lg: 16.5 },
        }}>
        <GridCards />
        <BoxContainer />
        <BoxContainer1 />
        <BoxContainer2 />
        <BoxContainer3 />
      </Box>
    </>
  );
};

export default HomePage;
