import React from "react";
import { useNavigate } from "react-router-dom";
import AddingCards from "../Custom/AddingCards";

const img = require("../../assets/Group 592.png");

const AddGiftCard = () => {
  const [value, setValue] = React.useState("Add a gift card");

  const navigate = useNavigate();
  return (
    <AddingCards
      heading={"Add Gift card"}
      value={value}
      setValue={setValue}
      text1={"Add a gift card"}
      text2={"Not Today"}
      bgcolor={"#3DD5B7"}
      image={img}
      pt={"100px"}
      pb={"100px"}
      width={"392px"}
    />
  );
};

export default AddGiftCard;
