/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { storage } from "../../config/storage";
import "../Custom/CSS/HeaderCrums.css";
import { MYACCOUNT, MYPROFILE } from "../routes/ConstURL";

const HeaderCrums = () => {
  const location = useLocation();
  const trimmedURL = location.pathname.slice(0, 10);
  let pathnames = location.pathname.split("/").filter((x) => x);
  const lastIndex = pathnames.length - 1;
  if (trimmedURL === "/video/mes") {
    pathnames.splice(lastIndex, 1);
  }

  return (
    <Box sx={style.box}>
      <Breadcrumbs sx={{ pt: "14px", pb: "14px" }} aria-label="breadcrumb">
        <Link className="linkText" to={"/"}>
          Home
        </Link>
        {pathnames.map((item, index) => {
          let routes = `/${pathnames.slice(0, index + 1).join("/")}`;
          if (index === 0 && trimmedURL === MYACCOUNT) {
            routes = MYPROFILE;
          }
          return index !== lastIndex ? (
            <Link className={"linkText"} key={index} to={routes}>
              {item}
            </Link>
          ) : (
            <Typography
              sx={{
                textTransform: "uppercase",
                fontSize: "14px",
                fontWeight: "600",
                color: "#808080",
              }}
              key={index}>
              {item}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default HeaderCrums;

const style = {
  box: {
    maxWidth: { lg: "1160px", xl: "1360px" },
    m: 0,
    width: "100%",
    display: "flex",
    pr: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
    pl: { xs: "10px", sm: "20px", md: "20px", lg: "20px", xl: 0 },
  },
  //   headertxt: {
  //     fontWeight: "700",
  //     textDecoration:'none',
  //     // pl:{xl:'1px',lg:0},/
  //     pt:{xs:"17px",sm:"25px",md:'25px',lg:'18px'},
  //     pb:{xs:"12px",sm:"15px",md:'15px',lg:'18px'},
  //     fontSize: { xs: "12px", sm: "12px", md: "13px", lg: "14px", xl: "14px" },
  //     lineHeight:{xs:"28px",sm:"30px"},
  // marginRight:'3px',
  //     // pt:'10px',
  //     // pb:{xs:"15px",sm:'10px'},
  //     color: "#3D2E57",
  //     // width:'100%',
  //     // bgcolor: "#F9F7F6",
  //     // display: "flex",
  //     alignItems: "center",
  //     textTransform: "uppercase",
  //     // mt: { xs: 2, sm: 3, md: 3, lg: 1, xl: 2 },
  //     // pr: { xs: 1, sm: 6, md: 8, lg: 5 },
  //   },
};

//old work//
// if (trimmedURL === MYACCOUNT) {
//   if (pathnames[1] !== data1[0]?.name) {
//     data1.splice(0, 1);
//   }
// }
// if (trimmedURL === "/greetingc") {
//   if (data1.length > 0) {
//     data1[0].name = "greetingcategories";
//   }
// }
// if(trimmedURL==="/firstchec"){
//   if(data1.length>0){
//     data1[0].name="firstcheckout"
// console.log("data111111111111111",data1)
//   }
// }

// let data = pathnames.map((item, index) => {
//   if (index === pathnames.length - 1) {
//     let obj = { name: item, id: "", routes: "" };
//     return obj;
//   } else {
//     let obj = { name: item, id: item.length > 30 ? item : "", routes: "" };
//     return obj;
//   }
// });
// if (data.length > 1 && data[lastIndex].name.length > 30) {
//   data[lastIndex - 1].id = data[lastIndex].name;
// } else if (data.length > 1 && data[lastIndex].name.length < 30) {
//   data[lastIndex - 1].name = data[lastIndex].name;
// }
// if (data[lastIndex].name.length > 30) {
//   data.splice(lastIndex, 1);
// }
// React.useEffect(() => {
//   if (lastIndex === 0) {
//     data1.push(data[0]);
//   } else {
//     data1.push(data[lastIndex - 1]);
//   }
//   setData1([...data1]);
//   if (data.length !== data1?.length) {
//     data1?.splice(data.length, data1.length - data.length);
//     setData1([...data1]);
//   }
//   if (data1?.length > 1 && trimmedURL === MYACCOUNT) {
//     if (data[lastIndex]?.name !== data1[lastIndex]?.name) {
//       data1[lastIndex].name = data[lastIndex].name;
//       setData1([...data1]);
//     }
//   }
// }, [location.pathname]);
// if (data1[0]?.name === data1[lastIndex]?.name && data1.length === 2) {
//   data1.splice(lastIndex, 1);
//   setData1([...data1]);
// }
// // const id = storage.fetch.prev_id("prev_id");
// console.log(data1);
// let firstString = "",
//   secondString = "";
