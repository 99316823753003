import React,{useEffect} from 'react';
import FooterHeading from "./FooterHeading";
import { Box, Typography } from "@mui/material";

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
      <FooterHeading heading={"Contact US"} />
      <Box
        sx={{
          // maxWidth: "1360px" ,
          ml: "auto",
          mr: "auto",
          bgcolor:'#F3F0EE',
          pl:{ xs: '10px', sm: '20px', md: '20px', lg: 4, xl: 0 },
          pr:{ xs: '10px', sm: '20px', md: '20px', lg: 4, xl: 0 },
        }}
      >
        <Typography variant="h5">
          Along with FAQ and Contact pages, the About Us page is one of the
          first supporting pages you'll likely create for your website,
          regardless of the industry you're in. They may go by different
          labels—“About”, “Story”, “Mission”—but these types of pages generally
          serve the same key purpose: to be the go-to page for a brand to say,
          “This is who we are.” When a visitor wants to learn more about you or
          your business, it's the page they'll look for. Unfortunately, About Us
          pages are too often treated as an obligation rather than the valuable
          opportunity they are to connect with your customers by selling your
          story, your vision, your mission, and what makes you, YOU. Along with
          FAQ and Contact pages, the About Us page is one of the first
          supporting pages you'll likely create for your website, regardless of
          the industry you're in. They may go by different labels—“About”,
          “Story”, “Mission”—but these types of pages generally serve the same
          key purpose: to be the go-to page for a brand to say, “This is who we
          are.” When a visitor wants to learn more about you or your business,
          it's the page they'll look for. Unfortunately, About Us pages are too
          often treated as an obligation rather than the valuable opportunity
          they are to connect with your customers by selling your story, your
          vision, your mission, and what makes you, YOU.
        </Typography>
      </Box>
    </>
  )
}

export default Contact;